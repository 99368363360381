import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

declare var $

@Component({
  selector: 'app-vesting-plan-add',
  templateUrl: './vesting-plan-add.component.html',
  styleUrls: ['./vesting-plan-add.component.css']
})
export class VestingPlanAddComponent implements OnInit {
  selectedOption: string = 'option1'; // Variable to store the selected option
  isRadioEnabled = true; // Initial state is enabled
  addFaqForm: FormGroup;
  pageNumber: number = 1
  currTab: any;
  userId: any
  today: any = new Date().toISOString().slice(0, 16)
  loylity: FormGroup
  // Creating an array in JavaScript
  daysList = [
    {name:30},
    {name:60},
    {name:90},
    {name:120},
    {name:180},
    {name:365},
    {name:730}
  ];
  dataListRelease = [
    {name:30},
    {name:180},
    {name:365},
    {name:550},
    {name:730},
    {name:920},
    {name:1095},
    {name:1280},
    {name:1460}
]
// daysList = [{name:3},
//   {name:30},
//   {name:60},
//   {name:90},
//   {name:120},
//   {name:360},
//   {name:720}
// ];
// dataListRelease = [{name:3},
//   {name:30},
//   {name:60},
//   {name:90},
//   {name:120},
//   {name:360},
//   {name:720}
// ];
  constructor(public route: Router, public service: MainService, public active: ActivatedRoute, private datePipe: DatePipe) {

    // this.active.queryParams.subscribe((params) => {
    //   this.currTab = params.tab
    //   this.userId = params.userId
    //   if (params.userId) {
    //     this.showUserDetail()
    //   }
    //   console.log('jjf', this.currTab);

    // })
  }

  ngOnInit(): void {
    this.formValidation();
    this.getFieroPrice();
    this.viewFee();
  }
  
  formValidation() {
    this.addFaqForm = new FormGroup({
      'planName': new FormControl('', [Validators.required]),
      'tokenPrice': new FormControl('', [Validators.required]),
      'tokenSellLimit': new FormControl('', [Validators.required]),
      'terms': new FormControl('', [Validators.required]),
      'planStartdate': new FormControl('', [Validators.required]),
      'planEndDate': new FormControl('', [Validators.required]),
      'withdrawaCommission': new FormControl('', [Validators.required]),
      'releaseTimePeriod': new FormControl('', [Validators.required]),
      'image': new FormControl(''),
      'minGbpAmount': new FormControl(''),
      'salePrice': new FormControl('POOL', [Validators.required])

    })
  }

  // Function to handle the change event when a radio button is clicked
  onOptionChange(): void {
  
  //  if(this.addFaqForm.value.salePrice === 'TRANC'){
    this.getFieroPrice()
  //  }

  }
  openModalFun(){
    this.isRadioEnabled = !this.isRadioEnabled;
  $('#markUpModal').modal('show')
  }

  showData:any
 
  fieroPrice:any
getFieroPrice(){
  let url=`wallet/get-sale-list`
  this.service.get(url).subscribe((res: any) => {
    if (res.status == 200) {
      this.fieroPrice = res.data['enabled_sale']['price']
      if(res.data.enabled_sale.icoStatus == "ENABLE"){
        this.fieroPrices = res.data['enabled_sale']['priceWithoutMark']
        this.finalPrice = res.data['enabled_sale']['price']
      }
      if(this.addFaqForm.value.salePrice === 'TRANC'){
       if(this.markTab == 'UP' || this.markTab =='DOWN'){
        this.addFaqForm.patchValue({
          tokenPrice : this.finalPrice
        })
       }else{
        this.addFaqForm.patchValue({
          tokenPrice : this.fieroPrices
        })
       }
      }
    } else {

    }
  }, err => {
    this.service.hideSpinner();
    if (err['status'] == '401') {
      this.service.onLogout();
      this.service.toasterErr('Unauthorized Access');
    } else {
      this.service.toasterErr('Something Went Wrong');
    }
  })
}
addVestingPlan(){
  let url = `wallet/admin/add-vesting-plan`
let enable
let markStatus
  if(this.addFaqForm.value.salePrice === 'TRANC'){
     enable = 'ENABLE'
     markStatus = this.markTab
  }else{
     enable = 'DISABLE'
     this.markDownPercentage = 0;
     this.markUpPercentage = 0;
     markStatus = 'NOMARK'
  }

let body = {
  "comission":  this.addFaqForm.value.withdrawaCommission,
  "endDate": this.addFaqForm.value.planEndDate,
  "planName": this.addFaqForm.value.planName,
  "releaseTerm": this.addFaqForm.value.releaseTimePeriod,
  "sellLimit": this.addFaqForm.value.tokenSellLimit,
  "startDate": this.addFaqForm.value.planStartdate,
  "terms": this.addFaqForm.value.terms,
  "minGbpAmount": this.addFaqForm.value.minGbpAmount,
  "tokenPrice": String(this.addFaqForm.value.tokenPrice),
  'image' : this.myImage,
  "vestingPlanType": this.addFaqForm.value.salePrice,
   "markDownPercentage": this.markTab == "NOMARK" ? 0 : ( this.markTab == "DOWN" ? this.markDownPercentage : 0 ) ,
  "markStatus": enable,
  "markType":markStatus,
  "markUpPercentage":this.markTab == "NOMARK" ? 0 : ( this.markTab == "UP" ? this.markUpPercentage : 0 ),
}
this.service.showSpinner()
  this.service.post(url, body).subscribe((res: any) => {
    if (res.status == 200) {
      this.service.toasterSucc(res.message)
      this.route.navigate(['/vesting-plan-list'])
    this.service.showSpinner()
    } else {
    this.service.showSpinner()
    }
  }, err => {
    this.service.hideSpinner();
    if (err['status'] == '401') {
      this.service.onLogout();
      this.service.toasterErr('Unauthorized Access');
    } else {
      this.service.toasterErr('Something Went Wrong');
    }
  })
}
myImageFIle: any
myImageNew: any
myImage: any
onFileSelected(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  const file = inputElement.files[0];

  if (file) {
    this.previewImage(file);
  }
}
previewImage(file: File) {
  const reader = new FileReader();
  reader.onload = () => {
    this.myImageNew = reader.result;
  };
  $('#imgCheck').modal('show')
  reader.readAsDataURL(file);
  this.myImageFIle = file
}
imageUplaod() {
  this.uploadImageFunc(this.myImageFIle);
}
 /** to call form data */
 uploadImageFunc(img) {
  var fb = new FormData();
  fb.append('file', img)
  this.service.showSpinner();
  this.service.postApi('account/upload-file', fb).subscribe(res => {
    this.service.hideSpinner();
    if (res['status'] == '200') {
      this.myImage = res['data'];
  $('#imgCheck').modal('hide')

    }
  }, err => {
    this.service.hideSpinner();
    if (err['status'] == '401') {
      this.service.onLogout();
      this.service.toasterErr('Unauthorized Access');
    } else {
      this.service.toasterErr('Something Went Wrong');
    }
  })
}

// markup and markdown value

markDownPercentage: any
markUpPercentage : any
priceType = ""
viewFee(){
  /* this.service.showSpinner(); */
  var url="wallet/coin/get-coin-details?coinName=FIERO"
  this.service.showSpinner() 
  this.service.get(url).subscribe((res:any)=>{
    this.service.hideSpinner() 
    if(res.status==200){
     this.priceType = res.data.markStatus == 'ENABLE' ? 'MARK' : 'TRANCHES'
     this.markTab = res.data.markType
     this.percentageValue = this.markTab == 'UP' ? res.data.markUpPercentage : this.markTab == 'DOWN' ? res.data.markDownPercentage : 0
     this.markDownPercentage = res.data.markDownPercentage
     this.markUpPercentage = res.data.markUpPercentage
    }
  },(err)=>{
    if(err.status==401){
      this.service.hideSpinner();
      this.service.toasterErr('Unauthorized access')
    }
    else{
      this.service.toasterErr('something went wrong')
    }
  })
}
coinList: any=[];
cointAmount : any = []
price : any = []
startDate : any = []
endDate : any = []
supply : any = []
usd : any = []
fieroPrices : number
finalPrice : any 

markTab = ''
setMarkTab(e){
  this.markTab = e
  this.setFinalPrice(Number(this.markTab == 'UP' ? this.markUpPercentage : this.markDownPercentage))
}
percentageValue : any
percentage:any
setMarkupPercentage(){
  this.percentage = 0
  if(this.markTab == 'UP'){
    this.percentage = this.markUpPercentage
  }
  else if(this.markTab == 'DOWN'){
    this.percentage = this.markDownPercentage
  }
  console.log(this.percentage);
  
  $('#markUpModal').modal('hide')   
  // this.getFieroPrice()     
  if(this.addFaqForm.value.salePrice === 'TRANC'){
    if(this.markTab == 'UP' || this.markTab =='DOWN'){
     this.addFaqForm.patchValue({
       tokenPrice : this.finalPrice
     })
    }else{
     this.addFaqForm.patchValue({
       tokenPrice : this.fieroPrices.toFixed(2)
     })
    }
   }
}
setFinalPrice(e : number){
  e = e > 100 ? 100 : e < 0 ? 0 : e 
  if(this.markTab == 'UP'){
    this.finalPrice =  Number(this.fieroPrices + (this.fieroPrices * e / 100)).toFixed(2)
  }
  else if(this.markTab == 'DOWN'){
    this.finalPrice = Number(this.fieroPrices - (this.fieroPrices * e / 100)).toFixed(2)
  }
  else{
    this.finalPrice = this.fieroPrices 
  }
}
toFixed(number, toLength: number) {
  return Number(number).toFixed(toLength)
}
}
