<div class="wrapper">
  <!-- Aside End -->
  <main class="middle-content">
    <!-- Page Title Start -->
    <div class="page_title_block">
      <h1 class="page_title" style="color: black">Add Coin</h1>
    </div>
    <!-- Page Title End -->
    <div class="content-section">
      <div class="order-view mt30 max-WT-700 mrgn-0-auto">
        <div class="main-block-innner mb40 mt40">
          <div class="add-store-block input-style">
            <form [formGroup]="addTokenCoinForm">
              <div class="form-group row">
                <label class="col-md-4">Coin Name :</label>
                <div class="col-md-8 col-sm-12">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="coinName"
                    (keypress)="service.preventSpace($event)"
                  />

                  <span class="error">
                    <p
                      *ngIf="
                        addTokenCoinForm.get('title').hasError('required') &&
                        (addTokenCoinForm.get('title').dirty ||
                          addTokenCoinForm.get('title').touched)
                      "
                      class="error"
                      padding
                    >
                      *Coin name is required.
                    </p>
                  </span>
                </div>
              </div>
              <div class="text-center mt40" style="margin-right: -14%">
                <a
                  class="btn btn-large max-WT-200 font-100 btn-grey mr-5"
                  [routerLink]="['/token-coin-list']"
                  >Cancel</a
                >
                <button
                  style="
                    background: linear-gradient(
                      90deg,
                      #ffc500 0%,
                      #d78a01 50.63%,
                      #ffc500 100%
                    ) !important;
                  "
                  class="btn btn-large max-WT-200 font-100 btn-green ml8"
                  (click)="addCoin()"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
  <!-- Middle Content End -->
</div>

<footer>
  <div class="container-fluid">
    <p class="copyright">
      REH ICO Crypto © 2023 All Rights Reserved.
      <strong class="theme-text-color"></strong>
    </p>
  </div>
</footer>
