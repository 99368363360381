<main class="middle-content">
  <div class="page_title_block">
    <h1 class="page_title">View Transaction Detail</h1>
  </div>
  <div class="content-section">
    <div class="shadow p-3 mb-5 bg-white rounded">
      <div class="outer-box">
        <div class="global-table no-radius p0">
          <div class="tab-content1">
            <div class="container-fluid">
              <div class="row" style="align-items: center">
                <div class="col-lg-4 col-md-4 col-sm-12">
                  <div
                    class="editprofile"
                    *ngIf="userDetail?.imageUrl == 'String'"
                  >
                    <img
                      src="assets/img/editprofile.png"
                      alt="Profile Image"
                      width="auto"
                    />
                  </div>
                  <div
                    class="editprofile"
                    *ngIf="
                      userDetail?.imageUrl != 'String' || userDetail?.imageUrl
                    "
                  >
                    <img
                      [src]="
                        userDetail?.imageUrl || 'assets/img/editprofile.png'
                      "
                      alt="Profile Image"
                      width="auto"
                    />
                  </div>
                </div>

                <div class="col-lg-8 col-md-8 col-sm-12">
                  <div class="row">
                    <div class="col-5">
                      <p class="sub_head">User Name:</p>
                    </div>
                    <div class="col-2 text-center">
                      <span>:</span>
                    </div>
                    <div class="col-5">
                      <p class="sub_title">{{ viewData?.userName || "N/A" }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-5">
                      <p class="sub_head">Email Id:</p>
                    </div>
                    <div class="col-2 text-center">
                      <span>:</span>
                    </div>
                    <div class="col-5">
                      <p class="sub_title">{{ viewData?.userEmail }}</p>
                    </div>
                  </div>
                  <div class="row" *ngIf="viewData?.icoName">
                    <div class="col-5">
                      <p class="sub_head">Plan Type:</p>
                    </div>
                    <div class="col-2 text-center">
                      <span>:</span>
                    </div>
                    <div class="col-5">
                      <p class="sub_title">{{ viewData?.icoName || "N/A" }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-5">
                      <p class="sub_head">Txn Type:</p>
                    </div>
                    <div class="col-2 text-center">
                      <span>:</span>
                    </div>
                    <div class="col-5">
                      <p class="sub_title">{{ viewData?.txnType || "N/A" }}</p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-5">
                      <p class="sub_head">Transaction Id:</p>
                    </div>
                    <div class="col-2 text-center">
                      <span>:</span>
                    </div>
                    <div class="col-5">
                      <p class="sub_title">{{ viewData?.txnId }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-5">
                      <p class="sub_head">Transaction Hash:</p>
                    </div>
                    <div class="col-2 text-center">
                      <span>:</span>
                    </div>
                    <div class="col-5">
                      <p class="sub_title">{{ viewData?.txnHash }}</p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-5">
                      <p class="sub_head">Transaction Created At</p>
                    </div>
                    <div class="col-2 text-center">
                      <span>:</span>
                    </div>
                    <div class="col-5">
                      <p class="sub_title">
                        {{ viewData?.txnTime | date : "medium" }}
                      </p>
                    </div>
                  </div>
                  <div class="row" *ngIf="viewData?.country">
                    <div class="col-5">
                      <p class="sub_head">Country</p>
                    </div>
                    <div class="col-2 text-center">
                      <span>:</span>
                    </div>
                    <div class="col-5">
                      <p class="sub_title">{{ viewData?.country }}</p>
                    </div>
                  </div>
                  <div class="row" *ngIf="viewData?.randomId">
                    <div class="col-5">
                      <p class="sub_head">Random Id</p>
                    </div>
                    <div class="col-2 text-center">
                      <span>:</span>
                    </div>
                    <div class="col-5">
                      <p class="sub_title">{{ viewData?.randomId }}</p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-5">
                      <p class="sub_head">Coin Type</p>
                    </div>
                    <div class="col-2 text-center">
                      <span>:</span>
                    </div>
                    <div class="col-5">
                      <p class="sub_title">{{ viewData?.coinType }}</p>
                    </div>
                  </div>
                  <div class="row" *ngIf="viewData?.type">
                    <div class="col-5">
                      <p class="sub_head">Type</p>
                    </div>
                    <div class="col-2 text-center">
                      <span>:</span>
                    </div>
                    <div class="col-5">
                      <p class="sub_title">{{ viewData?.type }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-5">
                      <p class="sub_head">Status:</p>
                    </div>
                    <div class="col-2 text-center">
                      <span>:</span>
                    </div>
                    <div class="col-5">
                      <p class="sub_title">{{ viewData?.status }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center mt40">
              <a
                class="btn btn-large max-WT-200 font-100 btn-grey ml8"
                [routerLink]="['/transactionList']"
                >Back</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<footer>
  <div class="container-fluid">
    <p class="copyright">
      REH ICO Crypto © 2023 All Rights Reserved.<strong
        class="theme-text-color"
      ></strong>
    </p>
  </div>
</footer>
<!-- appraved_modal Start -->

<!-- appraved_modal End -->
<!-- rejected_modal Start -->
<div class="modal fade global-modal reset-modal" id="rejectModal">
  <div class="modal-dialog max-WT-500">
    <form class="change_password" [formGroup]="modalForm">
      <div class="modal-content">
        <div>
          <div class="modal-body">
            <div
              class="text-center modal_flax_height d-flex align-items-center justify-content-center"
            >
              <div class="w-100">
                <p style="font-size: 20px">
                  Are you sure you want to revise this kyc?
                </p>

                <div class="row" style="margin-bottom: 10px">
                  <div class="col-md-4">
                    <p style="font-size: 16px">Reason:</p>
                  </div>
                  <div class="col-md-8">
                    <textarea
                      formControlName="reason"
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      style="height: 115px"
                    ></textarea>
                  </div>
                </div>
                <!-- <div style="margin-top: 2%;margin-bottom: 2%; margin-right: 13%;">
                                    <input class="form-check-input" style="margin-top: 2%;" type="checkbox" value=""
                                        id="Notify User">

                                </div> -->
                <div style="margin-left: 27%">
                  <button
                    type="submit"
                    class="btn btn-info mr-2"
                    style="width: 25%"
                    (click)="rejectKyc()"
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    data-dismiss="modal"
                    style="width: 25%"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- rejected_modal End -->
<!--image view modal-->
<div
  class="modal fade global-modal reset-modal"
  id="imgg"
  style="width: 60%; margin-left: 25%"
>
  <div class="modal-dialog max-WT-500">
    <div class="modal-content">
      <div>
        <div class="row align-items-center modal_flax_height">
          <div class="col" style="text-align: center; font-size: 25px">
            <i
              class="fas fa-times"
              data-dismiss="modal"
              style="float: right; margin-right: 2px"
              aria-hidden="true"
            ></i>
            <strong>Front Image</strong>
            <img
              src="{{
                viewData?.frontIdUrl
                  ? viewData?.frontIdUrl
                  : 'assets/img/noimg.png'
              }}"
              alt=""
              style="width: 100%; z-index: 1"
            />
          </div>
        </div>
      </div>
      <!--   -->
    </div>
  </div>
</div>

<div
  class="modal fade global-modal reset-modal"
  id="backimgg"
  style="width: 60%; margin-left: 25%"
>
  <div class="modal-dialog max-WT-500">
    <div class="modal-content">
      <div>
        <div class="row align-items-center modal_flax_height">
          <div class="col" style="text-align: center; font-size: 25px">
            <i
              class="fas fa-times"
              data-dismiss="modal"
              style="float: right; margin-right: 2px"
              aria-hidden="true"
            ></i>

            <strong>Back Image</strong>

            <img
              src="{{
                viewData?.backIdUrl
                  ? viewData?.backIdUrl
                  : 'assets/img/noimg.png'
              }}"
              alt=""
              style="width: 100%; z-index: 1"
            />
          </div>
        </div>
      </div>
      <!--   -->
    </div>
  </div>
</div>
