import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MainService } from "src/app/provider/main.service";

@Component({
  selector: "app-wallet-management",
  templateUrl: "./wallet-management.component.html",
  styleUrls: ["./wallet-management.component.css"],
})
export class WalletManagementComponent implements OnInit {
  @Input() userId: any;
  userForm: FormGroup;
  currTab: any = "Withdraw";
  withdrawlData: any = [];
  pageNumber: number = 1;
  depositeData: any = [];
  coin: any;
  coinName: any;
  coinData: any = [];
  url: string;
  walletBalance: any = [];
  id: any;
  constructor(
    private router: Router,
    public service: MainService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
  }

  ngOnInit(): void {
    console.log(this.userId);
    // this.coinList()
    this.withdrawl();
    this.userForm = new FormGroup({
      startdate: new FormControl("", Validators.required),
      enddate: new FormControl("", Validators.required),
      select: new FormControl("", Validators.required),
      searchText: new FormControl(""),
    });
    // this.userTrading();
  }

  selectTab(tab) {
    this.currTab = tab;
    if (this.currTab === "Withdraw") {
      this.withdrawl();
    } else if (this.currTab === "Deposite") {
      this.deposite();
    }
  }

  coinList() {
    var url =
      "wallet/coin/get-coin-list?page=" +
      (this.pageNumber - 1) +
      "&pageSize=10";
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res.status == 200) {
          this.coinData = res.data.resultlist;
          console.log(this.coinData);
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  // Api integration of withdrawl
  withdrawl() {
    var url =
      "wallet/get-all-transaction-history?page=" +
      (this.pageNumber - 1) +
      "&pageSize=10" +
      "&fkUserId=" +
      this.userId +
      "&order=desc";
    // var url = "wallet/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&fkUserId=" + this.userId + "&order=desc&status=CONFIRM" ;
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        console.log("df", res);
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.withdrawlData = res["data"]["resultlist"];
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
          this.service.hideSpinner();
        } else {
          this.service.hideSpinner();
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
    this.service.showSpinner();
  }

  // Api integration of deposite
  deposite() {
    var url =
      "wallet/get-all-transaction-history?page=" +
      (this.pageNumber - 1) +
      "&pageSize=10" +
      "&txnType=DEPOSIT" +
      "&fkUserId=" +
      this.userId +
      "&order=desc";
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res) => {
        console.log("df", res);
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.depositeData = res["data"]["resultlist"];
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
    this.service.hideSpinner();
  }

  // Api of search
  search() {
    this.coinName = this.userForm.value.select;
    // let fromDate=Date.parse(this.userForm.value.startdate);
    let fromDate = Date.parse(this.userForm.value.startdate);
    let currentTime = new Date().getTime();
    console.log("hdh", fromDate);

    let toDate = Date.parse(this.userForm.value.enddate);
    console.log("jhhj", toDate + currentTime, currentTime);

    if (
      this.userForm.value.enddate &&
      this.coinName &&
      this.currTab === "Withdraw"
    ) {
      this.url =
        "wallet/get-all-transaction-history?page=" +
        (this.pageNumber - 1) +
        "&pageSize=10" +
        "&coinName=" +
        this.coinName +
        "&fromDate=" +
        fromDate +
        "&toDate=" +
        toDate +
        "&txnType=WITHDRAW" +
        "&order=desc";
    } else if (this.userForm.value.enddate && this.currTab === "Withdraw") {
      this.url =
        "wallet/get-all-transaction-history?page=" +
        (this.pageNumber - 1) +
        "&pageSize=10" +
        "&fromDate=" +
        fromDate +
        "&toDate=" +
        toDate +
        "&txnType=WITHDRAW" +
        "&order=desc";
    } else if (this.userForm.value.startdate && this.currTab === "Withdraw") {
      this.url =
        "wallet/get-all-transaction-history?page=" +
        (this.pageNumber - 1) +
        "&pageSize=10" +
        "&fromDate=" +
        fromDate +
        "&txnType=WITHDRAW" +
        "&order=desc";
    } else if (this.coinName && this.currTab === "Withdraw") {
      this.url =
        "wallet/get-all-transaction-history?page=" +
        (this.pageNumber - 1) +
        "&pageSize=10" +
        "&coinName=" +
        this.coinName +
        "&txnType=WITHDRAW" +
        "&order=desc";
    } else if (
      this.userForm.value.enddate &&
      this.coinName &&
      this.currTab === "Deposite"
    ) {
      this.url =
        "wallet/get-all-transaction-history?page=" +
        (this.pageNumber - 1) +
        "&pageSize=10" +
        "&coinName=" +
        this.coinName +
        "&fromDate=" +
        fromDate +
        "&toDate=" +
        toDate +
        "&txnType=DEPOSIT" +
        "&order=desc";
    } else if (this.userForm.value.enddate && this.currTab === "Deposite") {
      this.url =
        "wallet/get-all-transaction-history?page=" +
        (this.pageNumber - 1) +
        "&pageSize=10" +
        "&fromDate=" +
        fromDate +
        "&toDate=" +
        toDate +
        "&txnType=DEPOSIT" +
        "&order=desc";
    } else if (this.userForm.value.startdate && this.currTab === "Deposite") {
      this.url =
        "wallet/get-all-transaction-history?page=" +
        (this.pageNumber - 1) +
        "&pageSize=10" +
        "&fromDate=" +
        fromDate +
        "&txnType=DEPOSIT" +
        "&order=desc";
    } else if (this.coinName && this.currTab === "Deposite") {
      this.url =
        "wallet/get-all-transaction-history?page=" +
        (this.pageNumber - 1) +
        "&pageSize=10" +
        "&coinName=" +
        this.coinName +
        "&txnType=DEPOSIT" +
        "&order=desc";
    }

    this.service.get(this.url).subscribe(
      (res: any) => {
        console.log("search", res);

        if (res["status"] == 200) {
          this.service.toasterSucc(res.message);
          // this.withdrawlData=res['data']['resultlist']
          if (this.currTab === "Withdraw") {
            this.withdrawlData = res["data"]["resultlist"];
            this.userForm.reset();
          } else if (this.currTab === "Deposite") {
            this.depositeData = res["data"]["resultlist"];
            this.userForm.reset();
          }
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
    this.service.hideSpinner();
  }
  saleData: any;
  saleList() {
    var url = "wallet/get-sale-list";
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.saleData = res["data"]["enabled_sale"];
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  // reset data of search
  reset() {
    if (this.currTab === "Withdraw") {
      this.withdrawl();
      this.userForm.reset();
    } else if (this.currTab === "Deposite") {
      this.deposite();
      this.userForm.reset();
    }
  }

  //export User
  exportAsXLSX() {
    let dataArr = [];
    if (this.currTab === "Withdraw") {
      this.withdrawlData.forEach((element, ind) => {
        dataArr.push({
          "S no": ind + 1,
          Coin: element.coinType ? element.coinType : "",
          "Transaction Type": element.txnType ? element.txnType : "",
          Email: element.userEmail ? element.userEmail : "N/A",
          "Transaction Hash": element.txnHash ? element.txnHash : "N/A",
          Amount: element.amount ? element.amount : "N/A",
          Date: element.txnTime ? element.txnTime.slice(0, 10) : "N/A",
        });
      });

      this.service.exportAsExcelFile(dataArr, "WALLET MANAGEMENT");
    } else if (this.currTab === "Deposite") {
      this.depositeData.forEach((element, ind) => {
        dataArr.push({
          "S no": ind + 1,
          Coin: element.coinType ? element.coinType : "",
          "Transaction Type": element.txnType ? element.txnType : "",
          // "Email": element.userEmail ? element.userEmail : 'N/A',
          "Transaction Hash": element.txnHash ? element.txnHash : "N/A",
          Amount: element.amount ? element.amount : "N/A",
          Status: element.status ? element.status : "N/A",
          Date: element.txnTime ? element.txnTime.slice(0, 10) : "N/A",
        });
      });

      this.service.exportAsExcelFile(dataArr, "WALLET MANAGEMENT");
    }
  }

  // wallet balance
  // api of Trading
  userTrading() {
    this.saleList();
    var url =
      "wallet/wallet/get-all-user-balance-and-coinlist-withName?userId=" +
      this.id;
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.walletBalance = res["data"]["userBalance"][0];
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  shortAddress(address) {
    if (address === null) {
      return "N/A"; // or handle the null case in your desired way
    }
    const shortedValue = `${address.slice(0, 6)}....${address.slice(
      address.length - 4
    )}`;
    return shortedValue;
  }
  copyToClipboard(item) {
    navigator.clipboard.writeText(item).then(
      () => {
        this.service.toasterSucc("Copied");
      },
      () => {
        this.service.toasterErr("Failed");
      }
    );
  }
  toFixed(number, toLength: number) {
    return parseFloat(number).toFixed(toLength);
  }
}
