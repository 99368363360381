<div class="wrapper">
  <!-- Aside End -->
  <main class="middle-content">
    <!-- Page Title Start -->
    <div class="page_title_block">
      <h1 class="page_title" style="color: #fff">Edit Staking</h1>
    </div>
    <!-- Page Title End -->
    <div class="content-section">
      <div class="order-view mt30 mrgn-0-auto">
        <div class="main-block-innner mb40 mt40">
          <div class="input-style">
            <form [formGroup]="addFaqForm">
              <div class="form-group row">
                <div class="col-md-6 col-sm-12">
                  <label class="col-md-4" class="labelBox"
                    >Terms (Days):
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="terms"
                    (keypress)="service.preventSpace($event)"
                    readonly
                  />
                </div>
                <div class="col-md-6 col-sm-12">
                  <label class="col-md-4" class="labelBox mb-0">
                    Percentage %:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="percentage"
                    (keypress)="service.preventSpace($event)"
                  />
                </div>
                <div class="col-md-6 col-sm-12" style="margin-top: 20px">
                  <label class="col-md-4" class="labelBox">
                    Renew Percentage % :
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="renewPercentage"
                    (keypress)="service.preventSpace($event)"
                  />
                </div>
                <div class="col-md-6 col-sm-12" style="margin-top: 20px">
                  <label class="col-md-4" class="labelBox">
                    Extra Bonus Percentage % :
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="extraBonusPercentage"
                    (keypress)="service.preventSpace($event)"
                  />
                </div>
              </div>
              <div class="text-center mt40">
                <button
                  class="btn btn-large max-WT-200 font-100 btn-grey mr-2 mt-2"
                  routerLink="/vesting-plan-list"
                >
                  Cancel
                </button>
                <button
                  class="btn btn-large max-WT-200 font-100 btn-green mr-2 mt-2"
                  (click)="addVestingPlan()"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
  <!-- Middle Content End -->
</div>

<footer>
  <div class="container-fluid">
    <p class="copyright">
      REH ICO Crypto © 2023 All Rights Reserved.
      <strong class="theme-text-color"></strong>
    </p>
  </div>
</footer>
