import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MainService } from "src/app/provider/main.service";

declare var $;

@Component({
  selector: "app-vesting-plan-edit",
  templateUrl: "./vesting-plan-edit.component.html",
  styleUrls: ["./vesting-plan-edit.component.css"],
})
export class VestingPlanEditComponent implements OnInit {
  selectedOption: string = "option1"; // Variable to store the selected option
  modalForm: FormGroup;
  modalFormBlacklist: FormGroup;
  addFaqForm: FormGroup;
  pageNumber: number = 1;
  currTab: any;
  userId: any;
  today: any = new Date().toISOString().slice(0, 16);
  loylity: FormGroup;
  planId: any;
  planDetails: any;
  isRadioEnabled = true; // Initial state is enabled
  constructor(
    public route: Router,
    public service: MainService,
    public active: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    this.active.queryParams.subscribe((params) => {
      const serializedObject = params["data"];
      if (serializedObject) {
        this.planDetails = JSON.parse(serializedObject);
        console.log(this.planDetails);
      }
    });
  }

  ngOnInit(): void {
    this.formValidation();
    this.modalForm = new FormGroup({
      address: new FormControl("", Validators.required),
    });
    this.modalFormBlacklist = new FormGroup({
      emailAddress: new FormControl("", Validators.required),
    });
  }

  formValidation() {
    this.addFaqForm = new FormGroup({
      terms: new FormControl(""),
      percentage: new FormControl("", [Validators.required]),
      renewPercentage: new FormControl("", [Validators.required]),
      extraBonusPercentage: new FormControl(""),
    });
    this.viewPlan();
  }

  viewPlan() {
    this.addFaqForm.patchValue({
      terms: this.planDetails.terms,
      percentage: this.planDetails.percentage,
      renewPercentage: this.planDetails.renewPercentage,
      extraBonusPercentage: this.planDetails.extraBonusPercentage,
    });
  }
  enable: any;
  markStatus: any;
  addVestingPlan() {
    this.service.showSpinner();
    let url = `wallet/update-staking?bonusPercentage=${this.addFaqForm.value.extraBonusPercentage}&id=${this.planDetails.id}&percentage=${this.addFaqForm.value.percentage}&renewPercentage=${this.addFaqForm.value.renewPercentage}`;
    this.service.put(url, {}).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.service.toasterSucc(res.message);
          this.route.navigate(["/vesting-plan-list"]);
          this.service.hideSpinner();
        } else {
          this.service.hideSpinner();
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
}
