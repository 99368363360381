import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MainService } from "src/app/provider/main.service";
declare var $: any;
@Component({
  selector: "app-vesting-plan-list",
  templateUrl: "./vesting-plan-list.component.html",
  styleUrls: ["./vesting-plan-list.component.css"],
})
export class VestingPlanListComponent implements OnInit {
  userForm: FormGroup;
  ticketList: any = [];
  page: number = 1;
  limit: number = 10;
  itemsPerPage: number = 10;
  modalForm: FormGroup;
  modalFormBlacklist: FormGroup;
  changeUserPaln = "NoUserPlan";
  totalRecords: any;
  pageNumber: number = 1;
  arr: any = ["RESOLVED", "INPROGRESS", "CLOSED", "CANCELLED"];
  updatestatus: string;
  isAssending: boolean = true;
  today: any = new Date().toISOString().slice(0, 16);
  permissionArr: any = {};
  constructor(private router: Router, public service: MainService) {
    this.service.permission.subscribe((res) => {
      if (Object.keys(res).length) {
        this.permissionArr = res;
        console.log(res);
      }
    });
  }

  ngOnInit() {
    this.userForm = new FormGroup({
      startdate: new FormControl("", Validators.required),
      enddate: new FormControl("", Validators.required),
      searchText: new FormControl(""),
      status: new FormControl(""),
    });
    this.modalForm = new FormGroup({
      address: new FormControl("", Validators.required),
    });
    this.modalFormBlacklist = new FormGroup({
      emailAddress: new FormControl("", Validators.required),
    });
    this.getListFiero();
  }

  vestingPlanList: any = [];
  getListFiero() {
    let url = `wallet/get-staking-list`;
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      this.vestingPlanList = res.data;
      // this.totalRecords = res.data.size;
    });
    this.service.hideSpinner();
  }

  isSearched: boolean = false;

  userTypePlan(plan) {
    this.changeUserPaln = plan;
  }

  // viewPlan(id) {
  //   this.router.navigate(["/vesting-plan-view"], { queryParams: { id: id } });
  // }
  editPlan(id) {
    const serializedObject = JSON.stringify(id);
    this.router.navigate(["/vesting-plan-edit"], {
      queryParams: { data: serializedObject },
    });
  }

  // sorting
  sortAgent(key) {
    if (this.isAssending) {
      this.ticketList = this.ticketList.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.ticketList = this.ticketList.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
  deleteID: any;
  addWhitePlanId: any;

  removePlanModal(id) {
    $("#removePlan").modal("show");
    this.deleteID = id;
  }
  deletePlan() {
    let url = `wallet/admin/delete-vesting-plans?id=${this.deleteID}`;
    this.service.showSpinner();
    this.service.delete(url).subscribe((res: any) => {
      if (res["status"] == 200) {
        this.service.showSpinner();
        $("#removePlan").modal("hide");
        this.getListFiero();
      } else {
        this.service.showSpinner();
      }
    });
    this.service.hideSpinner();
  }

  addWhiteList() {
    const emailString = [this.modalForm.value.address];
    const value = emailString[0]; // Assuming yourObject contains the data you provided

    const emailArray = value.split(",");
    const trimmedEmailArray = emailArray.map((email) => email.trim());
    const body = {
      email: trimmedEmailArray,
    };
    let url = `account/admin/user-management/add-vesting-whitelist?planId=${this.addWhitePlanId}`;
    this.service.put(url, body).subscribe(
      (res: any) => {
        if (res.status == 200) {
          $("#addPlanModal").modal("hide");
          this.service.toasterSucc(res["message"]);
        } else {
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  coinId: any;
  coinStatus: any;
  openModal(id, status) {
    this.coinId = id;
    this.coinStatus = status;
    if (status == true) {
      $("#blockModal").modal("show");
    } else {
      $("#activeModal").modal("show");
    }
  }
  // block functionallity of coin listing
  blockCoin() {
    let url = `wallet/update-status-staking?id=${this.coinId}&status=false`;
    this.service.showSpinner();
    this.service.put(url, {}).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.service.hideSpinner();
          $("#blockModal").modal("hide");
          this.service.toasterSucc(res.message);
          this.getListFiero();
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  // active
  activeCoin() {
    let url = `wallet/update-status-staking?id=${this.coinId}&status=true`;
    this.service.showSpinner();
    this.service.put(url, {}).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.service.hideSpinner();
          $("#activeModal").modal("hide");
          this.service.toasterSucc(res.message);
          this.getListFiero();
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
}
