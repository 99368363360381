import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $: any
@Component({
  selector: 'ip-whitelist',
  templateUrl: './ip-whitelist.component.html',
  styleUrls: ['./ip-whitelist.component.css']
})
export class IpWhitelistComponent implements OnInit {
  userForm: FormGroup;
  ticketList: any = [];
  page: number = 1;
  limit: number = 10;
  itemsPerPage: number = 10;

  totalRecords: any;
  pageNumber: number = 1;
  arr: any = ['RESOLVED', 'INPROGRESS', 'CLOSED', 'CANCELLED']
  updatestatus: string;
  isAssending: boolean = true;
  today: any = new Date().toISOString().slice(0, 16)
  ipdAddress: any
  permissionArr: any = {}
  constructor(
    private router: Router, public service: MainService
  ) {
    this.service.permission.subscribe((res) => {
      if (Object.keys(res).length) {
        this.permissionArr = res
        console.log(res);

      }
    })
    this.getIpAddrress()
  }

  ngOnInit() {
    this.userForm = new FormGroup({
      'startdate': new FormControl('', Validators.required),
      'enddate': new FormControl('', Validators.required),
      'searchText': new FormControl(''),
      'status': new FormControl(''),

    })
    this.getTicketList();
    this.getIpStatus();
  }
  status:any
  getIpStatus() {
    this.service.showSpinner();
    this.service.get(`/account/get-whitelisting-status`).subscribe((res: any) => {
      if(res.status==205){
        this.enableDisable("ENABLE")
      }
      this.status=res.data
    })
    this.service.hideSpinner()
  }

  getTicketList() {


    this.service.showSpinner();
    this.service.get(`account/admin/ipAddress-list?page=${this.pageNumber - 1}&pageSize=${this.limit}`).subscribe((res: any) => {
      this.ticketList = res.data.list
      this.totalRecords = res.data.size;
    })
    this.service.hideSpinner()
  }

  openAddModal() {
    $('#addModal').modal('show')
  }
  deleteId: any
  openDeleteModal(id) {
    this.deleteId = id
    $('#deleteModal').modal('show')
  }
  editIp: any
  editID: any
  openEditModal(ip, id) {
    this.editID = id
    this.editIp = ip
    $('#editModal').modal('show')
  }
  onadd() {

    let request = {
      "ipAddress": this.ipdAddress
    }
    this.service.showSpinner();
    this.service.post(`account/admin/add-ipAddress`, request).subscribe((res: any) => {
      $('#addModal').modal('hide')
      this.getTicketList();
    })
    this.service.hideSpinner()
  }
  onedit() {


    this.service.showSpinner();
    this.service.put(`account/admin/edit-ipAddress?id=${this.editID}&ipAddress=${this.editIp}`, {}).subscribe((res: any) => {
      $('#editModal').modal('hide')
      this.getTicketList();
    })
    this.service.hideSpinner()
  }
  blockType: any
  blockId: any
  statusType:any
  block(type, id) {
    this.blockId = id
    this.blockType = type
    if (type == 'ENABLE') {
      $('#disableip').modal('show')

    }
    else {
      $('#enableip').modal('show')
    }
  }
  enableDisable(type) {
    this.statusType = type
    if (type == 'ENABLE') {
      $('#Alldisableip').modal('show')

    }
    else {
      $('#Allenableip').modal('show')
    }
  }
  onDelete() {


    this.service.showSpinner();
    this.service.delete(`account/admin/delete-ipAddress?id=${this.deleteId}`).subscribe((res: any) => {
      $('#deleteModal').modal('hide')
      this.getTicketList();
    })
    this.service.hideSpinner()
  }
  onEnableDisable() {
    this.service.showSpinner();
    this.service.put(`account/admin/ip-whiteList-enable-disable?ipWhitelist=${this.statusType == 'ENABLE' ? 'DISABLE' : 'ENABLE'}`,{}).subscribe((res: any) => {
      $('#Allenableip').modal('hide')
      $('#Alldisableip').modal('hide')
     
      this.getIpStatus();
    })
    this.service.hideSpinner()
  }

  ip : any
  async getIpAddrress() {

    await fetch(`https://api.ipify.org/?format=json`).then(res => res.json()).then((data: any) => {
      console.log(data)
      this.ip = data.ip
      

    })
  }
  enableip() {
    this.service.showSpinner();

    this.service.put(`account/admin/enable-disable-ipAddress?id=${this.blockId}&ipWhitelistStatus=${this.blockType == 'ENABLE' ? 'DISABLE' : 'ENABLE'}`, {}).subscribe((res: any) => {
      $('#enableip').modal('hide')
      $('#disableip').modal('hide')
      this.getTicketList();
    })
    this.service.hideSpinner()
  }
  filterTicketList() {
    let startdate = Date.parse(this.userForm.value.startdate)
    let enddate = Date.parse(this.userForm.value.enddate)
    let search = this.userForm.controls.searchText.value;
    let status = this.userForm.controls.status.value;
    if (startdate && enddate && search && status) {
      var request = {
        'fromDate': startdate,
        'search': search,
        'ticketStatus': status,
        'toDate': enddate,
        'page': this.pageNumber - 1,
        'pageSize': this.limit
      }
    }

    else if (startdate && enddate) {
      var request1 = {
        'fromDate': startdate,
        'toDate': enddate,
        'page': this.pageNumber - 1,
        'pageSize': this.limit

      }
    }
    else if (search) {
      var request2 = {
        'search': search,
        'page': this.pageNumber - 1,
        'pageSize': this.limit
      }

    }

    else if (status) {
      var request3 = {
        'ticketStatus': this.userForm.controls.status.value,
        'page': this.pageNumber - 1,
        'pageSize': this.limit
      }


    }
    console.log('j', request, request1, request2, request3);
    this.service.showSpinner()
    this.service.post(`static/search-and-filter-ticket-list`, request || request1 || request2 || request3).subscribe((res: any) => {
      this.ticketList = res.data.list
      console.log('hh', this.ticketList);

      this.totalRecords = res.data.size;
    })
    this.service.hideSpinner()
    console.log('hitted');
    console.log(status);
  }
  isSearched: boolean = false;

  pagination(page) {
    // this.totalRecords = [];
    console.log("jh", page);
    this.pageNumber = page;
    console.log("jh", this.pageNumber);
    if (this.isSearched) {


      this.filterTicketList();
    } else {
      this.service.hideSpinner();
      this.getTicketList();
    }
  }
  updateTicketStatus(e, id: number) {
    let status = e.target.value
    id = id
    let data = {}
    this.service.post(`static/change-ticket-status?ticketId=${id}&ticketStatus=${status}`, data).subscribe((res: any) => {
      this.service.showSpinner()
      if (res.status = 200) {
        this.service.hideSpinner()
        this.service.toasterSucc(res.message)
      }
    })
  }

  // reset
  // reset(){
  //   this.userForm.reset()
  //   this.getTicketList(this.page)
  // }
  reset() {
    if (
      this.userForm.value.startdate ||
      this.userForm.value.enddate ||
      this.userForm.value.searchText ||
      this.userForm.value.status
    ) {
      this.userForm.reset({
        startdate: "",
        // userStatus: "",
        enddate: "",
        searchText: "",
        status: "",
      });
      this.getTicketList();
    }
  }
  replyTicket() {
    this.router.navigate(['/reply-ticket'])
  }
  viewTicket() {
    this.router.navigate(['/view-ticket'])
  }

  //export User
  exportAsXLSX() {
    let dataArr = [];
    this.ticketList.forEach((element, ind) => {

      dataArr.push({
        "S no": ind + 1,
        "Ticket ID": element.ticketId ? element.ticketId : '',
        "Message": element?.description ? element?.description : '',
        "Email": element.email ? element.email : 'N/A',
        "Customer Name": element.firstName + '' + element.lastName ? element.firstName + " " + (element.lastName ? element.lastName : '') : '',
        "Date": element.createdAt ? element.createdAt.slice(0, 10) : 'N/A',
        "Status": element.ticketStatus,
      })
    })

    this.service.exportAsExcelFile(dataArr, 'Queries Management');
    console.log(dataArr)
  }
  // sorting
  sortAgent(key) {
    if (this.isAssending) {
      this.ticketList = this.ticketList.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.ticketList = this.ticketList.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
}

