import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-all-user-trader',
  templateUrl: './all-user-trader.component.html',
  styleUrls: ['./all-user-trader.component.css']
})
export class AllUserTraderComponent implements OnInit {
  traderData: any=[];
  pageNumber:number=1
  length: any;
  traderForm: FormGroup;
  listing: any =[];
  spinner: any;



  constructor(public route:Router,public service:MainService) { 

  }
 

  ngOnInit(): void {
    this.allTrader()
    this.traderForm = new FormGroup({
      'searchText': new FormControl(''),
    })
  }

  search() {
    
    var search = this.traderForm.value.searchText;
    if( this.traderForm.value.searchText){
      var url="wallet/admin-basic-exchange/get-all-users-trading-history?userEmail="+search+'&page=0'+'&pageSize=5'+'&userId=1'
    }
   
    this.service.get( url ).subscribe((res: any) => {
       this.traderData = res.data.list;
      // console.log('kfg',this.listing);
      // this.totalRecords = res.data.totalCount
    })
  }

  reset(){
    this.traderForm.reset()
    this.allTrader()
  }
  // all trader
  allTrader(){
    var url = "wallet/admin-basic-exchange/get-all-users-trading-history?page="+(this.pageNumber-1)+ "&pageSize=500"+"&userId=1"
    this.service.showSpinner() 
    this.service.get(url).subscribe((res)=>{
      this.service.hideSpinner()
      console.log('df', res);
     
      if(res['status']==200){
        this.traderData=res['data']['list'];
        this.length=res['data']['size']
        console.log('k', this.length);
        
        
      }
      
    },(err)=>{
      if(err['status']==401){
        this.service.hideSpinner()
        this.service.toasterErr('Unauthorized Access')
      }
      else{
        this.service.toasterErr('Something Went Wrong');
     }
    })
  }
  pagination(page){
    this.traderData=''
    this.pageNumber=page;
    this.allTrader()
  }
  exportAsXLSX() {
    let dataArr = [];
    this.traderData.forEach((element, ind) => {

      dataArr.push({
        "S no": ind + 1,
        "Transaction Id":element.basicTradeHistoryId,
        
        "Transaction Type":element.orderType,
        // "Ticket ID": element.ticketId ? element.ticketId : '',
        // "Customer Name": element.firstName + '' + element.lastName ? element.lastName : '',
       "User Email": element.userEmail ? element.userEmail : 'N/A',
         //"Status": element.userStatus == 'ACTIVE' ? 'Active' : 'Inactive',
         "Base Coin Amount":element.baseCoinAmmount,
         "Exec Coin Amount":element.execCoinamount,
        'Base Coin Name': element.baseCoinName,
        'Executable Coin Name': element.execCoinName,
        "Date": element.creationTime ? element.creationTime.slice(0, 10) : 'N/A',
        'Status':element.status,
       
     
      })
    })

    this.service.exportAsExcelFile(dataArr, 'All User Trader');
    console.log(dataArr)
  }
}
