<div class="wrapper">
  <main class="middle-content">
    <div class="page_title_block" style="margin-top: 5em !important">
      <h1 class="page_title">Whitepaper / Add</h1>
    </div>
    <div class="content-section">
      <div class="order-view max-WT-900 mrgn-0-auto setting-page">
        <div class="mb40">
          <div class="add-store-block input-style mb20 mt20 view-label-align">
            <form [formGroup]="addSwatchesForm" #f="ngForm">
              <div class="form-group row align-items-baseline">
                <label class="col-md-5 textalignright"
                  >Whitepaper Language <span>:</span></label
                >
                <div class="col-md-7 textaligncenter">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Whitepaper Language"
                      formControlName="language"
                      (keypress)="service.preventSpace($event)"
                      maxlength="60"
                    />

                    <!-- <i *ngIf="imageUrl"   (click)="remove(1)" class="fa fa-trash new-delete" aria-hidden="true"></i>
                                        <button *ngIf="imageUrl" class="view-img" [disabled]="!imageUrl"><a [href]="imageUrl" target="_blank"><i class="fa fa-eye" aria-hidden="true"></i></a></button> -->
                  </div>
                </div>
              </div>
              <div class="form-group row align-items-baseline">
                <label class="col-md-5 textalignright docccs"
                  >Whitepaper Document <span>:</span></label
                >
                <div class="col-md-7 textaligncenter">
                  <div class="form-group d-flex">
                    <input
                      type="file"
                      *ngIf="!imageUrl"
                      formControlName="image"
                      name="image"
                      accept=".pdf"
                      id="image"
                      (change)="uploadImg($event)"
                    />
                    <img
                      src="assets/img/pdf-icon.png"
                      *ngIf="imageUrl"
                      class="pdf-size"
                      (click)="downloadPdf(imageUrl)"
                      alt=""
                    />

                    <!-- <object
                      *ngIf="imageUrl"
                      [data]="sanitizer?.bypassSecurityTrustResourceUrl(imageUrl)"
                      type="application/pdf"
                      width="100%"
                      height="200px"
                    >
                      <iframe
                        [src]="
                          sanitizer?.bypassSecurityTrustResourceUrl(imageUrl)
                        "
                        width="100%"
                        height="100%"
                        style="border: none"
                      >
                        <p>
                          Your browser does not support PDFs.
                          <a
                            [href]="
                              sanitizer?.bypassSecurityTrustResourceUrl(imageUrl)
                            "
                            >Download the PDF</a
                          >
                          .
                        </p>
                      </iframe>
                    </object> -->
                    <i
                      *ngIf="imageUrl"
                      (click)="remove(1)"
                      class="fa fa-trash new-delete"
                      aria-hidden="true"
                    ></i>
                    <!-- <button
                      *ngIf="imageUrl"
                      class="view-img"
                      [disabled]="!imageUrl"
                    >
                      <a [href]="imageUrl" target="_blank"
                        ><i class="fa fa-eye" aria-hidden="true"></i>
                      </a>
                    </button> -->
                  </div>
                </div>
              </div>
              <div class="form-group row align-items-baseline">
                <label class="col-md-5 textalignright docccs"
                  >Whitepaper Image <span>:</span></label
                >
                <div class="col-md-7 textaligncenter">
                  <div class="form-group d-flex">
                    <input
                      type="file"
                      *ngIf="!urlPdf"
                      formControlName="imagePdf"
                      name="image"
                      accept="image/png, image/jpeg, image/jpg, image/svg"
                      id="image"
                      (change)="uploadPdf($event)"
                    />
                    <img [src]="urlPdf" alt="" />

                    <i
                      *ngIf="urlPdf"
                      (click)="removeimage(1)"
                      class="fa fa-trash new-delete"
                      aria-hidden="true"
                    ></i>
                    <!-- <object *ngIf="urlPdf"
                                            [data]="sanitizer?.bypassSecurityTrustResourceUrl(urlPdf)"
                                            type="application/pdf" width="100%" height="200px">
                                            <iframe [src]="sanitizer?.bypassSecurityTrustResourceUrl(urlPdf)"
                                                width="100%" height="100%" style="border: none;">
                                                <p>
                                                    Your browser does not support PDFs.
                                                    <a [href]="sanitizer?.bypassSecurityTrustResourceUrl(urlPdf)">Download
                                                        the PDF</a>
                                                    .
                                                </p>
                                            </iframe>
                                        </object> -->
                    <!-- <i *ngIf="urlPdf" (click)="remove(1)" class="fa fa-trash new-delete"
                                            aria-hidden="true"></i>
                                        <button *ngIf="urlPdf" class="view-img" [disabled]="!urlPdf"><a
                                                [href]="urlPdf" target="_blank"><i class="fa fa-eye"
                                                    aria-hidden="true"></i>
                                            </a></button> -->
                  </div>
                </div>
              </div>
              <!-- <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright ">Whitepaper  France <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group d-flex">
                                        <input type="file" formControlName="image" name="image"
                                            accept=".pdf" id="image"
                                            (change)="uploadImg($event,'FRANCE')" >
                                            <i *ngIf="imageUrl1"   (click)="remove(2)" class="fa fa-trash new-delete" aria-hidden="true"></i>
                                            <button *ngIf="imageUrl1" class="view-img" [disabled]="!imageUrl1"><a [href]="imageUrl" target="_blank"><i class="fa fa-eye" aria-hidden="true"></i>
                                            </a></button>
                                    </div>
                                </div>

                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright ">Whitepaper  Arabic <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group d-flex">
                                        <input type="file" formControlName="image" name="image"
                                            accept=".pdf" id="image"
                                            (change)="uploadImg($event,'ARABIC')" >
                                            <i *ngIf="imageUrl2"   (click)="remove(3)" class="fa fa-trash new-delete" aria-hidden="true"></i>
                                            <button *ngIf="imageUrl2" class="view-img" [disabled]="!imageUrl2"><a [href]="imageUrl" target="_blank"><i class="fa fa-eye" aria-hidden="true"></i>
                                            </a></button>
                                    </div>
                                </div>

                            </div> -->
            </form>
          </div>
          <div class="text-center mt40 mb40">
            <button
              class="btn btn-large max-WT-150 btn-theme"
              routerLink="/whitepaper-list"
              style="background: #333 !important"
            >
              Back
            </button>
            <button
              class="btn btn-large max-WT-150 btn-theme ml-3"
              (click)="addWhitePaper()"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>

<footer>
  <div class="container-fluid">
    <p class="copyright">
      REH ICO Crypto © 2023 All Rights Reserved.
      <strong class="theme-text-color"></strong>
    </p>
  </div>
</footer>
