import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MainService } from "src/app/provider/main.service";

declare var $: any;
@Component({
  selector: "app-token-coin-list",
  templateUrl: "./token-coin-list.component.html",
  styleUrls: ["./token-coin-list.component.css"],
})
export class TokenCoinListComponent implements OnInit {
  faqData: any = [];
  pageNumber: number = 1;
  coinId: any;
  isPublished: any;
  lan: any;
  totalRecords: any;
  itemsPerPage: number = 10;
  permissionArr: any = {};
  constructor(public route: Router, public service: MainService) {}

  ngOnInit(): void {
    this.faqList();
  }

  // tab function

  // faq english
  faqList() {
    var url = `wallet/coin/get-all-market-coins?page=${
      this.pageNumber - 1
    }&pageSize=${this.itemsPerPage}`;
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.faqData = res.data["list"]["content"];
          this.totalRecords = res["data"]["count"];
        }

        this.service.hideSpinner();
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.hideSpinner();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.hideSpinner();
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  isSearched: boolean = false;
  pagination(page) {
    this.pageNumber = page;
    this.faqList();
  }
  // add faq
  addFaq() {
    this.route.navigate(["/token-coin-add"]);
  }

  // edit faq
  editFaq(faqId) {
    this.route.navigate(["/edit-faq"], { queryParams: { faqId: faqId } });
  }

  viewFaq(faqId) {
    this.route.navigate(["/view-faq"], { queryParams: { faqId: faqId } });
  }
  // open modal
  coinStatus: any;
  openModal(id, status) {
    this.coinId = id;
    this.coinStatus = status;
    if (status == "delete") {
      $("#deleteModal").modal("show");
    } else if (status == true) {
      $("#blockModal").modal("show");
    } else {
      $("#activeModal").modal("show");
    }
  }

  // delete functionallity of coin listing
  deleteCoin() {
    let url = "wallet/coin/delete-market-coin-by-id?coinId=" + this.coinId;
    this.service.showSpinner();
    this.service.delete(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.service.hideSpinner();
          $("#deleteModal").modal("hide");
          this.service.toasterSucc(res.message);
          this.faqList();
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  // block functionallity of coin listing
  blockCoin() {
    let url = `wallet/coin/update-status?coinId=${this.coinId}&status=false`;
    this.service.showSpinner();
    this.service.put(url, {}).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.service.hideSpinner();
          $("#blockModal").modal("hide");
          this.service.toasterSucc(res.message);
          this.faqList();
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  // active
  activeCoin() {
    let url = `wallet/coin/update-status?coinId=${this.coinId}&status=true`;
    this.service.showSpinner();
    this.service.put(url, {}).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.service.hideSpinner();
          $("#activeModal").modal("hide");
          this.service.toasterSucc(res.message);
          this.faqList();
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
}
