import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { countryJson } from 'src/assets/country';
declare var $: any;
@Component({
  selector: 'app-token-trnsaction-detail',
  templateUrl: './token-trnsaction-detail.component.html',
  styleUrls: ['./token-trnsaction-detail.component.css']
})
export class TokenTrnsactionDetailComponent implements OnInit {
  @Input() userId: any
  userForm: FormGroup;
  currTab: any = "Withdraw";
  withdrawlData: any = [];
  pageNumber: number = 1;
  itemsPerPage: number = 10;
  depositeData: any = [];
  totalRecords: any
  coin: any;
  coinName: any;
  coinData: any = [];
  url: string;
  userDetail:any
  walletBalance: any = [];
  isSearched: boolean = false;
  id: any;
  documentId: any;
  permissionArr : any = {}
  kycId: any;
  today: any = new Date().toISOString().slice(0, 16)
  constructor(private router: Router, public service: MainService, private route: ActivatedRoute,private date: DatePipe) {
    this.route.params.subscribe((params) => {
      this.id = params.id

    })
    this.service.permission.subscribe((res) => {
      if (Object.keys(res).length) {
        this.permissionArr = res
        console.log(res);

      }
    })
  }

  ngOnInit(): void {
    // this.withdrawl();
    this.userForm = new FormGroup({
      startdate: new FormControl(""),
      enddate: new FormControl(""),
      searchText: new FormControl(""),
      country: new FormControl(""),
      userStatus: new FormControl(""),
      ramdomId: new FormControl("")
    });
    this.coinList()
    this.myProfile();
    this.getClaimData();
    this.countryList();
  }
   products: any = [];
  countryList() {
    let obj = new countryJson()
    this.products = obj.country
  }

  

  coinList() {
    // var url = "wallet/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=" + (this.itemsPerPage) + "&type=BUY_TOKEN&order=desc&txnType=CLAIM";

    let startdate = Date.parse(this.userForm.value.startdate);

    let enddate = Date.parse(this.userForm.value.enddate);
    // let startdate = this.userForm.value.startdate;

    // let enddate = this.userForm.value.enddate;

    var search = this.userForm.value.searchText;
    let ramdomId = this.userForm.value.ramdomId;
    let url = `wallet/get-all-transaction-history?page=${this.pageNumber - 1}&pageSize=${this.itemsPerPage}${this.userForm.value.searchText ? "&userEmail=" + search : ""}${this.userForm.value.startdate ? "&fromDate=" + startdate : ""}${this.userForm.value.enddate ? "&toDate=" + enddate : ""}${this.userForm.value.userStatus ? "&status=" + this.userForm.value.userStatus : ""}${this.userForm.value.ramdomId ? "&randomId=" + ramdomId : ''}&order=desc&type=BUY_TOKEN&txnType=CLAIM`;
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      console.log('df', res);
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.coinData = res['data']['resultlist']
        this.totalRecords = res['data']['totalCount']
      }else{
        this.coinData = []

      }

    }, (err) => {
      this.coinData = []
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.toasterErr('Something Went Wrong');
      }
    })

  }

  // Api of search 
  search() {
    this.coinName = this.userForm.value.select;
    // let fromDate=Date.parse(this.userForm.value.startdate);
    let fromDate = Date.parse(this.userForm.value.startdate);
    let currentTime = new Date().getTime()
    console.log("hdh", fromDate);

    let toDate = Date.parse(this.userForm.value.enddate);
    console.log('jhhj', toDate + currentTime, currentTime);

    if (this.userForm.value.enddate && this.coinName && this.currTab === 'Withdraw') {
      this.url = "wallet/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&coinName=" + this.coinName + "&fromDate=" + fromDate + "&toDate=" + toDate + "&txnType=WITHDRAW"
    }

    else if (this.userForm.value.enddate && this.currTab === 'Withdraw') {
      this.url = "wallet/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&fromDate=" + fromDate + "&toDate=" + toDate + "&txnType=WITHDRAW";
    }

    else if (this.userForm.value.startdate && this.currTab === 'Withdraw') {
      this.url = "wallet/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&fromDate=" + fromDate + "&txnType=WITHDRAW";
    }

    else if (this.coinName && this.currTab === 'Withdraw') {
      this.url = "wallet/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&coinName=" + this.coinName + "&txnType=WITHDRAW";
    }

    else if (this.userForm.value.enddate && this.coinName && this.currTab === 'Deposite') {
      this.url = "wallet/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&coinName=" + this.coinName + "&fromDate=" + fromDate + "&toDate=" + toDate + "&txnType=DEPOSIT"
    }
    else if (this.userForm.value.enddate && this.currTab === 'Deposite') {
      this.url = "wallet/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&fromDate=" + fromDate + "&toDate=" + toDate + "&txnType=DEPOSIT";
    }

    else if (this.userForm.value.startdate && this.currTab === 'Deposite') {
      this.url = "wallet/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&fromDate=" + fromDate + "&txnType=DEPOSIT";
    }

    else if (this.coinName && this.currTab === 'Deposite') {
      this.url = "wallet/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&coinName=" + this.coinName + "&txnType=DEPOSIT";
    }





    this.service.get(this.url).subscribe((res: any) => {
      console.log('search', res);

      if (res['status'] == 200) {
        this.service.toasterSucc(res.message);
        // this.withdrawlData=res['data']['resultlist']
        this.coinData = res['data']['resultlist']
        this.totalRecords = res['data']['totalCount']
      }else{
        this.coinData = []

      }

    }, (err) => {
      this.coinData = []
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
    this.service.hideSpinner();
  }

  // reset data of search
  reset() {
    if (
      this.userForm.value.startdate ||
      this.userForm.value.enddate ||
      this.userForm.value.searchText ||
      this.userForm.value.ramdomId ||
      this.userForm.value.userStatus
    ) {
      this.userForm.reset({
        startdate: "",
        userStatus: "",
        enddate: "",
        searchText: "",
        country: "",
        ramdomId: ""
      });
      this.coinList();
    }
  }

  //export User
  async exportAsXLSX() {
    let temp = [];
    let startdate = Date.parse(this.userForm.value.startdate);
    let enddate = Date.parse(this.userForm.value.enddate);
    var search = this.userForm.value.searchText;
    let ramdomId = this.userForm.value.ramdomId;
    let url =  `wallet/get-all-transaction-history?page=${this.pageNumber - 1}&pageSize=${this.itemsPerPage}${this.userForm.value.searchText ? "&userEmail=" + search : ""}${this.userForm.value.startdate ? "&fromDate=" + startdate : ""}${this.userForm.value.enddate ? "&toDate=" + enddate : ""}${this.userForm.value.userStatus ? "&status=" + this.userForm.value.userStatus : ""}${this.userForm.value.ramdomId ? "&randomId=" + ramdomId : ''}&order=desc&type=BUY_TOKEN&txnType=CLAIM`;
    this.service.showSpinner();
    try {
      let res: any = await this.service.get(url).toPromise();

      this.service.hideSpinner();
      if (res.status == 200) {
        let dataArr = [];
        res.data.resultlist.forEach((element, ind) => {
          dataArr.push({
            "S no": ind + 1,
            "Username": element.userName ? element.userName : "N/A",
            "Email Id": element.userEmail ? element.userEmail : "N/A",
            "Random Id": element.randomId ? element.randomId : "N/A",
            "Claim Request": element.claimRequest ? element.claimRequest : "N/A",
            "Requested Token (FIERO)": element.remainingAmount ? element.remainingAmount : "N/A",
            "Date & Time": element.txnTime ? this.date.transform(element.txnTime, 'medium') : "N/A",
            "Status": element.status ? element.status : "N/A",
          });
        });

        this.service.exportAsExcelFile(dataArr, "Token Claim Transaction list");
      }
    } catch (error) {
      console.log(error)
      this.service.hideSpinner();
      this.service.toasterErr(error.error.message);
    }


  }



  // wallet balance
  // api of Trading
 
  
  pagination(page) {
    this.pageNumber = page;
      this.coinList();
  }
  tokenId: any
  approved(id) {
    this.tokenId = id
    $("#approveModal").modal("show");
  }
  approveKyc() {
    this.service.showSpinner();
    const url = `wallet/vesting-management/admin/claim-token?tokenRequest=CONFIRM&txnId=${this.tokenId}`
    this.service.put(url, {}).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        this.service.toasterSucc("Accepted successfully.");
        $("#approveModal").modal("hide");
        this.coinList();
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message);
      }
    },
      (error) => {
        this.service.hideSpinner();
      }
    );
  }
  toFixed(number, toLength: number) {
    return Number(number).toFixed(toLength)
  }
  // My Profile Functionality
  myProfile() {
    var url = 'account/my-account';
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userDetail = res['data']['role']
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Session Expired');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  userDetailData:any
  functionNew(){
    return this.service.toasterErr('This feature is only capable for admin!')
  }
  getClaimData(){
    let url = `wallet/vesting-management/admin/get-claim-data`
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userDetailData = res['data']
      } else {
        // this.service.toasterErr(res['message']);
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Session Expired');
      } else {
        // this.service.toasterErr('Something Went Wrong');
      }
    })
  }
}
