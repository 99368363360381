import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MainService } from "src/app/provider/main.service";
import { element } from "protractor";
import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";
declare var $: any
@Component({
  selector: "app-payment-list",
  templateUrl: "./payment-list.component.html",
  styleUrls: ["./payment-list.component.css"],
})
export class PaymentListComponent implements OnInit {
  kycForm: FormGroup;
  pageNumber: number = 1;
  itemPerPage: number = 10
  paymentData: any = [];
  userId: any;
  url: string;
  totalRecords: any;
  // today = new Date().toISOString().split("T")[0];
  today: any = new Date().toISOString().slice(0, 16);
  userForm: FormGroup
  isSearched: boolean = false;
  isAssending: boolean = true;
  permissionArr:any = {};

  constructor(
    public route: Router,
    public service: MainService,
    public transform: DatePipe
  ) {
    this.service.permission.subscribe((res) => {
      if (Object.keys(res).length) {
        this.permissionArr = res
        console.log(res);

      }
    })

   }

  ngOnInit(): void {
    this.userForm = new FormGroup({
      firstName: new FormControl(""),
      lastName: new FormControl(""),
      email: new FormControl(""),
      ramdomId: new FormControl(""),
    });
    this.kyc();
    // this.kycForm = new FormGroup({
    //   status: new FormControl("", Validators.required),
    //   // 'userName': new FormControl('', Validators.required),
    //   searchUsername: new FormControl(""),
    //   enddate: new FormControl("", Validators.required),
    //   startdate: new FormControl("", Validators.required),
    // });
  }




  // api of kyc listing
  kyc() {
    let randomId = this.userForm.value.ramdomId
    let email = this.userForm.value.email
    let firstName = this.userForm.value.firstName
    let lastName = this.userForm.value.lastName
    var url =
      "wallet/vesting-management/admin/get-payment-list?page=" +
      (this.pageNumber - 1) +
      "&pageSize=" + (this.itemPerPage) +`${randomId ? '&randomId='+randomId : ''}${email ? '&email='+email :''}${firstName ? '&firstName='+firstName :''}${lastName ? '&lastName='+lastName : ''}`;
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        console.log("kyc", res);
        if (res.status == 200) {
          this.paymentData = res.data.resultlist;
          this.totalRecords = res.data.totalCount
        }

        this.service.hideSpinner();
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.hideSpinner();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.hideSpinner();
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  search() {
    let startdate = Date.parse(this.kycForm.value.startdate);
    let enddate = Date.parse(this.kycForm.value.enddate);
    let trxnId = this.kycForm.value.status;
    let searchText = this.kycForm.value.searchUsername;
    let emailRegex = "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$";
    let isEmail = searchText.match(emailRegex);
    let url = `stripe/payment/get-all-payment-history-stripe?page=${this.pageNumber - 1}&pageSize=10${searchText ? (isEmail ? '&email=' + searchText : '&userName=' + searchText) : ''}${startdate ? '&fromDate=' + startdate : ''}${enddate ? '&toDate=' + enddate : ''}${trxnId ? '&txnId=' + trxnId : ''}`
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.service.hideSpinner();
          this.paymentData = res.data.RESULT_LIST || res.data.emailPaymentDetails || res.data.dateDetails || res.data.content || [];
          // this.paymentData = res.data.content;

          this.totalRecords = res.data.totalCount;
          this.totalRecords = res.data.totalElements;
        }
        else {
          // alert('lodu')
          this.paymentData = [];
          this.service.hideSpinner();
          this.totalRecords = 0
        }

      },

      (err) => {
        this.service.hideSpinner();
        // this.listing = [];

        // this.totalRecords = err.data.totalCount;
      }
    );
  }


  reset() {
    if (
      this.userForm.value.firstName ||
      this.userForm.value.lastName ||
      this.userForm.value.email ||
      this.userForm.value.ramdomId 
    ) {
      this.userForm.reset({
        firstName: "",
        lastName: "",
        email: "",
        ramdomId:""
      });
      this.kyc();
    }
  }
  // Api of search
  // search() {

  //   let kycStatus = this.kycForm.value.status;
  //   let search = this.kycForm.value.userName
  //   if (this.kycForm.value.userName && this.kycForm.value.status) {
  //     this.url = "account/admin/kyc-management/filter-kyc-users-list?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&kycStatus=" + kycStatus + "&search=" + search;
  //   }
  //   else if (this.kycForm.value.userName) {
  //     this.url = "account/admin/kyc-management/filter-kyc-users-list?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&search=" + search;
  //   }
  //   else if (this.kycForm.value.status) {
  //     this.url = "account/admin/kyc-management/filter-kyc-users-list?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&kycStatus=" + kycStatus;
  //   }
  //   this.service.get(this.url).subscribe((res: any) => {
  //     console.log('search', res);

  //     if (res['status'] == 200) {
  //       this.paymentData = res.data.list;
  //       this.service.toasterSucc('User found successfully')

  //     }

  //   }, (err) => {
  //     if (err['status'] == 401) {
  //       this.service.toasterErr('Unauthorized Access')
  //     }
  //     else {
  //       this.service.toasterErr('Something Went Wrong');
  //     }
  //   })

  // }

  // reset

  view(id) {
    console.log("gg", id);
    this.route.navigate(["/payment-view"], { queryParams: { id: id } });
  }

  copy(text) {
    navigator.clipboard.writeText(text);
    this.service.toasterSucc("Copy to clipboard");
  }

  exportAsXLSX() {
    var url =
      "wallet/vesting-management/admin/get-payment-list?page=0" + "&pageSize=" + (this.totalRecords);
    this.service.get(url).subscribe(
      (res: any) => {
        console.log("kyc", res);
        if (res.status == 200) {
          let dataArr = [];
          res.data.resultlist.forEach((element, ind) => {
            dataArr.push({
              "S.No": ind + 1,
              "First Name": element.firstName ? element.firstName : "N/A",
              "Last Name": element.lastName ? element.lastName : "N/A",
              "Received Amount": element.amountReceived ? element.amountReceived : "N/A",
              "Assets Amount": element.assetAmont ? element.assetAmont : "N/A",
              "GBP Amount": element.gbpAmount ? element.gbpAmount : "N/A",
              "IBI ID": element.ibiId ? element.ibiId : "N/A",
              "IBI Name": element.ibiName ? element.ibiName : "N/A",
              "Random Id": element.orderId ? element.orderId : "N/A",
              "Email": element.email ? element.email : "N/A",
              "Pay Address": element.payAddress ? element.payAddress : "N/A",
              "Pay Amount": element.payAmount ? element.payAmount : "N/A",
              "Payment Status": element.paymentStatus ? element.paymentStatus : "N/A",
              "Allocated Amount": element.toAllocateAmount ? element.toAllocateAmount : "N/A",
              "Created Date": element.createDate ? this.transform.transform(element.createDate, 'medium') : "N/A",
            });
          });
          console.log(dataArr);
          this.service.exportAsExcelFile(dataArr, "Payment List");
        }

        this.service.hideSpinner();
      }
    )


  }

  pagination(page) {
    // this.totalRecords = [];
    console.log("jh", page);
    this.pageNumber = page;
    console.log("jh", this.pageNumber);

    this.kyc();
  }
  sortAgent(key) {
    if (this.isAssending) {
      this.paymentData = this.paymentData.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.paymentData = this.paymentData.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }

  paymentId: any
  approved(id) {
    this.paymentId = id
    $("#approveModal").modal("show");
  }
  approveKyc() {
    this.service.showSpinner();
    const url = `wallet/vesting-management/admin/payment-status?paymentId=${this.paymentId}`
    this.service.put(url, {}).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        this.kyc();
        this.service.toasterSucc("Approved successfully.");
        $("#approveModal").modal("hide");
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message);
      }
    },
      (error) => {
        this.service.hideSpinner();
      }
    );
  }

  navigateToView(data) {
    localStorage.setItem('payment_Details', JSON.stringify(data))
    this.route.navigate(['/payment-view'])
  }

  shortAddress(address) {
    const shortedValue = `${address.slice(0, 6)}....${address.slice(address.length - 4)}`
    return shortedValue
  }
  copyToClipboard(item) {
    navigator.clipboard.writeText(item).then(
      () => {
        this.service.toasterSucc('Copied')
      },
      () => {
        this.service.toasterErr('Failed')
      }
    );
  }
}
