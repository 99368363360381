import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/provider/main.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-static-content-management',
  templateUrl: './static-content-management.component.html',
  styleUrls: ['./static-content-management.component.css']
})
export class StaticContentManagementComponent implements OnInit {
  staticData: any = []
  staticGermanData: any = [];
  staticSpanishData: any = [];
  staticContentData: any
  permissionArr : any = {}
  constructor(public service: MainService, public router: Router) {
    this.service.permission.subscribe((res) => {
      if (Object.keys(res).length) {
        this.permissionArr = res
        console.log(res);

      }
    })
   }

  ngOnInit() {
    this.getEnglishStaticData()

  }

  getName(e: string) {
    let str = e.replace(/_/g, ' ')
    return str
  }

  // english static data
  getEnglishStaticData() {
    this.service.showSpinner();
    this.service.get(`static/get-all-static-content-data`).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.status == 200) {
        this.staticData = res.data
      }
      // var arr = this.staticData;
      // console.log(arr.splice(0,arr.length-1));
      // this.staticContentData = arr.splice(0,arr.length-1)
      // console.log(this.staticContentData);

    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  // german static data
  getGermanStaticData() {
    this.service.showSpinner();
    this.service.get(`static/get-all-german-static-content-data`).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.status == 200) {
        console.log('ggf', res);
        this.staticGermanData = res.data
      }

    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  // spanish static data
  getSpanishStaticData() {
    this.service.showSpinner();
    this.service.get(`static/get-all-spanish-static-content-data`).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.status == 200) {
        console.log('ggf', res);
        this.staticSpanishData = res.data
      }

    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  // routing

  englishAbout(page) {
    console.log('hgg', page);
    page = String(page).toLowerCase()
    console.log(page);
    if (page.includes('about')) {
      this.router.navigate(['/About_Us'], { queryParams: { page } })
    }
    else if (page.includes('privacy')) {
      this.router.navigate(['/Privacy_Policy'], { queryParams: { page } })
    }

    else if (page.includes('terms')) {
      this.router.navigate(['/Terms_And_Conditions'], { queryParams: { page } })
    }
    else if (page.includes('risk')) {
      this.router.navigate(['/risk-management'], { queryParams: { page } })
    }
    else if (page.includes('aml')) {
      this.router.navigate(['/aml-service'], { queryParams: { page } })
    }
    else if (page.includes('term')) {
      this.router.navigate(['/terms-of-service'], { queryParams: { page } })
    }
  }

  // getName(name : string){
  //   return name.replace(/_/g," ");
  // }

}

