import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $: any
@Component({
  selector: 'app-ticket-management',
  templateUrl: './ticket-management.component.html',
  styleUrls: ['./ticket-management.component.css']
})
export class TicketManagementComponent implements OnInit {
  userForm: FormGroup;
  ticketList: any = [];
  page: number = 1;
  limit: number = 10;
  itemsPerPage: number = 10;

  totalRecords: any;
  pageNumber:number=1;
  arr : any = ['RESOLVED','INPROGRESS','CLOSED','CANCELLED']
  updatestatus:string;
  isAssending: boolean = true;
  today: any = new Date().toISOString().slice(0, 16)
  permissionArr : any = {}
  constructor(
    private router: Router, public service: MainService
  ) {
    this.service.permission.subscribe((res) => {
      if (Object.keys(res).length) {
        this.permissionArr = res
        console.log(res);

      }
    })
  }

  ngOnInit() {
    this.userForm = new FormGroup({
      'startdate': new FormControl('', Validators.required),
      'enddate': new FormControl('', Validators.required),
      'searchText': new FormControl(''),
      'status': new FormControl(''),

    })
    this.getTicketList(this.page);
  }

  getTicketList(page) {  
    this.page = page
    let request = {
      'page': this.pageNumber-1,
      'pageSize': this.limit
    }
    this.service.showSpinner();
    this.service.post(`static/search-and-filter-ticket-list`, request).subscribe((res: any) => {
      this.ticketList = res.data.list
      this.totalRecords = res.data.size;
    })
    this.service.hideSpinner()
  }
  filterTicketList() {
    let startdate = Date.parse(this.userForm.value.startdate)
    let enddate = Date.parse(this.userForm.value.enddate)
    let search = this.userForm.controls.searchText.value;
    let status = this.userForm.controls.status.value;
    if(startdate && enddate && search && status){
    var request = {
      'fromDate': startdate,
      'search': search,
      'ticketStatus':status ,
      'toDate': enddate,
      'page': this.pageNumber -1,
      'pageSize': this.limit
    }
  }
    
  else if(startdate && enddate){
    var request1={
      'fromDate': startdate,
      'toDate': enddate,
      'page': this.pageNumber-1,
      'pageSize': this.limit
      
    }
  }
  else if(search){
   var request2={
    'search': search,
    'page': this.pageNumber-1,
      'pageSize': this.limit
   }
   
  }

  else if(status){
   var request3={
    'ticketStatus':this.userForm.controls.status.value,
    'page': this.pageNumber-1,
      'pageSize': this.limit
   }
   
   
  }
  console.log('j', request, request1,request2,request3);
  this.service.showSpinner()
    this.service.post(`static/search-and-filter-ticket-list`, request || request1 || request2 || request3).subscribe((res: any) => {
      this.ticketList = res.data.list
      console.log('hh', this.ticketList);
      
      this.totalRecords = res.data.size;
    })
    this.service.hideSpinner()
    console.log('hitted');
    console.log(status);
  }
  isSearched: boolean = false;

  pagination(page) {
    // this.totalRecords = [];
    console.log("jh", page);
    this.pageNumber = page;
    console.log("jh", this.pageNumber);
    if (this.isSearched) {

      
      this.filterTicketList();
    } else {
      this.service.hideSpinner();
      this.getTicketList(page);
    }
  }
  updateTicketStatus(e ,id:number){
  let status=e.target.value
   id =id
   let data={}
    this.service.post(`static/change-ticket-status?ticketId=${id}&ticketStatus=${status}`,data).subscribe((res:any)=>{
      this.service.showSpinner() 
if(res.status=200){
  this.service.hideSpinner() 
this.service.toasterSucc(res.message)
}      
    })
  }

  // reset
  // reset(){
  //   this.userForm.reset()
  //   this.getTicketList(this.page)
  // }
  reset() {
    if (
      this.userForm.value.startdate ||
      this.userForm.value.enddate ||
      this.userForm.value.searchText ||
      this.userForm.value.status 
    ) {
      this.userForm.reset({
        startdate: "",
        // userStatus: "",
        enddate: "",
        searchText: "",
        status: "",
      });
      this.getTicketList(this.page);
    }
  }
  replyTicket() {
    this.router.navigate(['/reply-ticket'])
  }
  viewTicket() {
    this.router.navigate(['/view-ticket'])
  }

  //export User
  exportAsXLSX() {
    let dataArr = [];
    this.ticketList.forEach((element, ind) => {

      dataArr.push({
        "S no": ind + 1,
        "Ticket ID": element.ticketId ? element.ticketId : '',
        "Message" : element?.description ? element?.description : '',
        "Email": element.email ? element.email : 'N/A',
        "Customer Name": element.firstName + '' + element.lastName ? element.firstName +" " + (element.lastName? element.lastName : ''): '',
        "Date": element.createdAt ? element.createdAt.slice(0, 10) : 'N/A',
        "Status": element.ticketStatus,
      })
    })

    this.service.exportAsExcelFile(dataArr, 'Queries Management');
    console.log(dataArr)
  }
  // sorting
  sortAgent(key) {
    if (this.isAssending) {
      this.ticketList = this.ticketList.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.ticketList = this.ticketList.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
}

