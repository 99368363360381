<div class="wrapper" *ngIf="isLoggedIn">
  <!-- top header -->
  <header class="header">
    <nav class="navbar clearfix">
      <div class="logo-box">
        <!-- <a class="navbar-brand logo" [routerLink]="['/dashboard']" routerLinkActive="router-link-active">
                    <img id="mysidebar" src="assets/img/logo-blockchain.png" alt="logo" class="lg-logo">
                </a> -->
        <a
          class="navbar-brand logo"
          id="logo"
          *ngIf="showLogo == false"
          [routerLink]="['/dashboard']"
          routerLinkActive="router-link-active"
        >
          <img
            style="font-size: 8px; max-height: 70px; max-width: 100px"
            src="assets/img/fires.png"
            alt="ICON"
            class="lg-logo"
          />
        </a>
        <a
          class="navbar-brand logo"
          id="logo1"
          *ngIf="showLogo == true"
          [routerLink]="['/dashboard']"
          routerLinkActive="router-link-active"
        >
          <img
            style="
              font-size: 8px;
              max-width: 70px;
              max-width: 100%;
              height: 50px;
              width: 50px;
            "
            src="assets/img/xsm-logo.png"
            alt="ICON"
            class="lg-logo side_menu_icon_head"
          />
        </a>
      </div>
      <div class="header-right-part">
        <button class="btn btn-toggle" (click)="head()" type="button">
          <i class="fas fa-bars" aria-hidden="true"></i>
        </button>
        <!-- <button class="btn btn-outline-secondary btn-mobsearch" type="button">
                    <i class="fas fa-search" aria-hidden="true"></i></button> -->
      </div>
    </nav>
  </header>

  <!-- end top header -->

  <!--------------------------------------------------------------- ADMIN -------------------------------------------------------------------------->
  <aside *ngIf="!flag" class="sidebar" aria-labelledby="sidebar for admin">
    <div class="sidebar-scroller">
      <div id="accordion" class="sidebar-menu" style="color: #000">
        <div class="sidebarMenuBox">
          <div
            *ngIf="!showLogo"
            class="adminprofile"
            [routerLink]="['/my-profile']"
          >
            <a
              class="navbar-brand logo"
              style="width: 100%; display: inline-flex; justify-content: center"
            >
              <span class="sidebar-profile-image-sec">
                <img
                  class="lg-logo"
                  [src]="userDetail?.imageUrl || 'assets/img/profile-img.jpg'"
                  alt="image"
                  style="
                    cursor: pointer;
                    height: 85px;
                    width: 85px;
                    border-radius: 50%;
                    background: #161616;
                    padding: 9px;
                    border-radius: 50%;
                    margin-top: 14px;
                    border: 2px dotted #ffc500;
                  "
                />
              </span>
            </a>
            <div
              id="admin"
              class="profile-data-section"
              style="text-align: center; color: #fff"
            >
              <label> Welcome</label>
              <p style="cursor: pointer; color: #fff">
                {{ userDetail?.firstName }}
              </p>
            </div>
          </div>
          <div class="menu-box" [ngClass]="{ active: currUrl == 'dashboard' }">
            <div class="card-header clearfix" id="headingOne">
              <a
                class="side_menu"
                [routerLink]="['/dashboard']"
                routerLinkActive="router-link-active"
              >
                <span class="side_menu_icon">
                  <img
                    src="assets/img/dashboard-icon.png"
                    alt="icon"
                    style="margin-bottom: 7px"
                  />
                </span>
                <span class="side_page_name">Dashboard</span>
              </a>
            </div>
          </div>
          <div
            class="menu-box"
            [ngClass]="{
              active:
                currUrl == 'user-management' ||
                currUrl == 'user-details' ||
                currUrl == 'wallet-details'
            }"
          >
            <div class="card-header clearfix" id="headingTwo">
              <a
                class="side_menu"
                [routerLink]="['/user-management']"
                routerLinkActive="router-link-active"
              >
                <span class="side_menu_icon">
                  <img src="assets/img/user-manage.png" alt="icon" />
                </span>
                <span class="side_page_name">User Management</span>
              </a>
            </div>
          </div>

          <!-- <div class="menu-box"
                        [ngClass]="{'active':currUrl == 'user-management-admin' || currUrl == 'admin-detail' || currUrl == 'add-administator'}">
                        <div class="card-header clearfix" id="headingThree">
                            <a class="side_menu" [routerLink]="['/user-management-admin']"
                                routerLinkActive="router-link-active">
                                <span class="side_menu_icon">
                                    <img src="assets/img/user.png" alt="icon" />
                                </span>
                                <span class="side_page_name">Sub Admin Management</span>
                            </a>
                        </div>
                    </div> -->

          <div
            class="menu-box"
            [ngClass]="{
              active: currUrl == 'icoSalesListing' || currUrl == 'addICO'
            }"
          >
            <div class="card-header clearfix" id="headingThree">
              <a
                class="side_menu"
                [routerLink]="['/icoSalesListing']"
                routerLinkActive="router-link-active"
              >
                <span class="side_menu_icon">
                  <img src="assets/img/fees (1).png" alt="icon" />
                </span>
                <span class="side_page_name">Sale Tranches Mangement</span>
              </a>
            </div>
          </div>

          <div
            class="menu-box"
            [ngClass]="{ active: currUrl == 'transactionList' }"
          >
            <div class="card-header clearfix" id="headingThree">
              <a
                class="side_menu"
                [routerLink]="['/transactionList']"
                routerLinkActive="router-link-active"
              >
                <span class="side_menu_icon">
                  <img src="assets/img/stable (1).png" alt="icon" />
                </span>
                <span class="side_page_name">Transacation Management</span>
              </a>
            </div>
          </div>

          <!-- <div class="menu-box" [ngClass]="{'active':currUrl == 'admin-wallet'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/admin-wallet']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/stable (1).png" alt="icon" />
                            </span>
                            <span class="side_page_name">Wallet Management</span>
                        </a>
                    </div>
                </div> -->
          <div
            class="menu-box"
            [ngClass]="{ active: currUrl == 'hot-wallet-management' }"
          >
            <div class="card-header clearfix" id="headingThree">
              <a
                class="side_menu"
                [routerLink]="['/hot-wallet-management']"
                routerLinkActive="router-link-active"
              >
                <span class="side_menu_icon">
                  <img src="assets/img/stable (1).png" alt="icon" />
                </span>
                <span class="side_page_name">Hot Cold Wallet Management</span>
              </a>
            </div>
          </div>
          <div
            class="menu-box"
            [ngClass]="{
              active:
                currUrl == 'vesting-plan-list' ||
                currUrl == 'staking-transaction-list' ||
                currUrl == 'vesting-plan-add' ||
                currUrl == 'vesting-plan-edit' ||
                currUrl == 'vesting-plan-view'
            }"
          >
            <div class="card-header clearfix" id="headingThree">
              <a
                class="side_menu"
                [routerLink]="['/vesting-plan-list']"
                routerLinkActive="router-link-active"
              >
                <span class="side_menu_icon">
                  <img src="assets/img/fees (1).png" alt="icon" />
                </span>
                <span class="side_page_name">Staking Management</span>
              </a>
            </div>
          </div>
          <div
            class="menu-box"
            [ngClass]="{
              active: currUrl == 'kyc-detail' || currUrl == 'kyc-update'
            }"
          >
            <div class="card-header clearfix" id="headingThree">
              <a
                class="side_menu"
                [routerLink]="['/kyc-update']"
                routerLinkActive="router-link-active"
              >
                <span class="side_menu_icon">
                  <img src="assets/img/user.png" alt="icon" />
                </span>
                <span class="side_page_name">KYC Management</span>
              </a>
            </div>
          </div>

          <div
            class="menu-box"
            [ngClass]="{
              active:
                currUrl == 'ticket-management' ||
                currUrl == 'view-ticket' ||
                currUrl == 'reply-ticket'
            }"
          >
            <div class="card-header clearfix" id="headingThree">
              <a
                class="side_menu"
                [routerLink]="['/ticket-management']"
                routerLinkActive="router-link-active"
              >
                <span class="side_menu_icon">
                  <img src="assets/img/fees (1).png" alt="icon" />
                </span>
                <span class="side_page_name">Queries Management</span>
              </a>
            </div>
          </div>

          <div
            class="menu-box"
            [ngClass]="{
              active:
                currUrl == 'static-content-management' ||
                currUrl == 'add-static' ||
                currUrl == 'about-us' ||
                currUrl == 'privacy-policy' ||
                currUrl == 'terms-and-conditions'
            }"
          >
            <div class="card-header clearfix" id="headingThree">
              <a
                class="side_menu"
                [routerLink]="['/static-content-management']"
                routerLinkActive="router-link-active"
              >
                <span class="side_menu_icon">
                  <img src="assets/img/static-content.png" alt="icon" />
                </span>
                <span class="side_page_name">Static Content Management</span>
              </a>
            </div>
          </div>
          <!-- <div class="menu-box"
                        [ngClass]="{'active':currUrl == 'fieres-tutorial-list' || currUrl == 'fieres-tutorial-add' || currUrl == 'fieres-tutorial-edit' || currUrl == 'fieres-tutorial-view'}">
                        <div class="card-header clearfix" id="headingThree">
                            <a class="side_menu" [routerLink]="['/fieres-tutorial-list']"
                                routerLinkActive="router-link-active">
                                <span class="side_menu_icon">
                                    <img src="assets/img/static-content.png" alt="icon" />
                                </span>
                                <span class="side_page_name">Fieres Academy Management</span>
                            </a>
                        </div>
                    </div> -->

          <!-- <div class="menu-box" [ngClass]="{'active':currUrl == 'manage-fee' || currUrl == 'standard-trading-fee' || currUrl == 'min-trading-fee' || 
                currUrl == 'min-withdrawl-amount' || currUrl == 'update-withdrawl-amount'}">
                        <div class="card-header clearfix" id="headingThree">
                            <a class="side_menu" [routerLink]="['/manage-fee']" routerLinkActive="router-link-active">
                                <span class="side_menu_icon">
                                    <img src="assets/img/fees (1).png" alt="icon" />
                                </span>
                                <span class="side_page_name">Token Management</span>
                            </a>
                        </div>
                    </div> -->
          <!-- <div class="menu-box" [ngClass]="{'active':currUrl == 'vesting-plan-list' || currUrl == 'vesting-plan-add' || currUrl == 'vesting-plan-edit' || currUrl == 'vesting-plan-view'}">
                        <div class="card-header clearfix" id="headingThree">
                            <a class="side_menu" [routerLink]="['/vesting-plan-list']" routerLinkActive="router-link-active">
                                <span class="side_menu_icon">
                                    <img src="assets/img/fees (1).png" alt="icon" />
                                </span>
                                <span class="side_page_name">Vesting Management</span>
                            </a>
                        </div>
                    </div> -->
          <!-- <div class="menu-box" [ngClass]="{'active':currUrl == 'claim-token'}">
                        <div class="card-header clearfix" id="headingThree">
                            <a class="side_menu" [routerLink]="['/claim-token']" routerLinkActive="router-link-active">
                                <span class="side_menu_icon">
                                    <img src="assets/img/fees (1).png" alt="icon" />
                                </span>
                                <span class="side_page_name">Claim Token Management</span>
                            </a>
                        </div>
                    </div> -->
          <!-- <div class="menu-box" [ngClass]="{'active':currUrl == 'payment-list' || currUrl == 'payment-view'}">
                        <div class="card-header clearfix" id="headingThree">
                            <a class="side_menu" [routerLink]="['/payment-list']" routerLinkActive="router-link-active">
                                <span class="side_menu_icon">
                                    <img src="assets/img/fees (1).png" alt="icon" />
                                </span>
                                <span class="side_page_name">Payment Management</span>
                            </a>
                        </div>
                    </div> -->

          <div
            class="menu-box"
            [ngClass]="{
              active:
                currUrl == 'faq-management' ||
                currUrl == 'add-faq' ||
                currUrl == 'edit-faq' ||
                currUrl == 'terms-and-conditions'
            }"
          >
            <div class="card-header clearfix" id="headingThree">
              <a
                class="side_menu"
                [routerLink]="['/faq-management']"
                routerLinkActive="router-link-active"
              >
                <span class="side_menu_icon">
                  <img src="assets/img/static-content.png" alt="icon" />
                </span>
                <span class="side_page_name">FAQ Management</span>
              </a>
            </div>
          </div>
          <div
            class="menu-box"
            [ngClass]="{
              active:
                currUrl == 'whitepaper-list' ||
                currUrl == 'whitepaper-add' ||
                currUrl == 'whitepaper-view' ||
                currUrl == 'whitepaper-edit'
            }"
          >
            <div class="card-header clearfix" id="headingThree">
              <a
                class="side_menu"
                [routerLink]="['/whitepaper-list']"
                routerLinkActive="router-link-active"
              >
                <span class="side_menu_icon">
                  <img src="assets/img/static-content.png" alt="icon" />
                </span>
                <span class="side_page_name">Whitepaper Management</span>
              </a>
            </div>
          </div>
          <div
            class="menu-box"
            [ngClass]="{
              active:
                currUrl == 'token-coin-add' || currUrl == 'token-coin-list'
            }"
          >
            <div class="card-header clearfix" id="headingThree">
              <a
                class="side_menu"
                [routerLink]="['/token-coin-list']"
                routerLinkActive="router-link-active"
              >
                <span class="side_menu_icon">
                  <img src="assets/img/static-content.png" alt="icon" />
                </span>
                <span class="side_page_name">Coin List Management</span>
              </a>
            </div>
          </div>
          <!-- <div class="menu-box" [ngClass]="{'active':currUrl == 'ip-whitelist'}">
                        <div class="card-header clearfix" id="headingThree">
                            <a class="side_menu" [routerLink]="['/ip-whitelist']" routerLinkActive="router-link-active">
                                <span class="side_menu_icon">
                                    <img src="assets/img/static-content.png" alt="icon" />
                                </span>
                                <span class="side_page_name">Ip White List</span>
                            </a>
                        </div>
                    </div> -->
          <div
            class="menu-box"
            [ngClass]="{
              active:
                currUrl == 'setting' ||
                currUrl == 'my-profile' ||
                currUrl == 'edit-profile' ||
                currUrl == 'taker-maker-fee' ||
                currUrl == 'view-limit' ||
                currUrl == 'withdrawl-fee' ||
                currUrl == 'withdrawl-limit' ||
                currUrl == 'change-password'
            }"
          >
            <div class="card-header clearfix" id="headingThree">
              <a
                class="side_menu"
                [routerLink]="['/my-profile']"
                routerLinkActive="router-link-active"
              >
                <span class="side_menu_icon">
                  <img src="assets/img/setting-icon.png" alt="icon" />
                </span>
                <span class="side_page_name">Profile Management</span>
              </a>
            </div>
          </div>

          <!-- <div class="menu-box" [ngClass]="{'active':currUrl == 'sub-admin-activity'}">
                        <div class="card-header clearfix" id="headingThree">
                            <a class="side_menu" [routerLink]="['/sub-admin-activity']"
                                routerLinkActive="router-link-active">
                                <span class="side_menu_icon">
                                    <img src="assets/img/setting-icon.png" alt="icon" />
                                </span>
                                <span class="side_page_name">Sub Admin Activity</span>
                            </a>
                        </div>
                    </div> -->

          <div class="menu-box">
            <div
              class="card-header clearfix"
              style="position: absolute; bottom: -58px"
            >
              <a class="side_menu" href="#signout_modal" data-toggle="modal">
                <span class="side_menu_icon">
                  <img src="assets/img/logout.png" alt="icon" />
                </span>
                <span class="side_page_name">Logout</span>
              </a>
            </div>
          </div>
          <!-- <div class="menu-box" [ngClass]="{'active':currUrl == 'all-user-trader' }">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/all-user-trader']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/stable (1).png" alt="icon" />
                            </span>
                            <span class="side_page_name">All User Trades</span>
                        </a>
                    </div>
                </div> -->

          <!-- <div class="menu-box" [ngClass]="{'active':currUrl == 'trx-manage'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/trx-manage']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/stable (1).png" alt="icon" />
                            </span>
                            <span class="side_page_name">TRX Management</span>
                        </a>
                    </div>
                </div>
             
                <div class="menu-box"
                    [ngClass]="{'active':currUrl == 'token-management' || currUrl == 'add-token' || currUrl == 'edit-token' || currUrl == 'view-token'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/token-management']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/user.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Token Management</span>
                        </a>
                    </div>
                </div>
                
               
              

                <div class="menu-box" [ngClass]="{'active':currUrl == 'payment-list' || currUrl == 'payment-view'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/payment-list']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/fees (1).png" alt="icon" />
                            </span>
                            <span class="side_page_name">Payment Management</span>
                        </a>
                    </div>
                </div> -->
          <!-- <div class="menu-box"
                    [ngClass]="{'active':currUrl == 'news-letter-list' || currUrl == 'news-letter-add' || currUrl=='news-letter-edit' || currUrl=='news-letter-view'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/news-letter-list']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/stable (1).png" alt="icon" />
                            </span>
                            <span class="side_page_name">News Management</span>
                        </a>
                    </div>
                </div> -->

          <!-- <div class="menu-box"
                    [ngClass]="{'active':currUrl == 'fiat' || currUrl == 'send-money' || currUrl == 'privacy-policy' ||  currUrl == 'terms-and-conditions'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/fiat']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/static-content.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Fiat Management</span>
                        </a>
                    </div>
                </div> -->

          <!-- <div class="menu-box" [ngClass]="{'active':currUrl == 'wallet-types'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/wallet-types']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/static-content.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Fiat Wallet Types</span>
                        </a>
                    </div>
                </div> -->

          <!-- <div class="menu-box"
                    [ngClass]="{'active':currUrl == 'whitepaper-list' || currUrl == 'whitepaper-add' || currUrl == 'edit-faq' ||  currUrl == 'terms-and-conditions'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/whitepaper-list']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/static-content.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Whitepaper Management</span>
                        </a>
                    </div>
                </div>
                <div class="menu-box"
                    [ngClass]="{'active':currUrl == 'community-list' || currUrl == 'community-add' || currUrl == 'community-edit'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/community-list']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/static-content.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Community Management</span>
                        </a>
                    </div>
                </div> -->
          <!-- 
                <div class="menu-box"
                    [ngClass]="{'active':currUrl == 'setting' || currUrl == 'my-profile' || currUrl == 'edit-profile' || currUrl == 'taker-maker-fee' || currUrl == 'view-limit' || currUrl == 'withdrawl-fee' || currUrl == 'withdrawl-limit' || currUrl == 'change-password'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/setting']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/setting-icon.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Settings</span>
                        </a>
                    </div>
                </div> -->
        </div>
      </div>
    </div>
  </aside>

  <!--------------------------------------------------------------- SUB ADMIN -------------------------------------------------------------------------->
  <aside *ngIf="flag" class="sidebar" aria-labelledby="sidebar for admin">
    <div class="sidebar-scroller">
      <div id="accordion" class="sidebar-menu" style="color: #000">
        <div class="sidebarMenuBox">
          <div
            *ngIf="!showLogo"
            class="adminprofile"
            [routerLink]="['/my-profile']"
          >
            <a
              class="navbar-brand logo"
              style="width: 100%; display: inline-flex; justify-content: center"
            >
              <span class="sidebar-profile-image-sec">
                <img
                  class="lg-logo"
                  [src]="userDetail?.imageUrl || 'assets/img/profile-img.jpg'"
                  alt="image"
                  style="
                    cursor: pointer;
                    height: 85px;
                    width: 85px;
                    border-radius: 50%;
                    background: #161616;
                    padding: 9px;
                    border-radius: 50%;
                    margin-top: 14px;
                    border: 2px dotted #ffc500;
                  "
                />
              </span>
            </a>
            <div
              id="admin"
              class="profile-data-section"
              style="text-align: center; color: #fff"
            >
              <label> Welcome</label>
              <p style="cursor: pointer; color: #fff">
                {{ userDetail?.firstName }}
              </p>
            </div>
          </div>
          <div class="menu-box" [ngClass]="{ active: currUrl == 'dashboard' }">
            <div class="card-header clearfix" id="headingOne">
              <a
                class="side_menu"
                [routerLink]="['/dashboard']"
                routerLinkActive="router-link-active"
              >
                <span class="side_menu_icon">
                  <img
                    src="assets/img/dashboard-icon.png"
                    alt="icon"
                    style="margin-bottom: 7px"
                  />
                </span>
                <span class="side_page_name">Dashboard</span>
              </a>
            </div>
          </div>
          <div
            class="menu-box"
            *ngIf="permissionArr?.USER_MANAGEMENT?.length"
            [ngClass]="{
              active:
                currUrl == 'user-management' ||
                currUrl == 'user-details' ||
                currUrl == 'wallet-details'
            }"
          >
            <div class="card-header clearfix" id="headingTwo">
              <a
                class="side_menu"
                [routerLink]="['/user-management']"
                routerLinkActive="router-link-active"
              >
                <span class="side_menu_icon">
                  <img src="assets/img/user-manage.png" alt="icon" />
                </span>
                <span class="side_page_name">User Management</span>
              </a>
            </div>
          </div>

          <!-- <div class="menu-box" *ngIf="permissionArr?.SUBADMIN_MANAGEMENT?.length"
                        [ngClass]="{'active':currUrl == 'user-management-admin' || currUrl == 'admin-detail' || currUrl == 'add-administator'}">
                        <div class="card-header clearfix" id="headingThree">
                            <a class="side_menu" [routerLink]="['/user-management-admin']"
                                routerLinkActive="router-link-active">
                                <span class="side_menu_icon">
                                    <img src="assets/img/user.png" alt="icon" />
                                </span>
                                <span class="side_page_name">Sub Admin Management </span>
                            </a>
                        </div>
                    </div> -->

          <div
            class="menu-box"
            *ngIf="permissionArr?.TOKEN_TRANS_MANAGEMENT?.length"
            [ngClass]="{
              active: currUrl == 'icoSalesListing' || currUrl == 'addICO'
            }"
          >
            <div class="card-header clearfix" id="headingThree">
              <a
                class="side_menu"
                [routerLink]="['/icoSalesListing']"
                routerLinkActive="router-link-active"
              >
                <span class="side_menu_icon">
                  <img src="assets/img/fees (1).png" alt="icon" />
                </span>
                <span class="side_page_name">Sale Tranches Mangement</span>
              </a>
            </div>
          </div>

          <div
            class="menu-box"
            *ngIf="permissionArr?.TRANSACTION_MANAGEMENT?.length"
            [ngClass]="{ active: currUrl == 'transactionList' }"
          >
            <div class="card-header clearfix" id="headingThree">
              <a
                class="side_menu"
                [routerLink]="['/transactionList']"
                routerLinkActive="router-link-active"
              >
                <span class="side_menu_icon">
                  <img src="assets/img/stable (1).png" alt="icon" />
                </span>
                <span class="side_page_name">Transacation Management</span>
              </a>
            </div>
          </div>

          <!-- <div class="menu-box" [ngClass]="{'active':currUrl == 'admin-wallet'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/admin-wallet']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/stable (1).png" alt="icon" />
                            </span>
                            <span class="side_page_name">Wallet Management</span>
                        </a>
                    </div>
                </div> -->

          <div
            class="menu-box"
            *ngIf="permissionArr?.KYC_MANAGEMENT?.length"
            [ngClass]="{
              active: currUrl == 'kyc-detail' || currUrl == 'kyc-update'
            }"
          >
            <div class="card-header clearfix" id="headingThree">
              <a
                class="side_menu"
                [routerLink]="['/kyc-update']"
                routerLinkActive="router-link-active"
              >
                <span class="side_menu_icon">
                  <img src="assets/img/user.png" alt="icon" />
                </span>
                <span class="side_page_name">KYC Management</span>
              </a>
            </div>
          </div>

          <div
            class="menu-box"
            *ngIf="permissionArr?.QUERY_MANAGEMENT?.length"
            [ngClass]="{
              active:
                currUrl == 'ticket-management' ||
                currUrl == 'view-ticket' ||
                currUrl == 'reply-ticket'
            }"
          >
            <div class="card-header clearfix" id="headingThree">
              <a
                class="side_menu"
                [routerLink]="['/ticket-management']"
                routerLinkActive="router-link-active"
              >
                <span class="side_menu_icon">
                  <img src="assets/img/fees (1).png" alt="icon" />
                </span>
                <span class="side_page_name">Queries Management</span>
              </a>
            </div>
          </div>

          <div
            class="menu-box"
            *ngIf="permissionArr?.STATIC_CONTENT_MANGEMENT?.length"
            [ngClass]="{
              active:
                currUrl == 'static-content-management' ||
                currUrl == 'add-static' ||
                currUrl == 'about-us' ||
                currUrl == 'privacy-policy' ||
                currUrl == 'terms-and-conditions'
            }"
          >
            <div class="card-header clearfix" id="headingThree">
              <a
                class="side_menu"
                [routerLink]="['/static-content-management']"
                routerLinkActive="router-link-active"
              >
                <span class="side_menu_icon">
                  <img src="assets/img/static-content.png" alt="icon" />
                </span>
                <span class="side_page_name">Static Content Management</span>
              </a>
            </div>
          </div>
          <!-- <div class="menu-box" *ngIf="permissionArr?.FIERES_TUTORIAL?.length"
                        [ngClass]="{'active':currUrl == 'fieres-tutorial-list' || currUrl == 'fieres-tutorial-add' || currUrl == 'fieres-tutorial-edit' || currUrl == 'fieres-tutorial-view'}">
                        <div class="card-header clearfix" id="headingThree">
                            <a class="side_menu" [routerLink]="['/fieres-tutorial-list']"
                                routerLinkActive="router-link-active">
                                <span class="side_menu_icon">
                                    <img src="assets/img/static-content.png" alt="icon" />
                                </span>
                                <span class="side_page_name">Fieres Academy Management</span>
                            </a>
                        </div>
                    </div> -->

          <!-- <div class="menu-box" *ngIf="permissionArr?.TOKEN_MANAGEMENT?.length" [ngClass]="{'active':currUrl == 'manage-fee' || currUrl == 'standard-trading-fee' || currUrl == 'min-trading-fee' || 
                currUrl == 'min-withdrawl-amount' || currUrl == 'update-withdrawl-amount'}">
                        <div class="card-header clearfix" id="headingThree">
                            <a class="side_menu" [routerLink]="['/manage-fee']" routerLinkActive="router-link-active">
                                <span class="side_menu_icon">
                                    <img src="assets/img/fees (1).png" alt="icon" />
                                </span>
                                <span class="side_page_name">Token Management</span>
                            </a>
                        </div>
                    </div> -->
          <!-- <div class="menu-box" *ngIf="permissionArr?.VESTING_MANAGEMENT?.length" [ngClass]="{'active':currUrl == 'vesting-plan-list' || currUrl == 'vesting-plan-add' || currUrl == 'vesting-plan-edit' || currUrl == 'vesting-plan-view'}">
                        <div class="card-header clearfix" id="headingThree">
                            <a class="side_menu" [routerLink]="['/vesting-plan-list']" routerLinkActive="router-link-active">
                                <span class="side_menu_icon">
                                    <img src="assets/img/fees (1).png" alt="icon" />
                                </span>
                                <span class="side_page_name">Vesting Management</span>
                            </a>
                        </div>
                    </div> -->
          <!-- <div class="menu-box"  [ngClass]="{'active':currUrl == 'claim-token'}"> -->
          <!-- <div class="menu-box" *ngIf="permissionArr?.CLAIM_TOKEN_MANAGEMENT?.length"
                        [ngClass]="{'active':currUrl == 'claim-token'}">
                        <div class="card-header clearfix" id="headingThree">
                            <a class="side_menu" [routerLink]="['/claim-token']" routerLinkActive="router-link-active">
                                <span class="side_menu_icon">
                                    <img src="assets/img/fees (1).png" alt="icon" />
                                </span>
                                <span class="side_page_name">Claim Token Management</span>
                            </a>
                        </div>
                    </div> -->

          <!-- <div class="menu-box" *ngIf="permissionArr?.PAYMENT_MANAGEMENT?.length"
                        [ngClass]="{'active':currUrl == 'payment-list' || currUrl == 'payment-view'}">
                        <div class="card-header clearfix" id="headingThree">
                            <a class="side_menu" [routerLink]="['/payment-list']" routerLinkActive="router-link-active">
                                <span class="side_menu_icon">
                                    <img src="assets/img/fees (1).png" alt="icon" />
                                </span>
                                <span class="side_page_name">Payment Management</span>
                            </a>
                        </div>
                    </div> -->
          <!-- <div class="menu-box" *ngIf="permissionArr?.FAQ_MANAGEMENT?.length"
                        [ngClass]="{'active':currUrl == 'faq-management' || currUrl == 'add-faq' || currUrl == 'edit-faq' ||  currUrl == 'terms-and-conditions'}">
                        <div class="card-header clearfix" id="headingThree">
                            <a class="side_menu" [routerLink]="['/faq-management']"
                                routerLinkActive="router-link-active">
                                <span class="side_menu_icon">
                                    <img src="assets/img/static-content.png" alt="icon" />
                                </span>
                                <span class="side_page_name">FAQ Management</span>
                            </a>
                        </div>
                    </div> -->

          <div
            class="menu-box"
            [ngClass]="{
              active:
                currUrl == 'setting' ||
                currUrl == 'my-profile' ||
                currUrl == 'edit-profile' ||
                currUrl == 'taker-maker-fee' ||
                currUrl == 'view-limit' ||
                currUrl == 'withdrawl-fee' ||
                currUrl == 'withdrawl-limit' ||
                currUrl == 'change-password'
            }"
          >
            <div class="card-header clearfix" id="headingThree">
              <a
                class="side_menu"
                [routerLink]="['/my-profile']"
                routerLinkActive="router-link-active"
              >
                <span class="side_menu_icon">
                  <img src="assets/img/setting-icon.png" alt="icon" />
                </span>
                <span class="side_page_name">Profile Management</span>
              </a>
            </div>
          </div>

          <div class="menu-box">
            <div
              class="card-header clearfix"
              style="position: absolute; bottom: -58px"
            >
              <a class="side_menu" href="#signout_modal" data-toggle="modal">
                <span class="side_menu_icon">
                  <img src="assets/img/logout.png" alt="icon" />
                </span>
                <span class="side_page_name">Logout</span>
              </a>
            </div>
          </div>
          <!-- <div class="menu-box" [ngClass]="{'active':currUrl == 'all-user-trader' }">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/all-user-trader']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/stable (1).png" alt="icon" />
                            </span>
                            <span class="side_page_name">All User Trades</span>
                        </a>
                    </div>
                </div> -->

          <!-- <div class="menu-box" [ngClass]="{'active':currUrl == 'trx-manage'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/trx-manage']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/stable (1).png" alt="icon" />
                            </span>
                            <span class="side_page_name">TRX Management</span>
                        </a>
                    </div>
                </div>
             
                <div class="menu-box"
                    [ngClass]="{'active':currUrl == 'token-management' || currUrl == 'add-token' || currUrl == 'edit-token' || currUrl == 'view-token'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/token-management']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/user.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Token Management</span>
                        </a>
                    </div>
                </div>
                
               
              

                <div class="menu-box" [ngClass]="{'active':currUrl == 'payment-list' || currUrl == 'payment-view'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/payment-list']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/fees (1).png" alt="icon" />
                            </span>
                            <span class="side_page_name">Payment Management</span>
                        </a>
                    </div>
                </div> -->
          <!-- <div class="menu-box"
                    [ngClass]="{'active':currUrl == 'news-letter-list' || currUrl == 'news-letter-add' || currUrl=='news-letter-edit' || currUrl=='news-letter-view'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/news-letter-list']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/stable (1).png" alt="icon" />
                            </span>
                            <span class="side_page_name">News Management</span>
                        </a>
                    </div>
                </div> -->

          <!-- <div class="menu-box"
                    [ngClass]="{'active':currUrl == 'fiat' || currUrl == 'send-money' || currUrl == 'privacy-policy' ||  currUrl == 'terms-and-conditions'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/fiat']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/static-content.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Fiat Management</span>
                        </a>
                    </div>
                </div> -->

          <!-- <div class="menu-box" [ngClass]="{'active':currUrl == 'wallet-types'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/wallet-types']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/static-content.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Fiat Wallet Types</span>
                        </a>
                    </div>
                </div> -->

          <!-- <div class="menu-box"
                    [ngClass]="{'active':currUrl == 'whitepaper-list' || currUrl == 'whitepaper-add' || currUrl == 'edit-faq' ||  currUrl == 'terms-and-conditions'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/whitepaper-list']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/static-content.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Whitepaper Management</span>
                        </a>
                    </div>
                </div>
                <div class="menu-box"
                    [ngClass]="{'active':currUrl == 'community-list' || currUrl == 'community-add' || currUrl == 'community-edit'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/community-list']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/static-content.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Community Management</span>
                        </a>
                    </div>
                </div> -->
          <!-- 
                <div class="menu-box"
                    [ngClass]="{'active':currUrl == 'setting' || currUrl == 'my-profile' || currUrl == 'edit-profile' || currUrl == 'taker-maker-fee' || currUrl == 'view-limit' || currUrl == 'withdrawl-fee' || currUrl == 'withdrawl-limit' || currUrl == 'change-password'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/setting']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/setting-icon.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Settings</span>
                        </a>
                    </div>
                </div> -->
        </div>
      </div>
    </div>
  </aside>
  <!-- <footer>
        <div class="container-fluid">
            <p class="copyright">REH ICO Crypto © 2023 All Rights Reserved. <strong
                    class="theme-text-color"></strong>
            </p>
        </div>
    </footer> -->
</div>

<!-- Logout Modal -->
<div class="modal fade global-modal reset-modal" id="signout_modal">
  <div class="modal-dialog max-WT-500">
    <div class="modal-content">
      <div>
        <div class="modal-body text-center">
          <div class="row align-items-center modal_flax_height">
            <div class="col">
              <div class="box-title mb40 d-inline-block">
                <p class="mt10">Are you sure you want to Log out?</p>
              </div>
              <div class="text-center">
                <a
                  class="btn btn-red btn-noYes"
                  (click)="onLogout()"
                  style="border-radius: 10px"
                  >YES</a
                >
                &nbsp; &nbsp; &nbsp; &nbsp;
                <button
                  type="button"
                  class="btn btn-blue btn-noYes"
                  data-dismiss="modal"
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
