import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-fieres-tutorial-edit',
  templateUrl: './fieres-tutorial-edit.component.html',
  styleUrls: ['./fieres-tutorial-edit.component.css']
})
export class FieresTutorialEditComponent implements OnInit {

  addFaqForm: FormGroup;
  pageNumber: number = 1
  faqId: any;
  editData: any;
  currTab: any;
  editImage: any
  languageArrs = languagesArray
  constructor(public route: Router, public service: MainService, public active: ActivatedRoute) {
    this.active.queryParams.subscribe((res: any) => {
      this.faqId = res.faqId;
      this.currTab = res.lan;
      console.log('fg', this.faqId);

    })
  }

  ngOnInit(): void {
    this.formValidation();
    this.editFaq()
  }

  formValidation() {
    this.addFaqForm = new FormGroup({
      'title': new FormControl('', [Validators.required]),
      'description': new FormControl('', [Validators.required]),
      'image': new FormControl(''),
      'url': new FormControl(''),
      'videoUrl': new FormControl('',[Validators.required]),
      'language': new FormControl('', [Validators.required])
    })
  }



  // edit faq english
  editFaq() {

    this.service.get("static/admin/get-academy?id=" + this.faqId).subscribe((res: any) => {
      if (res.status = 200) {
        console.log('jjh', res);
        this.editData = res.data;
        this.service.toasterSucc(res.message);
        this.addFaqForm.patchValue({
          'title': this.editData.title,
          'description': this.editData.description,
          'videoUrl' : this.editData.videoUrl,
          'url' : this.editData.link,
          'language' : this.editData.language
       })
       this.editImage = this.editData.imageUrl
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })

  }




  // update faq english
  updateFaq() {
    let request = {
      "description": this.addFaqForm.value.description,
      "imageUrl": this.editImage,
      "link": this.addFaqForm.value.url,
      "title": this.addFaqForm.value.title,
      "videoUrl": this.addFaqForm.value.videoUrl,
      "language": this.addFaqForm.value.language,
    }
    this.service.put("static/admin/edit-academy?id=" + this.faqId, request).subscribe((res: any) => {
      if (res.status = 200) {
        console.log('jjh', res);

        this.service.toasterSucc(res.message)
        this.route.navigate(['/fieres-tutorial-list'])
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })

  }
  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == '200') {
        this.editImage = res['data'];
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
}
const languagesArray = [
  {"code":"fr","name":"French","nativeName":"français, langue française"},
  {"code":"it","name":"Italian","nativeName":"Italiano"},
  {"code":"en","name":"English","nativeName":"English"},
  {"code":"es","name":"Spanish; Castilian","nativeName":"español, castellano"}
]