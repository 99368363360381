import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $
@Component({
  selector: 'app-user-kycdetail',
  templateUrl: './user-kycdetail.component.html',
  styleUrls: ['./user-kycdetail.component.css']
})
export class UserKYCDetailComponent implements OnInit {
  @Input() userId: any
  modalForm: FormGroup;
  viewData: any;
  documentId: any;
  kycId: any;
  kycStatus: any;
  ima: any;
  docData: any;
  fileExten: any;
  path_name: any
  temp = "http://www.africau.edu/images/default/sample.pdf";
  permissionArr : any = {}
  constructor(public router: Router, public service: MainService, public active: ActivatedRoute, public sanitizer: DomSanitizer) {
    this.active.params.subscribe((params) => {
      this.userId = params.id;
    });

    this.service.permission.subscribe((res) => {
      if (Object.keys(res).length) {
        this.permissionArr = res
        console.log(res);

      }
    })
  }

  ngOnInit(): void {
    this.viewKyc();
    this.showUserDetail();
    this.modalForm = new FormGroup({
      reason: new FormControl("", Validators.required),
    });
  }
  userDetail: any;
  showUserDetail() {
    this.service.showSpinner();
    var url =
      "account/admin/user-management/user-details?userId=" + this.userId;
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.userDetail = res.data;
          this.service.hideSpinner();
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  // Api of view kyc
  viewKyc() {
    var url =
      "account/admin/kyc-management/get-kyc-details?userId=" + this.userId;
    this.service.get(url).subscribe(
      (res: any) => {
        console.log("view", res);
        if (res.status == 200) {
          this.viewData = res.data.document[0];
          // this.docData = res.data.kyc.document[0]
          this.kycStatus = res.data.kycStatus;
          this.documentId = res.data.document[0].documentId;
          this.kycId = res.data.kycId;

          // get extension
          if (this.viewData.frontIdUrl) {
            // let fileExten = viewData.frontIdUrl
            let fileExten = this.viewData.frontIdUrl.split(".").pop();
            console.log(fileExten);
            this.fileExten = fileExten
          }
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access.");
        } else {
          this.service.toasterErr("Something went wrong.");
        }
      }
    );
  }

  approved() {
    if (this.kycStatus == "ACCEPTED") {
      this.service.toasterInfo("Already kyc approved.");
    } else {
      $("#approveModal").modal("show");
    }
  }

  rejected() {
    if (this.kycStatus == "REJECTED") {
      this.service.toasterInfo("Already kyc rejected.");
    } else {
      $("#rejectModal").modal("show");
    }
  }

  approveKyc() {
    this.service.showSpinner();
    let data = {
      documentId: this.documentId,
      kycId: this.kycId,
      status: "ACCEPTED",
    };
    this.service
      .post("account/admin/kyc-management/doc-status", data)
      .subscribe(
        (res: any) => {
          console.log("hjuygidsu67tdsuk", res);
          if (res.status == 200) {
            console.log("jkdhsgdfgvs87dfdksjfhg", res);
            this.service.hideSpinner();
            // this.service.toasterSucc(res.message)
            this.service.toasterSucc("KYC approved successfully.");
            $("#approveModal").modal("hide");
            this.router.navigate(["/kyc-update"]);
          } else {
            this.service.hideSpinner();
            this.service.toasterErr(res.message);
          }
        },
        (error) => {
          this.service.hideSpinner();
        }
      );
  }

  rejectKyc() {
    this.service.showSpinner();
    let data = {
      documentId: this.documentId,
      kycId: this.kycId,
      reason: this.modalForm.value.reason,
      status: "REJECTED",
    };
    this.service
      .post("account/admin/kyc-management/doc-status", data)
      .subscribe(
        (res: any) => {
          console.log("hjuygidsu67tdsuk", res);
          if (res.status == 200) {
            console.log("jkdhsgdfgvs87dfdksjfhg", res);
            this.service.hideSpinner();
            // this.service.toasterSucc(res.message)
            this.service.toasterSucc("KYC rejected successfully.");
            $("#rejectModal").modal("hide");
            this.router.navigate(["/kyc-update"]);
          } else {
            this.service.toasterErr(res.message);
            this.service.hideSpinner();
          }
        },
        (error) => {
          this.service.hideSpinner();
        }
      );
  }

  image() {
    $("#imgg").modal("show");
  }

  backimage() {
    $("#backimgg").modal("show");
  }

  selfeimage() {
    $("#selfImg").modal("show");
  }
  residenc() {
    $("#residencImg").modal("show");
  }
   getFormattedDOB(t) {
    if (String(t).includes('-')) {
      // Convert the "DD-MM-YYYY" format to an ISO date string
      const parts = t.split('-');
      if (parts.length === 3) {
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1; // Months are zero-based
        const year = parseInt(parts[2], 10);
  
        // Check if the parsed values result in a valid date
        if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
          const d = new Date(year, month, day, 12, 0, 0); // Assuming 12:00:00 for time
          if (!isNaN(d.getTime())) {
            return d.toISOString();
          }
        }
      }
  
      // If the format is not recognized or invalid, return the input as-is
      return t;
    }
  
    const utcSeconds = t;
    const d = new Date(0);
    d.setHours(12);
    d.setMinutes(0);
    d.setUTCSeconds(utcSeconds);
  
    // Format the date as "YYYY-MM-DD"
    const year = d.getUTCFullYear();
    const month = String(d.getUTCMonth() + 1).padStart(2, '0');
    const day = String(d.getUTCDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }
  
}
