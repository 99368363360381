import { AngularMyDatePickerModule } from "angular-mydatepicker";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { MainService } from "src/app/provider/main.service";

@Component({
  selector: "app-edit-ico",
  templateUrl: "./edit-ico.component.html",
  styleUrls: ["./edit-ico.component.css"],
})
export class EditICOComponent implements OnInit {
  addFaqForm: FormGroup;
  pageNumber: number = 1;
  viewData: any;
  id: any;
  constructor(
    public route: Router,
    public service: MainService,
    public activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.id = params.id;
    });
  }

  ngOnInit(): void {
    this.formValidation();
    this.viewICO();
  }

  formValidation() {
    this.addFaqForm = new FormGroup({
      plan: new FormControl("", [Validators.required]),
      price: new FormControl("", [Validators.required]),
      tokenQuantity: new FormControl("", [Validators.required]),
      referalPercentage: new FormControl("", [Validators.required]),
      range: new FormControl("", [Validators.required]),
      // 'description': new FormControl('', [Validators.required])
    });
  }

  viewICO() {
    var url = "wallet/admin/view-ico?icoId=" + this.id;
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.viewData = res.data;
          this.addFaqForm.patchValue({
            plan: this.viewData.planName,
            price: this.viewData.price,
            tokenQuantity: this.viewData.totalQuantity,
            referalPercentage: this.viewData.referalPercetage,
            range: this.viewData.range,
            // VestingRules: this.viewData.vestingRules,
            // description: this.viewData.enterDesc
          });
          this.service.hideSpinner();
        } else {
          this.service.hideSpinner();
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr(err.message);
      }
    );
  }

  publishICO() {
    let url = `wallet/admin/update-ico?icoId=${this.id}`;
    let request = {
      currency: "GBP",
      planName: this.addFaqForm.value.plan,
      price: Number(this.addFaqForm.value.price),
      totalQuantity: this.addFaqForm.value.tokenQuantity,
      referalPercentage: this.addFaqForm.value.referalPercentage,
      range: this.addFaqForm.value.range,
      // 'enterDesc': this.addFaqForm.value.description,
    };
    this.service.post(url, request).subscribe(
      (res: any) => {
        if ((res.status = 200)) {
          this.service.toasterSucc(res.message);
          this.route.navigate(["/icoSalesListing"]);
        } else {
          this.service.hideSpinner();
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr(err.error.message);
      }
    );
  }
}
