import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MainService } from "src/app/provider/main.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
@Component({
  selector: "app-token-coin-add",
  templateUrl: "./token-coin-add.component.html",
  styleUrls: ["./token-coin-add.component.css"],
})
export class TokenCoinAddComponent implements OnInit {
  addTokenCoinForm: FormGroup;
  pageNumber: number = 1;
  currTab: any;
  constructor(public route: Router, public service: MainService) {}

  ngOnInit(): void {
    this.formValidation();
  }

  formValidation() {
    this.addTokenCoinForm = new FormGroup({
      coinName: new FormControl("", [Validators.required]),
    });
  }

  // add faq english
  addCoin() {
    let request = {
      coinName: this.addTokenCoinForm.value.coinName,
    };
    console.log("f", "jd");

    this.service.post(`wallet/coin/add-market-coin`, request).subscribe(
      (res: any) => {
        if ((res.status = 200)) {
          this.service.toasterSucc(res.message);
          this.route.navigate(["/token-coin-list"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
}
