<main class="middle-content">
    <div class="content-section">
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:#26272d">SUB ADMIN DETAIL</h1>
        </div>
        <div class="order-view mt30  mrgn-0-auto">
            <div class="main-block-innner mb40 ">
                <div class="user-profile">
                    <div class="image-box">
                        <img [src]="imageUrl || 'assets/img/profile-img.jpg'" class="profile-pic" alt="profile image">
                    </div>
                </div>
                <div class="add-store-block input-style" style="margin-left: 27%;">

                    <div class="form-group row">
                        <label class="col-md-5 text" for="">Name</label>
                        <span class="col-md-1">:</span>
                        <p class="col-md-6">{{viewData?.FirstName || 'N/A'}} {{viewData?.LastName}}</p>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-5 text" for="">Email</label>
                        <span class="col-md-1">:</span>
                        <p class="col-md-6">{{viewData?.Email || 'N/A'}}</p>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-5 text" for="">Mobile Number</label>
                        <span class="col-md-1">:</span>
                        <p class="col-md-6">{{viewData?.PhoneNo || 'N/A'}}</p>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-5 text" for="">Role</label>
                        <span class="col-md-1">:</span>
                        <p class="col-md-6">Sub Admin</p>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-bordered" aria-describedby="hot wallet list">
                    <thead>
                        <tr class="no_wrap_th">
                            <th scope="col">Managemnets</th>
                            <th scope="col">Create</th>
                            <th scope="col">Update</th>
                            <th scope="col">Read</th>
                            <th scope="col">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of permArray">
                            <!-- <ng-container *ngIf="userDeatil === 'ADMIN' || item.keyName !== 'CLAIM_TOKEN_MANAGEMENT'"> -->
                            <td>{{item.keyValue}}</td>
                            <td>
                                <input type="checkbox" *ngIf="item.access?.includes('CREATE')" disabled
                                    [checked]="isChecked(item.keyName , 'CREATE')" class="form-control" name="" id="">
                            </td>
                            <td>
                                <input type="checkbox" *ngIf="item.access?.includes('UPDATE')" disabled
                                    [checked]="isChecked(item.keyName , 'UPDATE')" class="form-control" name="" id="">
                            </td>
                            <td>
                                <input type="checkbox" *ngIf="item.access?.includes('READ')" disabled
                                    [checked]="isChecked(item.keyName , 'READ')" class="form-control" name="" id="">
                            </td>
                            <td>
                                <input type="checkbox" *ngIf="item.access?.includes('DELETE')" disabled
                                    [checked]="isChecked(item.keyName , 'DELETE')" class="form-control" name="" id="">
                            </td>
                            <!-- </ng-container> -->
                        </tr>
                        <tr>
                            <td>
                                Reward Management
                            </td>
                            <td>

                            </td>
                            <td>
                                <input class="curs" type="checkbox" class="form-control" [checked]="viewData?.rewarded"  name="" id="" disabled>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <div class="text-left mt40 text-center">
                <a routerLink="/user-management-admin" class="btn btn-large  max-WT-200 font-100 btn-green">Back</a>
            </div>
        </div>
    </div>
</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">REH ICO Crypto © 2023 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>