import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { countryJson } from 'src/assets/country';

declare var $
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  addFaqForm: FormGroup;
  pageNumber: number = 1
  currTab: any;
  countryList = obj.country
  userId: any
  loylity: FormGroup;
  userDetail:any ;
  constructor(public route: Router, public service: MainService, public active: ActivatedRoute, private datePipe: DatePipe) {

    this.active.queryParams.subscribe((params) => {
      this.currTab = params.tab
      this.userId = params.userId
      if (params.userId) {
        this.showUserDetail()
      }
      console.log('jjf', this.currTab);

    })
  }

  ngOnInit(): void {
    this.formValidation();
    this.getFormValue()
    this.myProfile()
  }
  getFormValue() {
    this.loylity = new FormGroup({
      loyalityAmount: new FormControl('')
    })
  }
  formValidation() {
    this.addFaqForm = new FormGroup({
      'firstName': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z]+[ ]?[A-Za-z]+[ ]?[A-Za-z]+[ ]?[A-Za-z]{1,}$/)]),
      'lastName': new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9]*[a-zA-Z]+[a-zA-Z0-9]*$')]),
      'phoneNumber': new FormControl('', [Validators.required, Validators.pattern('[- +()0-9]{10,12}')]),
      'emailId': new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]),
      'country': new FormControl('', [Validators.required]),
      'postalCode': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'state': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z]+[ ]?[A-Za-z]+[ ]?[A-Za-z]+[ ]?[A-Za-z]{1,}$/)]),
      'address': new FormControl('', [Validators.required]),
      'dob': new FormControl('', [Validators.required]),
      'ibiName': new FormControl('', [Validators.required]),
      'ibiId': new FormControl('', [Validators.required]),
      'twofa': new FormControl(''),
      // 'volumeAccount': new FormControl(''),
    })
  }
  
  // My Profile Functionality
  myProfile() {
    var url = 'account/my-account';
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userDetail = res['data'];
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Session Expired');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  showUserDetail() {
    this.service.showSpinner();
    var url = "account/admin/user-management/user-details?userId=" + this.userId;
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          let dob = this.getDOB(res.data.dob)
          let dttt = new Date(dob).toISOString().split('T')[0]
          this.addFaqForm.patchValue({
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            phoneNumber: res.data.phoneNo,
            emailId: String(res.data.email).toLowerCase(),
            country: res.data.country,
            postalCode: res.data.zipCode,
            state: res.data.state,
            address: res.data.address,
            dob: dttt,
            ibiName: res.data.ibiName,
            ibiId: res.data.ibiId,
            twofa: res.data.twoFaType,
            // volumeAccount: res.data.volumeAccount,
          })
         
          this.service.hideSpinner();
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  getDOB(t){
    try {
      if(String(t).includes('-')){
        return new Date(t).toISOString()
      }
      var utcSeconds = t;
      var d = new Date(0);
      d.setHours(12)
      d.setMinutes(0)
       d.setUTCSeconds(utcSeconds);
       return new Date(d).toISOString()
    } catch (error) {
      
      return new Date("1970-01-01").toISOString().split('T')[0]
    }
    
  }
  // add faq language
  addUser() {
    let body = {
      'address': this.addFaqForm.value.address,
      'country': this.addFaqForm.value.country,
      'dob': this.addFaqForm.value.dob,
      'email': this.addFaqForm.value.emailId,
      'firstName': this.addFaqForm.value.firstName,
      'ibiId': this.addFaqForm.value.ibiId,
      'ibiName': this.addFaqForm.value.ibiName,
      'lastName': this.addFaqForm.value.lastName,
      'password': "Password",
      'phoneNo': this.addFaqForm.value.phoneNumber,
      'roleStatus': "USER",
      'state': this.addFaqForm.value.state,
      'zipCode': this.addFaqForm.value.postalCode,
      // 'volumeAccount': this.addFaqForm.value.volumeAccount
    }
    console.log(body);

    this.service.post(`account/admin/user-management/add-new-user-by-admin`, body).subscribe((res: any) => {
      console.log(res);

      if (res.status == 200) {
        this.service.toasterSucc(res.message)
        this.route.navigate(['/user-management'])
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  updateUserData() {
    let body = {
      'address': this.addFaqForm.value.address,
      'country': this.addFaqForm.value.country,
      'dob': this.addFaqForm.value.dob,
      'email': this.addFaqForm.value.emailId,
      'firstName': this.addFaqForm.value.firstName,
      'ibiId': this.addFaqForm.value.ibiId,
      'ibiName': this.addFaqForm.value.ibiName,
      'lastName': this.addFaqForm.value.lastName,
      'password': "Password",
      'phoneNo': this.addFaqForm.value.phoneNumber,
      'roleStatus': "USER",
      'state': this.addFaqForm.value.state,
      'zipCode': this.addFaqForm.value.postalCode,
      'twoFaType': this.addFaqForm.value.twofa
      // 'volumeAccount': this.addFaqForm.value.volumeAccount
    }
    this.service.post(`account/admin/user-management/update-user-by-admin`, body).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.toasterSucc(res.message)
        this.route.navigate(['/user-management'])
        $('#editUserDetails').modal('hide')
      } else {
        $('#editUserDetails').modal('hide')
      }
    }, err => {
      this.service.hideSpinner();
      $('#editUserDetails').modal('hide')
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  addUserData() {
    this.addUser()
  }
  showData:any
  updateUserDataModal() {
    let body = {
      'address': this.addFaqForm.value.address,
      'country': this.addFaqForm.value.country,
      'dob': this.addFaqForm.value.dob,
      'email': this.addFaqForm.value.emailId,
      'firstName': this.addFaqForm.value.firstName,
      'ibiId': this.addFaqForm.value.ibiId,
      'ibiName': this.addFaqForm.value.ibiName,
      'lastName': this.addFaqForm.value.lastName,
      'password': "Password",
      'phoneNo': this.addFaqForm.value.phoneNumber,
      'roleStatus': "USER",
      'state': this.addFaqForm.value.state,
      'zipCode': this.addFaqForm.value.postalCode,
      'twoFaType': this.addFaqForm.value.twofa,
      // 'volumeAccount': this.addFaqForm.value.volumeAccount
    }
    this.showData = body
    setTimeout(() => {
    $('#editUserDetails').modal('show')
      
    }, 1000);

    
  }
  loginSessionData: any;
  openTransferModal() {
    $('#transferAmount').modal('show')
  }
  transferLoyltyMoney() {
    this.service.showSpinner()
    let url = `wallet/vesting-management/admin/loyality-amount?ibiId=${this.addFaqForm.value.ibiId}&ibiName=${this.addFaqForm.value.ibiName}&loyalityAmount=${this.loylity.value.loyalityAmount}&userId=${this.userId}&volume=${''}`
    this.service.post(url, {}).subscribe(
      (res: any) => {
        if (res.status == 200) {

          $('#transferAmount').modal('hide')
          this.service.toasterSucc(res['message'])
          this.service.hideSpinner();
        } else {
          $('#transferAmount').modal('hide')
          this.service.toasterSucc(res['message'])
          this.service.hideSpinner();
        }
      },
      (err) => {
        $('#transferAmount').modal('hide')
        this.service.hideSpinner();
        this.service.toasterSucc(err['message'])
        if (err.status == 401) {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something went wrong");
        }
      }
    );
  }

}


let obj = new countryJson()