import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-admin-detail',
  templateUrl: './admin-detail.component.html',
  styleUrls: ['./admin-detail.component.css']
})
export class AdminDetailComponent implements OnInit {
  userId: any;
  viewData: any;
  imageUrl: any;
  Previlage: any
  userDeatil = localStorage.getItem('usertype')
  permArray = [
    { 
      id: 1,
      keyName : 'USER_MANAGEMENT',
      keyValue : 'User Management',
      access : ['CREATE' , 'UPDATE', 'READ','DELETE']
    },
    { 
      id: 2,
      keyName : 'SUBADMIN_MANAGEMENT',
      keyValue : 'Sub Admin Management',
      access : ['CREATE' , 'UPDATE', 'READ','DELETE']
    },
    { 
      id: 3,
      keyName : 'TOKEN_TRANS_MANAGEMENT',
      keyValue : 'Sale Trances Management',
      access : ['CREATE' , 'UPDATE', 'READ','DELETE']
    },
    { 
      id: 4,
      keyName : 'TRANSACTION_MANAGEMENT',
      keyValue : 'Transactions Management',
      access : ['CREATE' , 'UPDATE', 'READ','DELETE']
    },
    { 
      id: 5,
      keyName : 'PAYMENT_MANAGEMENT', 
      keyValue : 'Payment Management', 
      access : ['CREATE' , 'UPDATE', 'READ','DELETE']
    },
    { 
      id: 6,
      keyName : 'KYC_MANAGEMENT',
      keyValue : 'KYC Management',
      access : ['CREATE' , 'UPDATE', 'READ','DELETE']
    },
    { 
      id: 7,
      keyName : 'QUERY_MANAGEMENT',
      keyValue : 'Queries Management',
      access : ['CREATE' , 'UPDATE', 'READ','DELETE']
    },
    { 
      id: 8,
      keyName : 'STATIC_CONTENT_MANGEMENT',
      keyValue : 'Static Content Management',
      access : ['CREATE' , 'UPDATE', 'READ','DELETE']
    },
    { 
      id: 9,
      keyName : 'TOKEN_MANAGEMENT',
      keyValue : 'Token Management',
      access : ['CREATE' , 'UPDATE', 'READ','DELETE']
    },
  
    { 
      id: 10,
      keyName : 'FIERES_TUTORIAL',
      keyValue : 'Fieres Academy Management',
      access : ['CREATE' , 'UPDATE', 'READ','DELETE']
    },
    { 
      id: 11,
      keyName : 'CLAIM_TOKEN_MANAGEMENT',
      keyValue : 'Claim Token Management',
      access : ['CREATE' , 'UPDATE', 'READ','DELETE']
    },
    { 
      id: 12,
      keyName : 'VESTING_MANAGEMENT',
      keyValue : 'Vesting Management',
      access : ['CREATE' , 'UPDATE', 'READ','DELETE']
    }
  ]
  constructor(public route: Router, public service: MainService, public active: ActivatedRoute) {
    this.active.params.subscribe((params) => {
      this.userId = params.id
    })
  }

  ngOnInit(): void {
    this.viewAdmin();
  }

  viewAdmin() {
    this.service.showSpinner();
    var url = "account/admin/user-management/get-subadmin?subAdminId=" + this.userId;
    this.service.get(url).subscribe((res: any) => {
      console.log("gf", res);
      if (res.status == 200) {
        this.service.hideSpinner();
        this.viewData = res.data
        this.imageUrl = res.data.ImageUrl
        let str = String(res.data.Previlage).replace(/'/gi, '"')
        this.Previlage = JSON.parse(str)
        console.log(this.Previlage);

      }

    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }


  isChecked(perm, permType) {
    return this.Previlage[perm]?.includes(permType)
  }


}
