<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title" style="color:#fff">Sale Round
            </h1>

            <h3 class="mt-5">REH Price : {{fieroPrice}} USD</h3>

        </div>
        <div class="d-flex justify-content-center">
            <div class="main-radio">
                <input type="radio" [(ngModel)]="priceType" (change)="setTab($event.target.value)" id="priceType"
                    name="priceType" value="TRANCHES">
                <label for="priceType">Tranches</label>
            </div>
            <div class="main-radio">
                <input type="radio" [(ngModel)]="priceType" (change)="setTab($event.target.value)" name="priceType"
                    id="mark" value="MARK">
                <label for="mark">Mark up/down</label>
            </div>
        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div class="tab-pane1">
                            <div class="table-responsive" *ngIf="priceType == 'TRANCHES'">
                                <table class="table table-bordered" aria-describedby="user list">
                                    <thead>
                                        <tr class="no_wrap_th">
                                            <th scope="col">Phase</th>
                                            <th scope="col">Fiero price in (USD)</th>
                                            <!-- <th scope="col">Live USD Price</th> -->
                                            <th scope="col">Total Supply</th>
                                            <th scope="col">Start Date</th>
                                            <th scope="col">End Date</th>
                                            <th scope="col">Total Sold</th>
                                            <th scope="col">Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let item of coinList  |  paginate:{itemsPerPage:10, currentPage:pageNumber, totalItems:coinList.length?.length}; let i=index">
                                            <td>{{item?.planName}}</td>
                                            <td *ngIf="!cointAmount[i]">{{item?.fieroUsdPrice}}</td>
                                            <td *ngIf="cointAmount[i]">
                                                <input [(ngModel)]="price[i]" (keypress)="service.preventSpace($event);"
                                                    style="text-align: left;" max="12" type="number" placeholder="Price"
                                                    class="form-control">
                                            </td>

                                            <!-- <td *ngIf="!cointAmount[i]">{{item?.price}}</td>
                                            <td *ngIf="cointAmount[i]">
                                                <input 
                                                    [(ngModel)]="usd[i]"
                                                    (keypress)="service.preventSpace($event);"
                                                    style="text-align: left;" max="12" type="number"
                                                    placeholder="USD Price" class="form-control">
                                            </td> -->

                                            <td *ngIf="!cointAmount[i]">{{item?.totalQuantity}}</td>
                                            <td *ngIf="cointAmount[i]">
                                                <input [(ngModel)]="supply[i]"
                                                    (keypress)="service.preventSpace($event);" style="text-align: left;"
                                                    max="12" type="number" placeholder="Price" class="form-control">
                                            </td>


                                            <td *ngIf="!cointAmount[i]">{{item?.saleDate | date : 'dd-MM-yyyy'}}</td>
                                            <td *ngIf="cointAmount[i]">
                                                <input [(ngModel)]="startDate[i]" style="text-align: left;" type="date"
                                                    placeholder="Supply" class="form-control">
                                            </td>

                                            <td *ngIf="!cointAmount[i]">{{item?.saleUptodate | date : 'dd-MM-yyyy'}}
                                            </td>
                                            <td *ngIf="cointAmount[i]">
                                                <input [(ngModel)]="endDate[i]" [min]="startDate[i]"
                                                    style="text-align: left;" type="date" placeholder="Supply"
                                                    class="form-control">
                                            </td>
                                            <td>{{item?.totalSold || '0'}}</td>

                                            <!-- <td><button type="submit" class="btn  btn-theme cus-mr"  style="width: 88%;" routerLink="/update-withdrawl-amount/{{item.coinShortName}}">Edit</button></td> -->
                                            <td style="max-width: 230px;">
                                                <button *ngIf="!cointAmount[i]" type="submit"
                                                    class="btn  btn-theme cus-mr" (click)="enableInputField(i)"
                                                    style="width: 80%;padding: 10px;">Edit</button>
                                                <button *ngIf="cointAmount[i]" (click)="updateCoinAmount(i,item.icoId)"
                                                    type="submit" class="btn  btn-theme cus-mr" style="width: 80%;
                                                    padding: 10px;">Update</button>
                                                <!-- <button  type="submit" class="btn  btn-theme cus-mr"
                                                    (click)="blockUser(item.icoId, item?.icoStatus)"
                                                    style="width: 45%;padding: 10px;">{{item?.icoStatus == 'DISABLE' ? 'Resume' : 'Pause'}}</button> -->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="priceType == 'MARK'" class="markup">
                                <div class="d-flex justify-content-center mb-30">
                                    <div class="mark-selector">
                                        <input type="radio" [(ngModel)]="markTab"
                                            (change)="setMarkTab($event.target.value)" id="markup" name="markType"
                                            value="UP">
                                        <label for="markup">Markup</label>
                                    </div>
                                    <div class="mark-selector">
                                        <input type="radio" [(ngModel)]="markTab"
                                            (change)="setMarkTab($event.target.value)" id="makdown" name="markType"
                                            value="DOWN">
                                        <label for="makdown">Markdown</label>
                                    </div>
                                    <div class="mark-selector">
                                        <input type="radio" [(ngModel)]="markTab"
                                            (change)="setMarkTab($event.target.value)" id="nomarkup" name="markType"
                                            value="NOMARK">
                                        <label for="nomarkup">No Mark up/down</label>
                                    </div>
                                </div>

                                <ng-container *ngIf="markTab == 'UP'">
                                    <div class="row justify-content-center mt-5 m-auto" >
                                        <div class="col-3">
                                            <p class="label">Mark up %</p>
                                        </div>
                                        <div class="col-1">
                                            <p class="label">:</p>
                                        </div>
                                        <div class="col-4">
                                            <input type="number" (input)="setFinalPrice($event.target.value)" [(ngModel)]="markUpPercentage"
                                                onkeyup="if(this.value >100) this.value = 100; if(this.value < 0) this.value = 0"
                                                class="form-control percent" placeholder="Markup percentage">
                                        </div>
                                    </div>
                                    <div class="row justify-content-center  m-auto">
                                        <div class="col-3">
                                            <p class="label">Final Price</p>
                                        </div>
                                        <div class="col-1">
                                            <p class="label">:</p>
                                        </div>
                                        <div class="col-4">
                                            <p class="label">{{finalPrice}} USD</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button type="submit"  [disabled]="finalPrice == '0.00'" class="btn  btn-theme cus-mr save-btn"
                                                (click)="setMarkupPercentage()">Save</button>
                                        </div>
                                    </div>
                                </ng-container>
                  
                                <ng-container *ngIf="markTab == 'DOWN'">
                                    <div class="row justify-content-center mt-5  m-auto" >
                                        <div class="col-3">
                                            <p class="label">Mark down %</p>
                                        </div>
                                        <div class="col-1">
                                            <p class="label">:</p>
                                        </div>
                                        <div class="col-4">
                                            <input type="number" (input)="setFinalPrice($event.target.value)" [(ngModel)]="markDownPercentage"
                                                onkeyup="if(this.value > 100) this.value = 100; if(this.value < 0) this.value = 0"
                                                class="form-control percent" placeholder="Markup percentage">
                                        </div>
                                    </div>
                                    <div class="row justify-content-center  m-auto">
                                        <div class="col-3">
                                            <p class="label">Final Price</p>
                                        </div>
                                        <div class="col-1">
                                            <p class="label">:</p>
                                        </div>
                                        <div class="col-4">
                                            <p class="label">{{finalPrice}} USD</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button type="submit" [disabled]="finalPrice == '0.00'" class="btn  btn-theme cus-mr save-btn"
                                                (click)="setMarkupPercentage()">Save</button>
                                        </div>
                                    </div>
                                </ng-container>
                                
                                <ng-container *ngIf="markTab == 'NOMARK'">
                                    <div class="row justify-content-center mt-5  m-auto">
                                        <div class="col-3">
                                            <p class="label">No Markup/down %</p>
                                        </div>
                                        <div class="col-1">
                                            <p class="label">:</p>
                                        </div>
                                        <div class="col-4">
                                            <input type="number" class="form-control percent" value="0.00" readonly
                                                placeholder="Markup percentage">
                                        </div>
                                    </div>
                                    <div class="row justify-content-center  m-auto">
                                        <div class="col-3">
                                            <p class="label">Final Price</p>
                                        </div>
                                        <div class="col-1">
                                            <p class="label">:</p>
                                        </div>
                                        <div class="col-4">
                                            <p class="label">{{fieroPrice}} USD</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button type="submit" class="btn  btn-theme cus-mr save-btn"
                                                (click)="setMarkupPercentage()">Save</button>
                                        </div>
                                    </div>
                                </ng-container>

                            </div>
                            <!-- <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end" >
                                <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                            </div>
                            -->

                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="order-view mt30  mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class=" input-style">
                        <form [formGroup]="addFaqForm">
                            <div class="form-group row ">

                                <div class="col-md-6 col-sm-12">
                                    <label class="col-md-4" class="labelBox">Enter ICO Name:

                                    </label>
                                    <input type="text" class="form-control " formControlName="plan"
                                        (keypress)="service.preventSpace($event)" />

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('plan')?.hasError('required') && (addFaqForm.get('plan').dirty || addFaqForm.get('plan').touched )"
                                            class="error" padding>*Plan name is required</p>
                                    </span>

                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <label class="col-md-4" class="labelBox"> Enter Price:



                                    </label>
                                    <input type="text" class="form-control " formControlName="price"
                                        (keypress)="service.preventSpace($event);service.numberOnly($event)" />

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('price')?.hasError('required') && (addFaqForm.get('price').dirty || addFaqForm.get('price').touched )"
                                            class="error" padding>*Price is required</p>
                                    </span>

                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox">Enter Token Quantity:





                                    </label>
                                    <input type="text" class="form-control" formControlName="tokenQuantity"
                                        (keypress)="service.preventSpace($event); service.numberOnly($event)" />

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('tokenQuantity')?.hasError('required') && (addFaqForm.get('tokenQuantity').dirty || addFaqForm.get('tokenQuantity').touched )"
                                            class="error" padding>*Quantity is required</p>
                                    </span>

                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox"> Enter Vesting Rules:</label>
                                    <input type="text" class="form-control " formControlName="VestingRules"
                                        (keypress)="service.preventSpace($event)" />

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('VestingRules')?.hasError('required') && (addFaqForm.get('VestingRules').dirty || addFaqForm.get('VestingRules').touched )"
                                            class="error" padding>*Rules is required</p>
                                    </span>

                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox"> Enter Sale Start Date:</label>
                                    <input type="date" class="form-control " formControlName="saleDate"
                                        (keypress)="service.preventSpace($event)" />

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('saleDate')?.hasError('required') && (addFaqForm.get('saleDate').dirty || addFaqForm.get('saleDate').touched )"
                                            class="error" padding>*Sale date is required</p>
                                    </span>

                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox"> Enter Sale End Date:</label>
                                    <input type="date" class="form-control"
                                        [min]="addFaqForm.controls['saleDate'].value"
                                        [readonly]="!this.addFaqForm.controls['saleDate'].value"
                                        formControlName="saleEndDate" (keypress)="service.preventSpace($event)" />

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('saleEndDate')?.hasError('required') && (addFaqForm.get('saleEndDate').dirty || addFaqForm.get('saleEndDate').touched )"
                                            class="error" padding>*Sale end date is required</p>
                                    </span>

                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox"> Enter Description:
                                    </label>
                                    <textarea class="form-control" id="exampleFormControlTextarea1"
                                        formControlName="description" rows="5" style="height: auto;"></textarea>

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('description')?.hasError('required') && (addFaqForm.get('description').dirty || addFaqForm.get('description').touched )"
                                            class="error" padding>*Description is required</p>
                                    </span>

                                </div>


                            </div>


                            <div class="text-center mt40" style="margin-right: -14%;">
                                <button class="btn btn-large  max-WT-200 font-100 btn-green mr-5" (click)="publishICO()"
                                    [disabled]="!addFaqForm.valid">Publish ICO</button>
                                <a class="btn btn-large  max-WT-200 font-100 btn-grey ml8"
                                    [routerLink]="['/icoSalesListing']">Cancel</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div> -->
        </div>
    </main>
    <!-- Middle Content End -->
</div>

<footer>
    <div class="container-fluid">
        <p class="copyright">REH ICO Crypto © 2023 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>