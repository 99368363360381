<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title">View </h1>
    </div>
    <div class="content-section">
        <div class="shadow p-3 mb-5 bg-white rounded">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">

                                    <div class="row">
                                        <div class="col-5">
                                            <p class="sub_head">Plan Name</p>
                                        </div>
                                        <div class="col-2 text-center">
                                            <span>:</span>
                                        </div>
                                        <div class="col-5">
                                            <p class="sub_title">{{viewData?.planName}}</p>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-5">
                                            <p class="sub_head">Token Quantity:
                                            </p>
                                        </div>
                                        <div class="col-2 text-center">
                                            <span>:</span>
                                        </div>
                                        <div class="col-5">
                                            <p class="sub_title">{{viewData?.totalQuantity}}

                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <p class="sub_head">Price

                                            </p>
                                        </div>
                                        <div class="col-2 text-center">
                                            <span>:</span>
                                        </div>
                                        <div class="col-5">
                                            <p class="sub_title">{{viewData?.price}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <p class="sub_head">Referral Percentage

                                            </p>
                                        </div>
                                        <div class="col-2 text-center">
                                            <span>:</span>
                                        </div>
                                        <div class="col-5">
                                            <p class="sub_title">{{viewData?.referalPercetage}}</p>
                                        </div>
                                    </div>

                                    <!-- <div class="row">
                                        <div class="col-5">
                                            <p class="sub_head"> Sale Start Date

                                            </p>
                                        </div>
                                        <div class="col-2 text-center">
                                            <span>:</span>
                                        </div>
                                        <div class="col-5">
                                            <p class="sub_title">{{(viewData?.saleDate | date:'medium') || '--'}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <p class="sub_head"> Sale End Date

                                            </p>
                                        </div>
                                        <div class="col-2 text-center">
                                            <span>:</span>
                                        </div>
                                        <div class="col-5">
                                            <p class="sub_title">{{(viewData?.saleUptodate
                                                | date:'medium') || '--'}}</p>
                                        </div>
                                    </div> -->
                                    <div class="row">
                                        <div class="col-5">
                                            <p class="sub_head"> Updated Date & Time

                                            </p>
                                        </div>
                                        <div class="col-2 text-center">
                                            <span>:</span>
                                        </div>
                                        <div class="col-5">
                                            <p class="sub_title">{{(viewData?.updateTime | date:'medium') || '--'}}</p>
                                        </div>
                                    </div>


                                    <div class="row">
                                        <div class="col-5">
                                            <p class="sub_head"> Status

                                            </p>
                                        </div>
                                        <div class="col-2 text-center">
                                            <span>:</span>
                                        </div>
                                        <div class="col-5">
                                            <p class="sub_title">{{viewData?.icoStatus}}

                                            </p>
                                        </div>
                                    </div>
                                    <!-- <div class="row">
                                        <div class="col-5">
                                            <p class="sub_head"> Description

                                            </p>
                                        </div>
                                        <div class="col-2 text-center">
                                            <span>:</span>
                                        </div>
                                        <div class="col-5">
                                            <p class="sub_title">{{viewData?.enterDesc}}

                                            </p>
                                        </div>
                                    </div> -->






                                </div>

                            </div>
                        </div>
                        <div class="text-center mt40">
                            <!-- <button class="btn btn-large  max-WT-200 font-100 btn-green mr-5">Active

                            </button> -->
                            <a class="btn btn-large  max-WT-200 font-100 btn-grey ml8"
                                [routerLink]="['/icoSalesListing']">Cancel</a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">REH ICO Crypto © 2023 All Rights Reserved.<strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>
<!-- appraved_modal Start -->
<div class="modal fade global-modal reset-modal" id="approveModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">KYC Approve</h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <!-- <p style="font-size: 25px;">Are you sure you want to approve kyc with data entered?</p> -->
                                <p style="font-size: 25px;">Are you sure you want to approve this kyc?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- appraved_modal End -->
<!-- rejected_modal Start -->
<div class="modal fade global-modal reset-modal" id="rejectModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password" [formGroup]="modalForm">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 20px;">Are you sure you want to revise this kyc? </p>

                                <div class="row" style="margin-bottom: 10px;">
                                    <div class="col-md-4 ">
                                        <p style="font-size: 16px;">Reason:</p>
                                    </div>
                                    <div class="col-md-8">
                                        <textarea formControlName="reason" class="form-control"
                                            id="exampleFormControlTextarea1" rows="3" style="height: 115px;"></textarea>

                                    </div>

                                </div>
                                <!-- <div style="margin-top: 2%;margin-bottom: 2%; margin-right: 13%;">
                                    <input class="form-check-input" style="margin-top: 2%;" type="checkbox" value=""
                                        id="Notify User">

                                </div> -->
                                <div style="margin-left: 27%;">
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- rejected_modal End -->
<!--image view modal-->
<div class="modal fade global-modal reset-modal" id="imgg" style="width: 60%;margin-left:25% ;">
    <div class="modal-dialog max-WT-500">
        <div class="modal-content">
            <div>

                <div class="row align-items-center modal_flax_height">
                    <div class="col" style="text-align: center; font-size:25px;">
                        <i class="fas fa-times" data-dismiss="modal" style="float: right;  margin-right: 2px;"
                            aria-hidden="true"></i>
                        <strong>Front Image</strong>
                        <img src="{{viewData?.frontIdUrl ? viewData?.frontIdUrl : 'assets/img/noimg.png' }}" alt=""
                            style="width: 100%;z-index: 1;">

                    </div>

                </div>

            </div>
            <!--   -->

        </div>
    </div>
</div>

<div class="modal fade global-modal reset-modal" id="backimgg" style="width: 60%;margin-left:25% ;">
    <div class="modal-dialog max-WT-500">
        <div class="modal-content">
            <div>

                <div class="row align-items-center modal_flax_height">
                    <div class="col" style="text-align: center; font-size:25px;">
                        <i class="fas fa-times" data-dismiss="modal" style="float: right;  margin-right: 2px;"
                            aria-hidden="true"></i>

                        <strong>Back Image</strong>

                        <img src="{{viewData?.backIdUrl ? viewData?.backIdUrl : 'assets/img/noimg.png' }}" alt=""
                            style="width: 100%;z-index: 1;">

                    </div>

                </div>

            </div>
            <!--   -->

        </div>
    </div>
</div>