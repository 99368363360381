<!-- <div class="outer-box">
    <div class="global-table no-radius p0">
        <div class="tab-content1">
            <div class="tab-pane1"> -->
                <div class="table-responsive" style="margin-top: 25px;">
                    <table class="table table-bordered" aria-describedby="user list">
                        <!-- <thead>
                            <tr class="no_wrap_th">
                                <th scope="col">S.No</th>
                                <th scope="col">Fiero in 1 USD</th>
                                <th scope="col">Coin name</th>
                                <th scope="col">Action</th>

                            </tr>
                        </thead> -->
                        <tbody>
                          <ng-container *ngFor="let item of coinList  | paginate:{itemsPerPage:10, currentPage:pageNumber, totalItems:coinList.length?.length}; let i=index">
                            <tr *ngIf="item?.coinShortName == 'FIERO'"
                            >
                            <td style="width: 25%;">Current Price</td>
                            <td colspan="2" *ngIf="!cointAmount[i]">{{item.marketPriceFierex}}</td>
                            <td colspan="2"  *ngIf="cointAmount[i]">
                                <input [value]="item.marketPriceFierex"
                                    [(ngModel)]="withdrawAmount[i] = item.marketPriceFierex"
                                    
                                    (keypress)="service.preventSpace($event);"
                                    step="0.1" type="number"
                                    style="text-align: left;" maxlength="12"  placeholder="USD Price"
                                    class="form-control">
                            </td>

                            <!-- <td>{{item.coinShortName == 'AVT' ? 'GREM' : item.coinShortName}}</td> -->
                            <td style="width: 35%;">
                                <button *ngIf="!cointAmount[i]" type="submit" class="btn  btn-theme cus-mr"
                                    (click)="enableInputField(i)" >Edit</button>
                                <button *ngIf="cointAmount[i]" type="submit" class="btn  btn-theme cus-mr"
                                    (click)="updateCoinAmount(item.coinShortName,withdrawAmount[i])"
                                   >Update</button>
                            </td>
                        </tr>
                          </ng-container>
                        </tbody>
                    </table>
                </div>
<!--               
            </div>
        </div>
    </div>
</div> -->