<main class="middle-content">
  <div class="page_title_block">
    <h1 class="page_title" style="color: #26272d">COIN MANAGEMENT</h1>
  </div>

  <div class="content-section">
    <div class="outer-box">
      <div class="global-table no-radius p0">
        <div class="tab-content1">
          <div class="tab-pane1">
            <div class="row d-flex justify-content-end w_100">
              <button
                type="submit"
                class="btn btn-theme faq"
                style="margin-right: 1.3%"
                (click)="addFaq()"
              >
                Add Coin
              </button>
            </div>
            <!-- tab english faq start -->
            <div class="tab-pane active show" style="margin-top: 20px">
              <div class="table-responsive">
                <table
                  class="table table-bordered"
                  aria-describedby="user list"
                >
                  <thead>
                    <tr class="no_wrap_th">
                      <th scope="col">S.No.</th>
                      <th scope="col">Coin Name</th>
                      <th scope="col">Price (USD)</th>
                      <th scope="col">Status</th>
                      <th scope="col" class="action_td_btn3">Action</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="faqData?.length">
                    <tr
                      *ngFor="
                        let items of faqData
                          | paginate
                            : {
                                itemsPerPage: itemsPerPage,
                                currentPage: pageNumber,
                                totalItems: totalRecords
                              };
                        let i = index
                      "
                    >
                      <td>
                        {{ itemsPerPage * (this.pageNumber - 1) + i + 1 }}
                      </td>
                      <td class="faq-ques">{{ items?.coinName }}</td>
                      <td class="faq-ques">{{ items?.marketPriceInUsd }}</td>
                      <td class="faq-ques">
                        {{ items?.status ? "Active" : "Block" }}
                      </td>
                      <td class="action_td_btn3">
                        <a
                          (click)="openModal(items.id, items?.status)"
                          *ngIf="items?.status == false"
                          ><i class="fa fa-ban" aria-hidden="true"></i
                        ></a>
                        <a
                          (click)="openModal(items.id, items?.status)"
                          *ngIf="items?.status == true"
                          ><i
                            class="fa fa-ban"
                            aria-hidden="true"
                            style="color: green"
                          ></i
                        ></a>
                        <a (click)="openModal(items.id, 'delete')"
                          ><i class="fa fa-trash" aria-hidden="true"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="!faqData?.length">
                    <tr>
                      <td colspan="10" vertical-align="middle">
                        <div class="no-record">
                          <div class="no-recordin">
                            <h5>No record found</h5>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                class="custom-pagination mt20"
                style="display: flex; justify-content: flex-end"
                *ngIf="totalRecords > 10"
              >
                <pagination-controls
                  (pageChange)="pagination($event)"
                ></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<footer>
  <div class="container-fluid">
    <p class="copyright">
      REH ICO Crypto © 2023 All Rights Reserved.
      <strong class="theme-text-color"></strong>
    </p>
  </div>
</footer>

<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
  <div class="modal-dialog max-WT-500">
    <form class="change_password">
      <div class="modal-content">
        <div>
          <div class="modal-body">
            <h3 style="text-align: center">Delete Coin</h3>
            <hr />
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              style="margin-top: -85px"
            >
              &times;
            </button>
            <div
              class="text-center modal_flax_height d-flex align-items-center justify-content-center"
            >
              <div class="w-100">
                <p style="font-size: 25px">
                  Are you sure you want to delete this coin?
                </p>

                <div>
                  <button
                    type="submit"
                    class="btn btn-info mr-2"
                    (click)="deleteCoin()"
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="blockModal">
  <div class="modal-dialog max-WT-500">
    <form class="change_password">
      <div class="modal-content">
        <div>
          <div class="modal-body">
            <h3 style="text-align: center">Block Coin</h3>
            <hr />
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              style="margin-top: -85px"
            >
              &times;
            </button>
            <div
              class="text-center modal_flax_height d-flex align-items-center justify-content-center"
            >
              <div class="w-100">
                <p style="font-size: 25px">
                  Are you sure you want to block this coin?
                </p>

                <div>
                  <button
                    type="submit"
                    class="btn btn-info mr-2"
                    (click)="blockCoin()"
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal fade global-modal reset-modal" id="activeModal">
  <div class="modal-dialog max-WT-500">
    <form class="change_password">
      <div class="modal-content">
        <div>
          <div class="modal-body">
            <h3 style="text-align: center">Active Coin</h3>
            <hr />
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              style="margin-top: -85px"
            >
              &times;
            </button>
            <div
              class="text-center modal_flax_height d-flex align-items-center justify-content-center"
            >
              <div class="w-100">
                <p style="font-size: 25px">
                  Are you sure you want to active this coin?
                </p>

                <div>
                  <button
                    type="submit"
                    class="btn btn-info mr-2"
                    (click)="activeCoin()"
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
