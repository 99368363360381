<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title" style="color:#fff">{{userId ? 'Edit User Details' : 'Add New User'}}

            </h1>

        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="order-view mt30  mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class=" input-style">
                        <form [formGroup]="addFaqForm">
                            <div class="form-group row ">

                                <div class="col-md-6 col-sm-12">
                                    <label class="col-md-4" class="labelBox">First Name:

                                    </label>
                                    <input type="text" class="form-control " formControlName="firstName"
                                        (keypress)="service.preventSpace($event)" />

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('firstName').hasError('required') && (addFaqForm.get('firstName').dirty || addFaqForm.get('firstName').touched )"
                                            class="error" padding>*First Name is required</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('firstName').hasError('pattern') && (addFaqForm.get('firstName').dirty || addFaqForm.get('firstName').touched )"
                                            class="error" padding>*Please enter valid first name</p>
                                    </span>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <label class="col-md-4" class="labelBox"> Last Name:

                                    </label>
                                    <input type="text" class="form-control " formControlName="lastName"
                                        (keypress)="service.preventSpace($event)" />

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('lastName').hasError('required') && (addFaqForm.get('lastName').dirty || addFaqForm.get('lastName').touched )"
                                            class="error" padding>*Last Name is required</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('lastName').hasError('pattern') && (addFaqForm.get('lastName').dirty || addFaqForm.get('lastName').touched )"
                                            class="error" padding>*Please enter valid last name</p>
                                    </span>
                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox"> Phone Number:



                                    </label>
                                    <input type="text" class="form-control " formControlName="phoneNumber"
                                        (keypress)="service.preventSpace($event)" />

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('phoneNumber').hasError('required') && (addFaqForm.get('phoneNumber').dirty || addFaqForm.get('phoneNumber').touched )"
                                            class="error" padding>*Phone Number is required</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('phoneNumber').hasError('pattern') && (addFaqForm.get('phoneNumber').dirty || addFaqForm.get('phoneNumber').touched )"
                                            class="error" padding>*Please enter valid phone number</p>
                                    </span>
                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox"> Email ID:



                                    </label>
                                    <input type="text" class="form-control " formControlName="emailId"
                                        (keypress)="service.preventSpace($event)" [readonly]="userId"
                                        style="text-transform:lowercase ;" />

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('emailId').hasError('required') && (addFaqForm.get('emailId').dirty || addFaqForm.get('emailId').touched )"
                                            class="error" padding>*Email ID is required</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('emailId').hasError('pattern') && (addFaqForm.get('emailId').dirty || addFaqForm.get('emailId').touched )"
                                            class="error" padding>*Please enter valid email id</p>
                                    </span>
                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox"> Country:



                                    </label>
                                    <select class="form-control " formControlName="country">
                                        <option value="">Select Country</option>
                                        <option *ngFor="let item of countryList" [value]="item?.name">{{item.name}}
                                        </option>

                                    </select>
                                    <!-- <input type="text"  (keypress)="service.preventSpace($event)" /> -->

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('country').hasError('required') && (addFaqForm.get('country').dirty || addFaqForm.get('country').touched )"
                                            class="error" padding>*Country is required</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('country').hasError('pattern') && (addFaqForm.get('country').dirty || addFaqForm.get('country').touched )"
                                            class="error" padding>*Please enter valid country</p>
                                    </span>
                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox"> Postal Code:



                                    </label>
                                    <input type="text" class="form-control " formControlName="postalCode"
                                        (keypress)="service.preventSpace($event)" />

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('postalCode').hasError('required') && (addFaqForm.get('postalCode').dirty || addFaqForm.get('postalCode').touched )"
                                            class="error" padding>*Postal Code is required</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('postalCode').hasError('pattern') && (addFaqForm.get('postalCode').dirty || addFaqForm.get('postalCode').touched )"
                                            class="error" padding>*Please enter valid postal code</p>
                                    </span>
                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox"> State;
                                    </label>
                                    <input type="text" class="form-control " formControlName="state"
                                        (keypress)="service.preventSpace($event)" />

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('state').hasError('required') && (addFaqForm.get('state').dirty || addFaqForm.get('state').touched )"
                                            class="error" padding>*State is required</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('state').hasError('pattern') && (addFaqForm.get('state').dirty || addFaqForm.get('state').touched )"
                                            class="error" padding>*Please enter valid state</p>
                                    </span>
                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox"> Address:


                                    </label>
                                    <input type="text" class="form-control " formControlName="address"
                                        (keypress)="service.preventSpace($event)" />

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('address').hasError('required') && (addFaqForm.get('address').dirty || addFaqForm.get('address').touched )"
                                            class="error" padding>*Address is required</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('address').hasError('pattern') && (addFaqForm.get('address').dirty || addFaqForm.get('address').touched )"
                                            class="error" padding>*Please enter valid address</p>
                                    </span>
                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox"> Date of Birth:


                                    </label>
                                    <input type="date" class="form-control" max="2000-12-31" formControlName="dob"
                                        (keypress)="service.preventSpace($event)" />

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('dob').hasError('required') && (addFaqForm.get('dob').dirty || addFaqForm.get('dob').touched )"
                                            class="error" padding>*Date of Birth is required</p>
                                    </span>
                                    <!-- <span class="error">
                                        <p *ngIf="addFaqForm.get('dob').hasError('pattern') && (addFaqForm.get('dob').dirty || addFaqForm.get('dob').touched )"
                                            class="error" padding>*Please enter valid date of birth</p>
                                    </span> -->
                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox"> IBI Name


                                    </label>
                                    <input type="text" class="form-control " formControlName="ibiName"
                                        (keypress)="service.preventSpace($event)" />

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('ibiName').hasError('required') && (addFaqForm.get('ibiName').dirty || addFaqForm.get('ibiName').touched )"
                                            class="error" padding>*IBI Name is required</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('ibiName').hasError('pattern') && (addFaqForm.get('ibiName').dirty || addFaqForm.get('ibiName').touched )"
                                            class="error" padding>*Please enter valid IBI name</p>
                                    </span>
                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox"> IBI Id


                                    </label>
                                    <input type="text" class="form-control " formControlName="ibiId"
                                        (keypress)="service.preventSpace($event)" />

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('ibiId').hasError('required') && (addFaqForm.get('ibiId').dirty || addFaqForm.get('ibiId').touched )"
                                            class="error" padding>*IBI Id is required</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('ibiId').hasError('pattern') && (addFaqForm.get('ibiId').dirty || addFaqForm.get('ibiId').touched )"
                                            class="error" padding>*Please enter valid IBI id</p>
                                    </span>
                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox"> 2 FA</label>
                                    <!-- <input type="text" class="form-control " formControlName="ibiId"
                                        (keypress)="service.preventSpace($event)" /> -->
                                    <select class="form-control " formControlName="twofa" name="" id="">
                                        <option value="NONE">NONE</option>
                                        <option disabled value="GOOGLE">GOOGLE</option>
                                    </select>
                                    
                                </div>

                            </div>
                            <div class="text-center mt40" style="margin-right: -14%;">
                                <button class="btn btn-large  max-WT-200 font-100 btn-green mr-5" *ngIf="!userId"
                                    (click)="addUserData()" [disabled]="addFaqForm.invalid">Add</button>

                                <button class="btn btn-large  max-WT-200 font-100 btn-grey mr-5" *ngIf="userId && (userDetail?.rewarded || userDetail?.role === 'ADMIN')"
                                    (click)="openTransferModal()">Transfer Rewards</button>

                                <button class="btn btn-large  max-WT-200 font-100 btn-green mr-5" *ngIf="userId"
                                    (click)="updateUserDataModal()">Edit & Save</button>
                                <a class="btn btn-large  max-WT-200 font-100 btn-grey ml8"
                                    [routerLink]="['/user-management']">Cancel</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>

<footer>
    <div class="container-fluid">
        <p class="copyright">REH ICO Crypto © 2023 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>


<div class="modal fade global-modal reset-modal" id="editUserDetails">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Edit User Details </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <div class="form-group row mb-0 mt-0 ">
                                    <label class="col-md-4" style="color: #fff;">First Name :</label>
                                    <div class="col-md-8 col-sm-12">
                                        <p>{{showData?.firstName}}</p>
                                    </div>
                                </div>
                                <div class="form-group row mb-0 mt-0 ">
                                    <label class="col-md-4" style="color: #fff;">Last Name :</label>
                                    <div class="col-md-8 col-sm-12">
                                        <p>{{showData?.lastName}}</p>
                                    </div>
                                </div>
                                <div class="form-group row mb-0 mt-0 ">
                                    <label class="col-md-4" style="color: #fff;">Phone Number :</label>
                                    <div class="col-md-8 col-sm-12">
                                        <p>{{showData?.phoneNo}}</p>
                                    </div>
                                </div>
                                <div class="form-group row mb-0 mt-0 ">
                                    <label class="col-md-4" style="color: #fff;">Country :</label>
                                    <div class="col-md-8 col-sm-12">
                                        <p>{{showData?.country}}</p>
                                    </div>
                                </div>
                                <div class="form-group row mb-0 mt-0 ">
                                    <label class="col-md-4" style="color: #fff;">State :</label>
                                    <div class="col-md-8 col-sm-12">
                                        <p>{{showData?.state}}</p>
                                    </div>
                                </div>
                                <div class="form-group row mb-0 mt-0 ">
                                    <label class="col-md-4" style="color: #fff;">Address :</label>
                                    <div class="col-md-8 col-sm-12">
                                        <p>{{showData?.address}}</p>
                                    </div>
                                </div>
                                <div class="form-group row mb-0 mt-0 ">
                                    <label class="col-md-4" style="color: #fff;">Date of Birth :</label>
                                    <div class="col-md-8 col-sm-12">
                                        <p>{{showData?.dob | date : 'dd-MM-yyyy'}}</p>
                                    </div>
                                </div>
                                <div class="form-group row mb-0 mt-0 ">
                                    <label class="col-md-4" style="color: #fff;">IBI Name :</label>
                                    <div class="col-md-8 col-sm-12">
                                        <p>{{showData?.ibiName}}</p>
                                    </div>
                                </div>
                                <div class="form-group row mb-0 mt-0 ">
                                    <label class="col-md-4" style="color: #fff;">IBI ID :</label>
                                    <div class="col-md-8 col-sm-12">
                                        <p>{{showData?.ibiId}}</p>
                                    </div>
                                </div>
                                <div class="form-group row mb-0 mt-0 ">
                                    <label class="col-md-4" style="color: #fff;">2FA :</label>
                                    <div class="col-md-8 col-sm-12">
                                        <p>{{showData?.twoFaType}}</p>
                                    </div>
                                </div>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2"
                                        (click)="updateUserData()">Confirm</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>


<div class="modal fade global-modal reset-modal" id="transferAmount">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3  style="text-align: center; ">Transfer Rewards Amount </h3>
                        <hr>
                        <!-- <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button> -->
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                               <form [formGroup]="loylity">
                                <div class="form-group row mt-2 mb-5">
                                    <div class="col-md-12 col-sm-12">
                                      <input type="text" class="form-control" formControlName="loyalityAmount" placeholder="Enter Transfer Amount"
                                        (keypress)="service.preventSpace($event)" />
                                    </div>
                    
                                  </div>
                               </form>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2"
                                        (click)="transferLoyltyMoney()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>