import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { MainService } from "src/app/provider/main.service";
declare var $;
@Component({
  selector: "app-ico-sales-listing",
  templateUrl: "./ico-sales-listing.component.html",
  styleUrls: ["./ico-sales-listing.component.css"],
})
export class IcoSalesListingComponent implements OnInit {
  userForm: FormGroup;
  listing: any = [];
  id: number;
  priceAuto: any;
  deleted: any;
  totalRecords: any;
  pageNumber: number = 1;
  itemsPerPage: number = 10;
  userid: any;
  userStatus: any;
  isAssending: boolean = true;
  today: any = new Date().toISOString().slice(0, 16);
  isSearched: boolean = false;
  count: any;
  permissionArr: any = {};
  fieroPrice: any;
  constructor(
    private router: Router,
    public service: MainService,
    private httpClient: HttpClient
  ) {
    this.service.permission.subscribe((res) => {
      if (Object.keys(res).length) {
        this.permissionArr = res;
        console.log(res);
      }
    });
  }

  ngOnInit() {
    this.userForm = new FormGroup({
      // startdate: new FormControl(""),
      // enddate: new FormControl(""),
      searchText: new FormControl(""),
      country: new FormControl(""),
      userStatus: new FormControl(""),
    });
    this.countryList();
    this.getlist();
    this.getPriceAuto();
  }

  products: any = [];
  markStatus: any;
  countryList() {
    var url = "wallet/coin/get-coin-details?coinName=FIERO";
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res.status == 200) {
          this.markStatus = res.data;
        }
      },
      (err) => {
        if (err.status == 401) {
          this.service.hideSpinner();
          this.service.toasterErr("Unauthorized access");
        } else {
          this.service.toasterErr("something went wrong");
        }
      }
    );
  }

  getlist() {
    // let startdate = Date.parse(this.userForm.value.startdate);
    // let enddate = Date.parse(this.userForm.value.enddate);
    var search = this.userForm.value.searchText;
    let countryDa = this.userForm.value.country;
    let url = `wallet/admin/search-filter?page=${
      this.pageNumber - 1
    }&pageSize=${this.itemsPerPage}${
      this.userForm.value.searchText ? "&search=" + search : ""
    }${
      this.userForm.value.userStatus
        ? "&icoStatus=" + this.userForm.value.userStatus
        : ""
    }`;
    // let url = `wallet/admin/search-filter?page=${this.pageNumber - 1}&pageSize=${this.itemsPerPage}${this.userForm.value.searchText ? "&search=" + search : ""}${this.userForm.value.startdate ? "&fromDate=" + startdate : ""}
    // ${this.userForm.value.enddate ? "&toDate=" + enddate : ""} ${this.userForm.value.userStatus ? "&icoStatus=" + this.userForm.value.userStatus : ""}`;

    // this.service.showSpinner();

    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.listing = res.data.list;
          // let temp = res.data.list.find((ele) => {
          //   return ele.icoStatus == "ENABLE";
          // });
          // setTimeout(() => {
          //   if (this.markStatus.markStatus == "ENABLE") {
          //     if (this.markStatus.markType == "DOWN") {
          //       this.fieroPrice = temp.price;
          //     } else if (this.markStatus.markType == "UP") {
          //       this.fieroPrice = temp.price;
          //     } else if (this.markStatus.markType == "NOMARK") {
          //       this.fieroPrice = temp.priceWithoutMark;
          //     }
          //   } else {
          //     this.fieroPrice = temp.fieroUsdPrice;
          //   }
          // }, 500);

          this.count = res.data.size;
        } else {
          this.listing = [];
          this.service.hideSpinner();
        }
      },
      (err: any) => {
        this.listing = [];
        this.service.hideSpinner();
        this.service.toasterErr(err.error.message);
      }
    );
  }
  goToAddIco() {
    this.router.navigate(["/addICO"]);
  }
  pagination(page) {
    this.pageNumber = page;
    this.getlist();
  }

  reset() {
    if (
      // this.userForm.value.startdate ||
      // this.userForm.value.enddate ||
      this.userForm.value.searchText ||
      this.userForm.value.country ||
      this.userForm.value.userStatus
    ) {
      this.userForm.reset({
        startdate: "",
        userStatus: "",
        enddate: "",
        searchText: "",
        country: "",
      });
      this.getlist();
    }
  }

  //========modal=======//
  delete(id: number) {
    this.id = id;
    $("#deleteModal").modal("show");
  }
  deleteUser() {
    this.service.delete(`wallet/admin/delete-ico?icoId=${this.id}`).subscribe(
      (res: any) => {
        this.service.showSpinner();
        if ((res.status = 200)) {
          $("#deleteModal").modal("hide");
          this.getlist();
          this.service.hideSpinner();
          this.service.toasterSucc(res.message);
        } else {
          this.service.hideSpinner();
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr(err.message);
      }
    );
  }

  block(status, id) {
    this.userStatus = status;
    this.userid = id;
    $("#block").modal("show");
  }
  blockUser() {
    this.service.showSpinner();
    var url =
      "wallet/admin/disable-enable-ico?icoId=" +
      this.userid +
      "&icoStatus=" +
      this.userStatus;
    this.service.showSpinner();
    this.service.post(url, {}).subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res.status == 200) {
          this.service.toasterSucc(res.message);
          this.getlist();
          $("#block").modal("hide");
        } else {
          this.service.hideSpinner();
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr(err.message);
      }
    );
  }
  // user details navigation
  userDetails(id) {
    this.router.navigate(["/viewICO"], { queryParams: { id: id } });
  }

  //export User
  async exportAsXLSX() {
    let temp = [];
    let url = `wallet/admin/search-filter?page=${
      this.pageNumber - 1
    }&pageSize=${this.count}`;
    try {
      let res: any = await this.service.get(url).toPromise();
      this.service.hideSpinner();
      if (res.status == 200) {
        temp = res.data.list;
        let dataArr = [];
        temp.forEach((element, ind) => {
          dataArr.push({
            "S no": ind + 1,
            "ICO Name": element.planName ? element.planName : "",
            "Price (USD)": element.price ? element.price : "N/A",
            "Token Quantity": element.totalQuantity
              ? element.totalQuantity
              : "N/A",
            "Referral Percentage": element.referalPercetage
              ? element.referalPercetage
              : "N/A",
            Status: element.icoStatus ? element.icoStatus : "N/A",
          });
        });
        this.service.exportAsExcelFile(dataArr, "Token Trances list");
      }
    } catch (error) {
      this.service.hideSpinner();
      this.service.toasterErr(error.error.message);
    }
  }

  sortAgent(key) {
    if (this.isAssending) {
      this.listing = this.listing.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.listing = this.listing.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
  OpenModal() {
    $("#priceAutomation").modal("show");
  }
  automatedPrice: any;
  getPriceAuto() {
    let url = `wallet/get-price`;
    this.service.get(url).subscribe(
      (res: any) => {
        this.service.showSpinner();
        if ((res.status = 200)) {
          this.automatedPrice = res.data;
          this.priceAuto = this.automatedPrice.increasePrice;
          this.service.hideSpinner();
        } else {
          this.service.hideSpinner();
        }
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }
  setPriceAuto() {
    let url = `wallet/update-price-status?id=${this.automatedPrice.id}&price=${
      this.priceAuto
    }&status=${this.automatedPrice?.status ? false : true}`;
    this.service.put(url, {}).subscribe(
      (res: any) => {
        this.service.showSpinner();
        if ((res.status = 200)) {
          this.getPriceAuto();
          $("#priceAutomation").modal("hide");
          this.service.hideSpinner();
        } else {
          this.service.hideSpinner();
        }
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }
}
