<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">TRX Management</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="">
                        <ul class="dash_list d-flex w-100 flex-wrap text-center ">
                            <li class="d-inline-flex align-items-center justify-content-center bg-blue"
                                style="cursor: pointer;">
                                <div class="w-100">
                                    <!-- <div class="dash_icn">
                                        <i class="fas fa-users fa-3x" aria-hidden="true"></i>
                                    </div> -->
                                    <h2>Total Generate Address</h2>
                                    <h4>{{totalCountdata?.totalCount}}</h4>
                                </div>
                            </li>
                            <li class="d-inline-flex align-items-center justify-content-center bg-green"
                                style="cursor: pointer;">
                                <div class="w-100">
                                    <!-- <div class="dash_icn">
                                        <i class="fas fa-user-ninja fa-3x" aria-hidden="true"></i>
                                    </div> -->
                                    <h2>Used Address</h2>
                                    <h4>{{totalCountdata?.usedCount}}</h4>
                                </div>
                            </li>
                            <li class="d-inline-flex align-items-center justify-content-center bg-red"
                                style="cursor: pointer;">
                                <div class="w-100">
                                    <!-- <div class="dash_icn">
                                        <i class="fas fa-user-slash fa-3x" aria-hidden="true"></i>
                                    </div> -->
                                    <h2>Pending Address</h2>
                                    <h4>{{totalCountdata?.remainingCount}}</h4>
                                </div>
                            </li>

                        </ul>
                    </div>
                    <div class="tab-pane1">
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <!-- <th scope="col">S.No</th> -->
                                        <!-- <th scope="col">Value</th> -->
                                        <th style="text-align: center !important;">Generate New Address</th>
                                        <!-- <th scope="col">Coin name</th>
                                        <th scope="col">Action</th> -->
                                        <th></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <!-- <td>{{i+1}}</td> -->
                                        <!-- <td *ngIf="!cointAmount[i]">{{item.withdrawalAmount}}</td> -->
                                        <td>
                                            <input style="text-align: left;margin: auto;" type="number"
                                                placeholder="Enter Number Of Address" [(ngModel)]="address"
                                                (keypress)="service.preventSpace($event);" class="form-control">
                                        </td>
                                        <!-- <td><button type="submit" class="btn  btn-theme cus-mr"  style="width: 88%;" routerLink="/update-withdrawl-amount/{{item.coinShortName}}">Edit</button></td> -->
                                        <td>
                                            <button type="submit" (click)="getCount()"
                                                class="btn  btn-theme cus-mr">Submit</button>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end" >
                            <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                        </div>
                        -->

                    </div>
                </div>
            </div>
        </div>
        <!-- </div>
</main> -->


        <!-- <footer>
    <div class="container-fluid">
        <p class="copyright">REH ICO Crypto © 2023 All Rights Reserved.<strong
                class="theme-text-color"></strong>
        </p>
    </div>
</footer> -->