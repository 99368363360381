<div class="login-wrapper" style="background-color: #000; position: relative; overflow: hidden;">
    <div class="orangeshadeBox1"></div>
    <div class="purpleBox1"></div>
    <div class="login-wrapper ">
        <div class="container-common">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <form class="login_box_outer" [formGroup]="forgotPassword">
                        <div class="login-box max-WT-520" style="    opacity: 0.8;
                        background: rgba(0,0,1,0.8);">
                            <div class="login-right-block">
                                <div class="text-center">
                                    <img src="assets\img\logo.png" alt="crypto-logo" class="data-img"
                                        style="width: auto;max-width: 15vh;">

                                </div>
                                <div class="login-heading">
                                    <h4>FORGOT PASSWORD?</h4>
                                </div>
                                <div class="login-box-body">
                                    <p class="common-paragrph text-center">Please provide your registered email address
                                        so that we can send you instructions on how to reset your password</p>
                                    <div class="form-group"> <input type="email" formControlName="email"
                                            class="form-control" placeholder="Email Address" required />
                                        <span class="error">
                                            <p *ngIf="forgotPassword.get('email').hasError('required') && forgotPassword.get('email').touched"
                                                class="error" padding>*Please enter email</p>
                                        </span>
                                        <span class="error">
                                            <p *ngIf="forgotPassword.get('email').hasError('pattern') && forgotPassword.get('email').dirty"
                                                class="error" padding>*Please enter valid email</p>
                                        </span>
                                    </div>
                                    <div class="form-group text-center"> <button type="submit"
                                            class="btn btn-login btn-small1 width100 font-100 mr-2" (click)="onForgot()"
                                            style="    border-radius: 50px;     background-color: #302f35;
                                            padding: 10px 35px;
                                            font-size: 14px;
                                        " [disabled]="forgotPassword.invalid">SUBMIT</button>
                                        <button type="submit" class="btn btn-login btn-small width100 font-100"
                                            [routerLink]="['/login']" style=" padding: 10px 43px;">BACK</button>
                                    </div>
                                </div> <a (click)="onResend()" *ngIf="resend" class="text-center mt20 reset-link"
                                    style="color: white;">Resend Link ?</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>