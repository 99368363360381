import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-subadmin-activity',
  templateUrl: './subadmin-activity.component.html',
  styleUrls: ['./subadmin-activity.component.css']
})
export class SubadminActivityComponent implements OnInit {

  userForm: FormGroup;
  ticketList: any = [];
  page: number = 1;
  limit: number = 10;
  itemsPerPage: number = 10;

  totalRecords: any;
  pageNumber: number = 1;
  arr: any = ['RESOLVED', 'INPROGRESS', 'CLOSED', 'CANCELLED']


  permArray = [
    {
      id: 1,
      keyName: 'USER_MANAGEMENT',
      keyValue: 'User Management'
    },
    {
      id: 2,
      keyName: 'SUBADMIN_MANAGEMENT',
      keyValue: 'Sub Admin Management'
    },
    {
      id: 3,
      keyName: 'TOKEN_TRANS_MANAGEMENT',
      keyValue: 'Sale Trances Management'
    },
    {
      id: 4,
      keyName: 'TRANSACTION_MANAGEMENT',
      keyValue: 'Transactions Management'
    },
    {
      id: 5,
      keyName: 'PAYMENT_MANAGEMENT',
      keyValue: 'Payment Management',
    },
    {
      id: 6,
      keyName: 'KYC_MANAGEMENT',
      keyValue: 'KYC Management'
    },
    {
      id: 7,
      keyName: 'QUERY_MANAGEMENT',
      keyValue: 'Queries Management'
    },
    {
      id: 8,
      keyName: 'STATIC_CONTENT_MANGEMENT',
      keyValue: 'Static Content Management'
    },
    {
      id: 9,
      keyName: 'TOKEN_MANAGEMENT',
      keyValue: 'Token Management'
    },

    {
      id: 10,
      keyName: 'FIERES_TUTORIAL',
      keyValue: 'Fieres Academy Management'
    },
    {
      id: 11,
      keyName: 'CLAIM_TOKEN_MANAGEMENT',
      keyValue: 'Claim Token Management',
      access: ['CREATE', 'UPDATE', 'READ', 'DELETE']
    },
    {
      id: 12,
      keyName: 'VESTING_MANAGEMENT',
      keyValue: 'Vesting Management',
      access: ['CREATE', 'UPDATE', 'READ', 'DELETE']
    }
  ]
  updatestatus: string;
  isAssending: boolean = true;
  today: any = new Date().toISOString().slice(0, 16)
  permissionArr: any = {}
  constructor(
    private router: Router, public service: MainService
  ) {
    this.service.permission.subscribe((res) => {
      if (Object.keys(res).length) {
        this.permissionArr = res
        console.log(res);

      }
    })
  }

  ngOnInit() {
    this.userForm = new FormGroup({
      'startdate': new FormControl('', Validators.required),
      'enddate': new FormControl('', Validators.required),
      'searchText': new FormControl(''),
      'status': new FormControl(''),

    })
    this.getTicketList();
  }
  changeMessage(value) {
    let newValue = value.replaceAll('_', ' ')
    return newValue
  }
  getTicketList() {

    let startdate = Date.parse(this.userForm.value.startdate)
    let enddate = Date.parse(this.userForm.value.enddate)
    let search = this.userForm.controls.searchText.value;
    this.service.showSpinner();
    let url = `account/admin/user-management/get-subadmin-activity-list?page=${this.pageNumber - 1}&pageSize=${this.limit}${startdate ? '&fromDate=' + startdate : ''}${enddate ? '&toDate=' + enddate : ''}${search ? '&email=' + search : ''}`
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner()
      if(res.status == 200){
      this.ticketList = res.data.list
      this.totalRecords = res.data.count;
      }else{
      this.ticketList = []
      }
    },(err)=>{
      this.service.hideSpinner()
      this.ticketList = []
    })
    
  }
  filterTicketList() {
    let startdate = Date.parse(this.userForm.value.startdate)
    let enddate = Date.parse(this.userForm.value.enddate)
    let search = this.userForm.controls.searchText.value;
    let status = this.userForm.controls.status.value;
    if (startdate && enddate && search && status) {
      var request = {
        'fromDate': startdate,
        'search': search,
        'ticketStatus': status,
        'toDate': enddate,
        'page': this.pageNumber - 1,
        'pageSize': this.limit
      }
    }

    else if (startdate && enddate) {
      var request1 = {
        'fromDate': startdate,
        'toDate': enddate,
        'page': this.pageNumber - 1,
        'pageSize': this.limit

      }
    }
    else if (search) {
      var request2 = {
        'search': search,
        'page': this.pageNumber - 1,
        'pageSize': this.limit
      }

    }

    else if (status) {
      var request3 = {
        'ticketStatus': this.userForm.controls.status.value,
        'page': this.pageNumber - 1,
        'pageSize': this.limit
      }


    }
    console.log('j', request, request1, request2, request3);
    this.service.showSpinner()
    this.service.post(`static/search-and-filter-ticket-list`, request || request1 || request2 || request3).subscribe((res: any) => {
      this.service.hideSpinner()

      if(res.status == 200){
    this.ticketList = res.data.list
    console.log('hh', this.ticketList);

    this.totalRecords = res.data.count;
   }else{
    this.ticketList = []
    }
  },(err)=>{
    this.service.hideSpinner()
    this.ticketList = []
  })
    console.log('hitted');
    console.log(status);
  }
  isSearched: boolean = false;

  pagination(page) {
    // this.totalRecords = [];
    console.log("jh", page);
    this.pageNumber = page;
    this.page = page
    console.log("jh", this.pageNumber);
    if (this.isSearched) {


      this.filterTicketList();
    } else {
      this.service.hideSpinner();
      this.getTicketList();
    }
  }
  updateTicketStatus(e, id: number) {
    let status = e.target.value
    id = id
    let data = {}
    this.service.post(`static/change-ticket-status?ticketId=${id}&ticketStatus=${status}`, data).subscribe((res: any) => {
      this.service.showSpinner()
      if (res.status = 200) {
        this.service.hideSpinner()
        this.service.toasterSucc(res.message)
      }
    })
  }

  // reset
  // reset(){
  //   this.userForm.reset()
  //   this.getTicketList(this.page)
  // }
  reset() {
    if (
      this.userForm.value.startdate ||
      this.userForm.value.enddate ||
      this.userForm.value.searchText ||
      this.userForm.value.status
    ) {
      this.userForm.reset({
        startdate: "",
        // userStatus: "",
        enddate: "",
        searchText: "",
        status: "",
      });
      this.getTicketList();
    }
  }
  replyTicket() {
    this.router.navigate(['/reply-ticket'])
  }
  viewTicket() {
    this.router.navigate(['/view-ticket'])
  }

  //export User
 async exportAsXLSX() {
  let temp = [];
    let startdate = Date.parse(this.userForm.value.startdate)
    let enddate = Date.parse(this.userForm.value.enddate)
    let search = this.userForm.controls.searchText.value;
    this.service.showSpinner();
    let url = `account/admin/user-management/get-subadmin-activity-list?page=${this.pageNumber - 1}&pageSize=${this.totalRecords}${startdate ? '&fromDate=' + startdate : ''}${enddate ? '&toDate=' + enddate : ''}${search ? '&email=' + search : ''}`
    // this.service.get(url).subscribe((res: any) => {
    //   this.ticketList = res.data.list
    //   this.totalRecords = res.data.count;
    // })
    // this.service.hideSpinner()
    try {
      let res: any =  await this.service.get(url).toPromise();
    this.service.hideSpinner()
    if(res.status == 200){
      temp = res.data.list
    }
    } catch (error) {
      this.service.hideSpinner();
      this.service.toasterErr(error.error.message);
    }
    let dataArr = [];
    temp.forEach((element, ind) => {
      dataArr.push({
        "S.No": ind + 1,
        "Sub Admin Email": element.activityBy ? element.activityBy : '',
        "Management Name": this.changeMessage(element.management) ? this.changeMessage(element.management) : '',
        "Activity": this.changeMessage(element.activityOf) ? this.changeMessage(element.activityOf) : 'N/A',
        "Message": element.email + '' + element.lastName ? element.email + " " + (element.lastName ? element.lastName : '') : '',
        "Date & Time": element.createdAt ? element.createdAt.slice(0, 10) : 'N/A',
      })
    })

    this.service.exportAsExcelFile(dataArr, 'Sub Admin Activity Management');
  }
  // sorting
  sortAgent(key) {
    if (this.isAssending) {
      this.ticketList = this.ticketList.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.ticketList = this.ticketList.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }

}
