<div class="wrapper">
  <main class="middle-content">
    <div class="page_title_block" style="margin-top: 5em !important">
      <h1 class="page_title">Whitepaper / Edit</h1>
    </div>
    <div class="content-section">
      <div class="order-view max-WT-900 mrgn-0-auto setting-page">
        <div class="mb40">
          <div class="add-store-block input-style mb20 mt20 view-label-align">
            <form [formGroup]="addSwatchesForm" #f="ngForm">
              <div class="form-group row align-items-baseline">
                <label class="col-md-5 textalignright"
                  >Whitepaper Language <span>:</span></label
                >
                <div class="col-md-7 textaligncenter">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Whitepaper Language"
                      formControlName="language"
                      (keypress)="service.preventSpace($event)"
                      maxlength="60"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group row align-items-baseline">
                <label class="col-md-5 textalignright docccs"
                  >Whitepaper Document <span>:</span></label
                >
                <div class="col-md-7 textaligncenter">
                  <div class="form-group d-flex">
                    <input
                      *ngIf="!imageUrl"
                      type="file"
                      formControlName="image"
                      name="image"
                      accept=".pdf"
                      id="image"
                      (change)="uploadImg($event)"
                    />
                    <!-- <button *ngIf="imageUrl"   (click)="remove(1)">remove</button> -->
                    <!-- <img *ngIf="imageUrl" [src]="imageUrl" class="mt-1" alt=""> -->
                    <!-- <object *ngIf="imageUrl"
                                            [data]="sanitizer?.bypassSecurityTrustResourceUrl(imageUrl)"
                                            type="application/pdf" width="100%" height="200px">
                                            <iframe [src]="sanitizer?.bypassSecurityTrustResourceUrl(imageUrl)"
                                                width="100%" height="100%" style="border: none;">
                                                <p>
                                                    Your browser does not support PDFs.
                                                    <a [href]="sanitizer?.bypassSecurityTrustResourceUrl(imageUrl)">Download
                                                        the PDF</a>
                                                    .
                                                </p>
                                            </iframe>
                                        </object> -->
                    <img
                      src="assets/img/pdf-icon.png"
                      class="pdf-size"
                      (click)="downloadPdf(imageUrl)"
                      alt=""
                    />
                    <i
                      *ngIf="imageUrl"
                      (click)="remove(1)"
                      class="fa fa-trash new-delete"
                      aria-hidden="true"
                    ></i>
                    <!-- <button *ngIf="imageUrl" class="view-img" [disabled]="!imageUrl"><a
                                                [href]="imageUrl" target="_blank"><i class="fa fa-eye"
                                                    aria-hidden="true"></i></a></button> -->
                  </div>
                </div>
              </div>
              <div class="form-group row align-items-baseline">
                <label class="col-md-5 textalignright docccs"
                  >Whitepaper Image <span>:</span></label
                >
                <div class="col-md-7 textaligncenter">
                  <div class="form-group d-flex">
                    <input
                      *ngIf="!urlPdf"
                      type="file"
                      formControlName="image"
                      name="image"
                      accept="image/png, image/jpeg, image/jpg, image/svg"
                      id="image"
                      (change)="uploadPdf($event)"
                    />
                    <!-- <button *ngIf="imageUrl"   (click)="remove(1)">remove</button> -->
                    <!-- <img *ngIf="imageUrl" [src]="imageUrl" class="mt-1" alt=""> -->
                    <!-- <object *ngIf="imageUrl"
                                            [data]="sanitizer?.bypassSecurityTrustResourceUrl(imageUrl)"
                                            type="application/pdf" width="100%" height="200px">
                                            <iframe [src]="sanitizer?.bypassSecurityTrustResourceUrl(imageUrl)"
                                                width="100%" height="100%" style="border: none;">
                                                <p>
                                                    Your browser does not support PDFs.
                                                    <a [href]="sanitizer?.bypassSecurityTrustResourceUrl(imageUrl)">Download
                                                        the PDF</a>
                                                    .
                                                </p>
                                            </iframe>
                                        </object> -->
                    <img [src]="urlPdf" alt="" />

                    <i
                      *ngIf="imageUrl"
                      (click)="removeimage(1)"
                      class="fa fa-trash new-delete"
                      aria-hidden="true"
                    ></i>
                    <!-- <button *ngIf="imageUrl" class="view-img" [disabled]="!imageUrl"><a
                                                [href]="imageUrl" target="_blank"><i class="fa fa-eye"
                                                    aria-hidden="true"></i></a></button> -->
                  </div>
                </div>
              </div>
              <!-- <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright ">Whitepaper  France <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group d-flex">
                                        <input  *ngIf="!imageUrl1" type="file" formControlName="image" name="image"
                                            accept=".pdf,.doc" id="image"
                                            (change)="uploadImg($event,'FRANCE')" >
                                            <object *ngIf="imageUrl1" [data]="sanitizer?.bypassSecurityTrustResourceUrl(imageUrl1)" type="application/pdf" width="100%" height="200px">
                                                <iframe [src]="sanitizer?.bypassSecurityTrustResourceUrl(imageUrl1)" width="100%" height="100%" style="border: none;">
                                                    <p>
                                                        Your browser does not support PDFs.
                                                        <a [href]="sanitizer?.bypassSecurityTrustResourceUrl(imageUrl)">Download the PDF</a>
                                                        .
                                                    </p>
                                                </iframe>
                                            </object>
                                        <i *ngIf="imageUrl1"   (click)="remove(2)" class="fa fa-trash new-delete" aria-hidden="true"></i>

                                        <button *ngIf="imageUrl1" class="view-img" [disabled]="!imageUrl1"><a [href]="imageUrl1" target="_blank"><i class="fa fa-eye" aria-hidden="true"></i></a></button>
                                    </div>
                                </div>

                            </div> -->
              <!-- <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright ">Whitepaper  Arabic <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group d-flex">
                                        <input *ngIf="!imageUrl2" type="file" formControlName="image" name="image"
                                            accept=".pdf,.doc" id="image"
                                            (change)="uploadImg($event,'ARABIC')" >
                                            <object *ngIf="imageUrl2" [data]="sanitizer?.bypassSecurityTrustResourceUrl(imageUrl2)" type="application/pdf" width="100%" height="200px">
                                                <iframe [src]="sanitizer?.bypassSecurityTrustResourceUrl(imageUrl2)" width="100%" height="100%" style="border: none;">
                                                    <p>
                                                        Your browser does not support PDFs.
                                                        <a [href]="sanitizer?.bypassSecurityTrustResourceUrl(imageUrl)">Download the PDF</a>
                                                        .
                                                    </p>
                                                </iframe>
                                            </object>
                                        <i *ngIf="imageUrl2"   (click)="remove(3)" class="fa fa-trash new-delete" aria-hidden="true"></i>

                                        <button *ngIf="imageUrl2" class="view-img" [disabled]="!imageUrl2"><a [href]="imageUrl2" target="_blank"><i class="fa fa-eye" aria-hidden="true"></i></a></button>
                                    </div>
                                </div>

                            </div> -->

              <!-- <button (click)="addColour()">Add more</button> -->
              <!-- <div class="row">
                                <i aria-hidden="true" class="fa fa-plus icon-add" (click)="addColour()"></i>
                            </div> -->
              <!-- upload image end -->
              <!-- <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright ">Description <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group">
                                        <textarea type="text" rows="7" class="form-control" placeholder="{{validationMessage?.body.descriptionPlaceholder}}"
                                            formControlName="description" 
                                            maxlength="250"></textarea>
                                            <div *ngIf="(description.invalid ) && description.touched || description.dirty">
                                                <small *ngIf="description.errors?.required"
                                                    class="text-danger">{{validationMessage?.skirt.descriptionRequired}} </small>
                                               
                                            </div>
                                    </div>
                                </div>
                            </div> -->
            </form>
          </div>
          <div class="text-center mt40 mb40">
            <button
              class="btn btn-large max-WT-150 btn-theme"
              routerLink="/whitepaper-list"
            >
              Back
            </button>
            <button
              class="btn btn-large max-WT-150 btn-theme ml-3"
              style="
                background: linear-gradient(
                  90deg,
                  #ffc500 0%,
                  #d78a01 50.63%,
                  #ffc500 100%
                ) !important;
              "
              (click)="addWhitePaper()"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>

<footer>
  <div class="container-fluid">
    <p class="copyright">
      REH ICO Crypto © 2023 All Rights Reserved.
      <strong class="theme-text-color"></strong>
    </p>
  </div>
</footer>
