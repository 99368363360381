import { EditUserAdminComponent } from "./pages/edit-user-admin/edit-user-admin.component";
import { IcoSalesListingComponent } from "./pages/ico-sales-listing/ico-sales-listing.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./pages/login/login.component";
import { DashboardComponent } from "./pages/dashboard/dashboard/dashboard.component";
import { UserManagementComponent } from "./pages/dashboard/user-management/user-management.component";
import { KycManagementComponent } from "./pages/dashboard/kyc-management/kyc-management.component";
import { WalletManagementComponent } from "./pages/dashboard/wallet-management/wallet-management.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { MyProfileComponent } from "./pages/dashboard/my-profile/my-profile.component";
import { EditProfileComponent } from "./pages/dashboard/edit-profile/edit-profile.component";
import { TransactionManagementComponent } from "./pages/dashboard/transaction-management/transaction-management.component";
import { ViewTransactionComponent } from "./pages/dashboard/view-transaction/view-transaction.component";
import { HotColdWalletManagementComponent } from "./pages/dashboard/hot-cold-wallet-management/hot-cold-wallet-management.component";
import { StaticContentManagementComponent } from "./pages/dashboard/static-content-management/static-content-management.component";
import { TermsAndConditionsComponent } from "./pages/dashboard/static-content-management/terms-and-conditions/terms-and-conditions.component";
import { PrivacyPolicyComponent } from "./pages/dashboard/static-content-management/privacy-policy/privacy-policy.component";
import { AboutUsComponent } from "./pages/dashboard/static-content-management/about-us/about-us.component";
import { FeeManagementComponent } from "./pages/dashboard/fee-management/fee-management.component";

import { UserDetailsComponent } from "./pages/dashboard/user-details/user-details.component";
import { WalletDetailsComponent } from "./pages/dashboard/wallet-details/wallet-details.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { ProfitLossManagementComponent } from "./pages/dashboard/profit-loss-management/profit-loss-management.component";
import { UserDetailTradingComponent } from "./pages/dashboard/user-detail-trading/user-detail-trading.component";
import { LoginSessionActivityComponent } from "./pages/dashboard/login-session-activity/login-session-activity.component";
import { ViewUserTradingDetailComponent } from "./pages/dashboard/view-user-trading-detail/view-user-trading-detail.component";
import { ViewHotColdWalletManagementComponent } from "./pages/dashboard/view-hot-cold-wallet-management/view-hot-cold-wallet-management.component";
import { TokenManagementComponent } from "./pages/dashboard/token-management/token-management.component";
import { AddTokenComponent } from "./pages/dashboard/add-token/add-token.component";
import { EditTokenComponent } from "./pages/dashboard/edit-token/edit-token.component";
import { ViewTokenComponent } from "./pages/dashboard/view-token/view-token.component";
import { TicketManagementComponent } from "./pages/dashboard/ticket-management/ticket-management.component";
import { ViewTicketComponent } from "./pages/dashboard/view-ticket/view-ticket.component";
import { ReplyTicketComponent } from "./pages/dashboard/reply-ticket/reply-ticket.component";
import { SettingsComponent } from "./pages/setting/settings/settings.component";
import { TakerMakeFeeComponent } from "./pages/setting/taker-make-fee/taker-make-fee.component";
import { ViewLimitComponent } from "./pages/setting/view-limit/view-limit.component";
import { WithdrawlFeeComponent } from "./pages/setting/withdrawl-fee/withdrawl-fee.component";
import { WithdrawlLimitComponent } from "./pages/setting/withdrawl-limit/withdrawl-limit.component";
import { KycDetailComponent } from "./pages/kyc-management/kyc-detail/kyc-detail.component";
import { KycUpdateComponent } from "./pages/kyc-management/kyc-update/kyc-update.component";
import { ManageFeeComponent } from "./pages/fee-management/manage-fee/manage-fee.component";
import { StandardTradingFeeComponent } from "./pages/fee-management/standard-trading-fee/standard-trading-fee.component";
import { MinTradingFeeComponent } from "./pages/fee-management/min-trading-fee/min-trading-fee.component";
import { ChangePasswordComponent } from "./pages/dashboard/change-password/change-password.component";
import { ContentManagementComponent } from "./pages/dashboard/content-management/content-management.component";
import { UserManagementAdminComponent } from "./pages/admin-management/user-management-admin/user-management-admin.component";
import { AdminDetailComponent } from "./pages/admin-management/admin-detail/admin-detail.component";
import { AddAdministerComponent } from "./pages/admin-management/add-administer/add-administer.component";
import { DepositeWalletComponent } from "./pages/dashboard/deposite-wallet/deposite-wallet.component";
import { ViewFeeComponent } from "./pages/dashboard/view-fee/view-fee.component";
import { UpdateWithdrawlAmountComponent } from "./pages/fee-management/update-withdrawl-amount/update-withdrawl-amount.component";
import { MinWithdrawlAmountComponent } from "./pages/fee-management/min-withdrawl-amount/min-withdrawl-amount.component";
import { FaqComponent } from "./pages/faq-management/faq/faq.component";
import { AddFaqComponent } from "./pages/faq-management/add-faq/add-faq.component";
import { EditFaqComponent } from "./pages/faq-management/edit-faq/edit-faq.component";
import { AnnouncementComponent } from "./pages/announcement-management/announcement/announcement.component";
import { AddAnnouncementComponent } from "./pages/announcement-management/add-announcement/add-announcement.component";
import { EditAnnouncementComponent } from "./pages/announcement-management/edit-announcement/edit-announcement.component";
import { ViewAnnouncementComponent } from "./pages/announcement-management/view-announcement/view-announcement.component";
import { AllUserTraderComponent } from "./pages/dashboard/all-user-trader/all-user-trader.component";
import { FiatComponent } from "./pages/fiat-management/fiat/fiat.component";
import { SendMoneyComponent } from "./pages/fiat-management/send-money/send-money.component";
import { WalletTypesComponent } from "./pages/wallet-types/wallet-types.component";
import { ViewFaqComponent } from "./pages/faq-management/view-faq/view-faq.component";
import { BroadcastManagementComponent } from "./pages/broadcast-management/broadcast-management.component";
import { ContactUsComponent } from "./pages/dashboard/static-content-management/contact-us/contact-us.component";
import { NewsLetterListComponent } from "./pages/newsManagement/news-letter-list/news-letter-list.component";
import { NewsLetterAddComponent } from "./pages/newsManagement/news-letter-add/news-letter-add.component";
import { NewsLetterEditComponent } from "./pages/newsManagement/news-letter-edit/news-letter-edit.component";
import { NewsLetterViewComponent } from "./pages/newsManagement/news-letter-view/news-letter-view.component";
import { PaymentListComponent } from "./pages/dashboard/payment-list/payment-list.component";
import { PaymentViewComponent } from "./pages/dashboard/payment-view/payment-view.component";
import { StaticContentAddComponent } from "./pages/dashboard/static-content-management/static-content-add/static-content-add.component";
import { WhitepaperListComponent } from "./pages/whitepaper-management/whitepaper-list/whitepaper-list.component";
import { WhitepaperAddComponent } from "./pages/whitepaper-management/whitepaper-add/whitepaper-add.component";
import { WhitepaperEditComponent } from "./pages/whitepaper-management/whitepaper-edit/whitepaper-edit.component";
import { CommunityListComponent } from "./pages/community-management/community-list/community-list.component";
import { CommunityAddComponent } from "./pages/community-management/community-add/community-add.component";
import { CommunityEditComponent } from "./pages/community-management/community-edit/community-edit.component";
import { TrxManageComponent } from "./pages/trx-management/trx-manage/trx-manage.component";
import { HotWalletManagementComponent } from "./pages/dashboard/hot-wallet-management/hot-wallet-management.component";
import { AddUserComponent } from "./pages/add-user/add-user.component";
import { AddICOComponent } from "./pages/add-ico/add-ico.component";
import { ViewICOComponent } from "./pages/view-ico/view-ico.component";
import { EditICOComponent } from "./pages/edit-ico/edit-ico.component";
import { TransactionListManagementComponent } from "./pages/transaction-list-management/transaction-list-management.component";
import { TransactionViewManagementComponent } from "./pages/transaction-view-management/transaction-view-management.component";
import { TokentransactionViewComponent } from "./pages/tokentransaction-view/tokentransaction-view.component";
import { AdminWalletManagemtComponent } from "./pages/admin-wallet-managemt/admin-wallet-managemt.component";
import { ViewAdminWalletManagemtComponent } from "./pages/view-admin-wallet-managemt/view-admin-wallet-managemt.component";
import { EditAdminDetailComponent } from "./pages/admin-management/edit-admin-detail/edit-admin-detail.component";
import { RiskManagementComponent } from "./pages/dashboard/static-content-management/risk-management/risk-management.component";
import { AmlServiceComponent } from "./pages/dashboard/static-content-management/aml-service/aml-service.component";
import { TermsOfServiceComponent } from "./pages/dashboard/static-content-management/terms-of-service/terms-of-service.component";
import { IpWhitelistComponent } from "./pages/dashboard/ip-whitelist/ip-whitelist.component";
import { FieresTutorialListComponent } from "./pages/fieres-tutorial-management/fieres-tutorial-list/fieres-tutorial-list.component";
import { FieresTutorialAddComponent } from "./pages/fieres-tutorial-management/fieres-tutorial-add/fieres-tutorial-add.component";
import { FieresTutorialEditComponent } from "./pages/fieres-tutorial-management/fieres-tutorial-edit/fieres-tutorial-edit.component";
import { FieresTutorialViewComponent } from "./pages/fieres-tutorial-management/fieres-tutorial-view/fieres-tutorial-view.component";
import { SubadminActivityComponent } from "./pages/subadmin-activity/subadmin-activity.component";
import { TokenTrnsactionDetailComponent } from "./pages/token-trnsaction-detail/token-trnsaction-detail.component";
import { VestingPlanListComponent } from "./pages/vesting-plan-management/vesting-plan-list/vesting-plan-list.component";
import { VestingPlanAddComponent } from "./pages/vesting-plan-management/vesting-plan-add/vesting-plan-add.component";
import { VestingPlanEditComponent } from "./pages/vesting-plan-management/vesting-plan-edit/vesting-plan-edit.component";
import { VestingPlanViewComponent } from "./pages/vesting-plan-view/vesting-plan-view.component";
import { TokenCoinAddComponent } from "./pages/dashboard/token-coin-management/token-coin-add/token-coin-add.component";
import { TokenCoinListComponent } from "./pages/dashboard/token-coin-management/token-coin-list/token-coin-list.component";
import { StakingTransactionListComponent } from "./pages/dashboard/staking-transaction-list/staking-transaction-list.component";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  { path: "dashboard", component: DashboardComponent },
  { path: "user-management", component: UserManagementComponent },
  { path: "user-details/:id", component: UserDetailsComponent },
  { path: "wallet-details/:id", component: WalletDetailsComponent },
  { path: "kyc-management", component: KycManagementComponent },
  { path: "wallet-management", component: WalletManagementComponent },
  { path: "deposite-wallet", component: DepositeWalletComponent },
  { path: "transaction-management", component: TransactionManagementComponent },
  { path: "view-transaction/:id", component: ViewTransactionComponent },

  { path: "fee-management", component: FeeManagementComponent },
  { path: "manage-fee", component: ManageFeeComponent },
  { path: "standard-trading-fee", component: StandardTradingFeeComponent },
  { path: "min-withdrawl-amount", component: MinWithdrawlAmountComponent },
  {
    path: "update-withdrawl-amount/:data",
    component: UpdateWithdrawlAmountComponent,
  },
  { path: "min-trading-fee/:data", component: MinTradingFeeComponent },
  { path: "profit-loss-management", component: ProfitLossManagementComponent },
  { path: "user-detail-trading", component: UserDetailTradingComponent },
  {
    path: "hot-cold-wallet-management/:data",
    component: HotColdWalletManagementComponent,
  },
  {
    path: "view-hot-cold-wallet-management/:data",
    component: ViewHotColdWalletManagementComponent,
  },
  { path: "hot-wallet-management", component: HotWalletManagementComponent },
  {
    path: "static-content-management",
    component: StaticContentManagementComponent,
  },
  { path: "Terms_And_Conditions", component: TermsAndConditionsComponent },
  { path: "Privacy_Policy", component: PrivacyPolicyComponent },
  { path: "About_Us", component: AboutUsComponent },
  { path: "risk-management", component: RiskManagementComponent },
  { path: "aml-service", component: AmlServiceComponent },
  { path: "terms-of-service", component: TermsOfServiceComponent },
  { path: "Contact_Us", component: ContactUsComponent },
  { path: "faq-management", component: FaqComponent },
  { path: "add-faq", component: AddFaqComponent },
  { path: "edit-faq", component: EditFaqComponent },
  { path: "view-faq", component: ViewFaqComponent },
  { path: "broadcast-management", component: AnnouncementComponent },
  { path: "add-announcement", component: AddAnnouncementComponent },
  { path: "edit-announcement/:id", component: EditAnnouncementComponent },
  { path: "edit-user-admin/:id", component: EditUserAdminComponent },
  { path: "view-announcement/:id", component: ViewAnnouncementComponent },
  { path: "content-management", component: ContentManagementComponent },

  { path: "login-session-activity", component: LoginSessionActivityComponent },
  {
    path: "view-user-trading-detail",
    component: ViewUserTradingDetailComponent,
  },
  { path: "setting", component: SettingsComponent },
  { path: "taker-maker-fee", component: TakerMakeFeeComponent },
  { path: "view-limit", component: ViewLimitComponent },
  { path: "view-fee", component: ViewFeeComponent },
  { path: "withdrawl-fee", component: WithdrawlFeeComponent },
  { path: "withdrawl-limit", component: WithdrawlLimitComponent },

  { path: "user-management-admin", component: UserManagementAdminComponent },
  { path: "admin-detail/:id", component: AdminDetailComponent },
  { path: "edit-admin-detail/:id", component: EditAdminDetailComponent },
  { path: "add-administator", component: AddAdministerComponent },
  { path: "kyc-detail/:id", component: KycDetailComponent },
  { path: "kyc-update", component: KycUpdateComponent },

  { path: "manage-fee", component: ManageFeeComponent },
  { path: "standard-trading-fee", component: StandardTradingFeeComponent },
  { path: "min-trading-fee", component: MinTradingFeeComponent },

  { path: "my-profile", component: MyProfileComponent },
  { path: "edit-profile", component: EditProfileComponent },
  { path: "change-password", component: ChangePasswordComponent },
  { path: "token-management", component: TokenManagementComponent },
  { path: "add-token", component: AddTokenComponent },
  { path: "edit-token", component: EditTokenComponent },
  { path: "view-token", component: ViewTokenComponent },
  { path: "ticket-management", component: TicketManagementComponent },
  { path: "view-ticket/:id", component: ViewTicketComponent },
  { path: "reply-ticket/:id", component: ReplyTicketComponent },
  { path: "payment-view", component: PaymentViewComponent },
  { path: "payment-list", component: PaymentListComponent },
  { path: "add-static", component: StaticContentAddComponent },
  { path: "all-user-trader", component: AllUserTraderComponent },
  { path: "fiat", component: FiatComponent },
  { path: "send-money/:id", component: SendMoneyComponent },
  { path: "wallet-types", component: WalletTypesComponent },
  { path: "whitepaper-list", component: WhitepaperListComponent },
  { path: "whitepaper-add", component: WhitepaperAddComponent },
  { path: "whitepaper-edit", component: WhitepaperEditComponent },
  { path: "community-list", component: CommunityListComponent },
  { path: "community-add", component: CommunityAddComponent },
  { path: "community-edit", component: CommunityEditComponent },
  { path: "broadcast", component: BroadcastManagementComponent },
  { path: "news-letter-list", component: NewsLetterListComponent },
  { path: "news-letter-add", component: NewsLetterAddComponent },
  { path: "news-letter-edit", component: NewsLetterEditComponent },
  { path: "news-letter-view", component: NewsLetterViewComponent },
  { path: "trx-manage", component: TrxManageComponent },
  { path: "add-user", component: AddUserComponent },
  { path: "icoSalesListing", component: IcoSalesListingComponent },
  { path: "addICO", component: AddICOComponent },
  { path: "viewICO", component: ViewICOComponent },
  { path: "editICO", component: EditICOComponent },
  { path: "transactionList", component: TransactionListManagementComponent },
  { path: "transactionView", component: TransactionViewManagementComponent },
  { path: "editICO", component: EditICOComponent },
  { path: "token-transaction-view", component: TokentransactionViewComponent },
  { path: "admin-wallet", component: AdminWalletManagemtComponent },
  { path: "view-admin-wallet", component: ViewAdminWalletManagemtComponent },
  { path: "ip-whitelist", component: IpWhitelistComponent },
  { path: "fieres-tutorial-list", component: FieresTutorialListComponent },
  { path: "fieres-tutorial-add", component: FieresTutorialAddComponent },
  { path: "fieres-tutorial-edit", component: FieresTutorialEditComponent },
  { path: "fieres-tutorial-view", component: FieresTutorialViewComponent },
  { path: "sub-admin-activity", component: SubadminActivityComponent },
  { path: "claim-token", component: TokenTrnsactionDetailComponent },
  { path: "vesting-plan-list", component: VestingPlanListComponent },
  { path: "vesting-plan-add", component: VestingPlanAddComponent },
  { path: "vesting-plan-edit", component: VestingPlanEditComponent },
  { path: "vesting-plan-view", component: VestingPlanViewComponent },
  { path: "token-coin-add", component: TokenCoinAddComponent },
  { path: "token-coin-list", component: TokenCoinListComponent },
  {
    path: "staking-transaction-list",
    component: StakingTransactionListComponent,
  },
  { path: "**", component: PageNotFoundComponent },

  // payment
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
