<div class="wrapper">
  <main class="middle-content">
    <div class="page_title_block">
      <h1 class="page_title" style="color:#26272d">FIAT WALLET TYPES</h1>
    </div>
    <div class="content-section dashboard-block">
      <div class="" style="margin-left: 8%;">
        <button *ngFor="let items of coinData" type="submit" data-target="#coinModal"
          [disabled]="items.coinType=='crypto'" data-toggle="modal"  class="btn  btn-theme cus-mr mb-2" (click)="coinclicked(items.coinShortName)">
          {{items.coinShortName}} Wallet</button>

      </div>
      <ul class="dash_list d-flex w-100 flex-wrap text-center justify-content-between">

        <div class="w-100 wallet-type-div">


          <form [formGroup]="walletForm" style="text-align: left;">  
            <div class="user-profile">
              <div class="image-box">
                <img [src]="walletImage" class="profile-pic" alt="profile image">
                <label class="upload-label">
                  <input type="file" class="hidden" accept=".png, .jpg, .jpeg" (change)="uploadImg($event)" />
                  <i class="fas fa-camera" aria-hidden="true"></i>
                </label>
              </div>
            </div>

            <div class="form-group row align-items-baseline">
              <label class="col-md-4">Coin Short Name </label>
              <div class="col-md-1">:</div>
              <div class="col-md-7">
                <input type="text" id="name" name="name" class="form-control"  formControlName="coinName">
              </div>
            </div>
            <div class="form-group row align-items-baseline">
              <label class="col-md-4">IBAN</label>
              <div class="col-md-1">:</div>
              <div class="col-md-7">
                <input type="text" id="name" class="form-control" name="name" formControlName="iban">
              </div>
            </div>
            <div class="form-group row align-items-baseline">
              <label class="col-md-4">SWIFT</label>
              <div class="col-md-1">:</div>
              <div class="col-md-7">
                <input type="text" id="name" class="form-control" name="name" formControlName="swift">
              </div>
            </div>
            <p style="margin: auto;width: 13%;">
              <button type="submit" class="btn  btn-theme cus-mr " (click)="addFiatWallet()"
                [disabled]="walletForm.invalid">Add Wallet</button>
            </p>
          </form>
        </div>

      </ul>
    </div>
  </main>
</div>


<div class="modal fade global-modal reset-modal" id="coinModal">
  <div class="modal-dialog max-WT-500">
    <form class="change_password">
      <div class="modal-content">  
        <div>
          <div class="modal-body">
            <h3 style="text-align: center; ">Edit Wallet </h3>
            <hr>
            <button type="button" class="close" data-dismiss="modal" style="margin-top: -85px;">&times;</button>
            <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
              <form [formGroup]="editwalletForm">
                <div class="user-profile">
                  <div class="image-box">
                  <img [src]="editImage?editImage:'assets/img/alende.png'"  class="profile-pic" alt="profile image">
                  <label class="upload-label">
                    <input type="file" class="hidden" accept=".png, .jpg, .jpeg" (change)="uploadeditImg($event)" />
                    <i class="fas fa-camera" aria-hidden="true"></i>
                  </label>
                </div>
                </div>
                <div class="form-group row align-items-baseline">
                  <label class="col-md-4">Coin Short Name </label>
                  <div class="col-md-1">:</div>
                  <div class="col-md-7">
                    <input type="text" id="name" name="name" class="form-control" readonly formControlName="coinName">
                  </div>
                </div>
                <div class="form-group row align-items-baseline">
                  <label class="col-md-4">IBAN</label>
                  <div class="col-md-1">:</div>
                  <div class="col-md-7">
                    <input type="text" id="name" class="form-control" name="name" formControlName="iban">
                  </div>
                </div>
                <div class="form-group row align-items-baseline">
                  <label class="col-md-4">SWIFT</label>
                  <div class="col-md-1">:</div>
                  <div class="col-md-7">
                    <input type="text" id="name" class="form-control" name="name" formControlName="swift">
                  </div>
                </div>
                <p>
                  <button type="submit" class="btn  btn-theme cus-mr" (click)="editWallet()"
                    [disabled]="editwalletForm.invalid">Update</button>
                  <button type="button" class="btn btn-danger" data-dismiss="modal" style="width: 25%;">Cancel</button>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>