<main class="middle-content">
  <div class="page_title_block">
    <h1 class="page_title" style="color: #26272d">Staking Management</h1>
  </div>
  <div class="content-section">
    <div class="outer-box">
      <div class="global-table no-radius p0">
        <div class="tab-content1">
          <div class="tab-pane1">
            <div class="row d-flex justify-content-end w_100">
              <button
                type="submit"
                class="btn btn-theme"
                routerLink="/staking-transaction-list"
              >
                Staking Transactions
              </button>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered" aria-describedby="user list">
                <thead>
                  <tr class="no_wrap_th">
                    <th scope="col">Id</th>
                    <th scope="col">Percentage %</th>
                    <th scope="col">Renew Percentage %</th>
                    <th scope="col">Extra Bonus Percentage %</th>
                    <th scope="col">Terms (Days)</th>
                    <th scope="col">Status</th>
                    <th scope="col" class="action_td_btn3">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="new-hgt-tab" *ngFor="let item of vestingPlanList">
                    <td>{{ item?.id }}</td>
                    <td>{{ item?.percentage }}</td>
                    <td>{{ item?.renewPercentage }}</td>
                    <td>{{ item?.extraBonusPercentage }}</td>
                    <td>{{ item?.terms }}</td>
                    <td>
                      {{
                        item?.status == null || item?.status
                          ? "Active"
                          : "Block"
                      }}
                    </td>
                    <td
                      class="action_td_btn3 pl-0"
                      style="
                        text-overflow: unset;
                        max-width: 100% !important;
                        width: auto;
                      "
                    >
                      <a
                        class="mr-2"
                        (click)="openModal(item?.id, item?.status)"
                        *ngIf="item?.status == false"
                        ><i class="fa fa-ban" aria-hidden="true"></i
                      ></a>
                      <a
                        class="mr-2"
                        (click)="openModal(item?.id, item?.status)"
                        *ngIf="item?.status == true || item?.status == null"
                        ><i
                          class="fa fa-ban"
                          aria-hidden="true"
                          style="color: green"
                        ></i
                      ></a>
                      <a (click)="editPlan(item)" class="ml-2"
                        ><i class="fa fa-edit" aria-hidden="true"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<footer>
  <div class="container-fluid">
    <p class="copyright">
      REH ICO Crypto © 2023 All Rights Reserved.
      <strong class="theme-text-color"></strong>
    </p>
  </div>
</footer>

<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="blockModal">
  <div class="modal-dialog max-WT-500">
    <form class="change_password">
      <div class="modal-content">
        <div>
          <div class="modal-body">
            <h3 style="text-align: center">Block Staking</h3>
            <hr />
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              style="margin-top: -85px"
            >
              &times;
            </button>
            <div
              class="text-center modal_flax_height d-flex align-items-center justify-content-center"
            >
              <div class="w-100">
                <p style="font-size: 25px">
                  Are you sure you want to block this plan?
                </p>

                <div>
                  <button
                    type="submit"
                    class="btn btn-info mr-2"
                    (click)="blockCoin()"
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal fade global-modal reset-modal" id="activeModal">
  <div class="modal-dialog max-WT-500">
    <form class="change_password">
      <div class="modal-content">
        <div>
          <div class="modal-body">
            <h3 style="text-align: center">Active Staking</h3>
            <hr />
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              style="margin-top: -85px"
            >
              &times;
            </button>
            <div
              class="text-center modal_flax_height d-flex align-items-center justify-content-center"
            >
              <div class="w-100">
                <p style="font-size: 25px">
                  Are you sure you want to active this plan?
                </p>

                <div>
                  <button
                    type="submit"
                    class="btn btn-info mr-2"
                    (click)="activeCoin()"
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
