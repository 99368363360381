import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $
@Component({
  selector: 'app-view-ico',
  templateUrl: './view-ico.component.html',
  styleUrls: ['./view-ico.component.css']
})
export class ViewICOComponent implements OnInit {
  modalForm: FormGroup;
  id: any;
  viewData: any;


  temp = "http://www.africau.edu/images/default/sample.pdf";
  constructor(public router: Router, public service: MainService, public sanitizer: DomSanitizer, public activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.id = params.id;
    });
  }

  ngOnInit(): void {
    this.viewICO();
    this.modalForm = new FormGroup({
      reason: new FormControl("", Validators.required),
    });
  }

  viewICO() {
    var url = "wallet/admin/view-ico?icoId=" + this.id;
    this.service.get(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.viewData = res.data
        this.service.hideSpinner()
      } else {
        this.service.hideSpinner();
      }
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterErr(err.message)
    }
    );
  }

}
